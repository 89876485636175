import { Chart } from "chart.js";
import React from "react";
import { IChartDocument } from "../types/charts.schema";
import { ChartSizeMode, IChartPosition, IChartSize, ISlidePadding } from "../types/slideDeck.schema";
import useChartUtil from "./useChartUtil";
import useColorMode from "./useColorMode";
import useDynamicChartData from "./useDynamicChartData";

export interface IPasteChartIntoOfficeOptions {
    padding?: ISlidePadding,
    position?: IChartPosition,
    size?: IChartSize
}

export default function useDynamicChartImage() {
    const {
        convertTypeToChartType
    } = useChartUtil();

    const {
        getChartData,
        getChartOptions         
    } = useDynamicChartData(null, false, true);

    const getImageFromChart = (chart: IChartDocument): Promise<string | undefined> => new Promise((resolve) => {

        try {
            const canvas = document.getElementById("chart-canvas");
            if (!canvas) return resolve(undefined);
    
            const chartItem = new Chart((canvas as HTMLCanvasElement).getContext("2d"), {
                type: convertTypeToChartType(chart.type),
                data: getChartData(chart),
                options: getChartOptions(chart)
            });
    
            setTimeout(() => {
                try {
                    return resolve(chartItem.toBase64Image());
                }
                catch (e) {
                    console.log(e);
                    return resolve(undefined);
                }
                finally {
                    if (!!chartItem) chartItem.destroy();
                }
            }, 50);
        }
        catch {
            return resolve(undefined);
        }
    });

    const getPosition = (p: IChartPosition, s: IChartSize, padding: ISlidePadding): IChartPosition => {

        const result: IChartPosition = {
            x: 0,
            y: 0
        };

        const slidePadding: ISlidePadding = {
            left: padding?.left ?? 0,
            right: padding?.right ?? 0,
            top: padding?.top ?? 0,
            bottom: padding?.bottom ?? 0
        }

        const size: IChartSize = {
            sizeMode: s?.sizeMode ?? ChartSizeMode.width,
            value: s?.value ?? 400
        };

        if (!p) return result;

        const pptSlideSize: IChartPosition = {
            x: 1280,
            y: 720
        }

        const percentOfWidth = pptSlideSize.x / 100;
        const percentOfHeight = pptSlideSize.y / 100;

        const availableWidth = pptSlideSize.x - (percentOfWidth * (slidePadding.left + slidePadding.right));
        const availableHeight = pptSlideSize.y - (percentOfHeight * (slidePadding.top + slidePadding.bottom));
        
        const pxToPt = (px: number) => px * 0.75;

        const viewBox = {
            startX: percentOfWidth * slidePadding.left,
            startY: percentOfHeight * slidePadding.top,
            endX: (percentOfWidth * slidePadding.left) + availableWidth,
            endY: (percentOfHeight * slidePadding.top) + availableHeight
        }

        const getAdjustedLocation = (viewStart: number, viewSize: number, posPercentOfSide: number, imgSize: number) => {
            const imgCenterPt = pxToPt(imgSize) / 2;
            const sizePt = pxToPt(viewSize);
            const startPt = pxToPt(viewStart);

            const pos = posPercentOfSide < 0 ? 0 : (posPercentOfSide / 100) * viewSize;

            if ((pos - imgCenterPt) < 0) return startPt;
            if ((pos + imgCenterPt) > sizePt) return sizePt - pxToPt(imgSize);

            return startPt + (pxToPt(pos) - imgCenterPt);
        }

        const getSizeInPoints = (mode: ChartSizeMode): number => {

            if (mode === size.sizeMode) return pxToPt(size.value);

            switch (mode) {
                case ChartSizeMode.width:
                    return pxToPt(size.value * (pptSlideSize.x / pptSlideSize.y));
                case ChartSizeMode.height:
                    return pxToPt(size.value * (pptSlideSize.y / pptSlideSize.x));
            }
        }

        const imageSize: IChartPosition = {
            x: getSizeInPoints(ChartSizeMode.width),
            y: getSizeInPoints(ChartSizeMode.height)
        }

        const usedImageSize: IChartPosition = {
            x: imageSize.x > availableWidth ? availableWidth : imageSize.x,
            y: imageSize.y > availableHeight ? availableHeight : imageSize.y
        };

        result.x = getAdjustedLocation(viewBox.startX, viewBox.endX, p.x, usedImageSize.x);
        result.y = getAdjustedLocation(viewBox.startY, viewBox.endY, p.y, usedImageSize.y);
 
        console.log("RESULTING POSITION: ", result);
        
        return result;
    }

    const pasteChartImageToActiveSlide = async (chart: IChartDocument, opts: IPasteChartIntoOfficeOptions = {}): Promise<boolean> => {
        try {
            const image = await getImageFromChart(chart);

            if (!image) return false;
    
            const imgContent = image.split(",")[1];
            
            if (!imgContent) return false;
            
            const {
                position,
                size,
                padding
            } = opts;
    
            const p = getPosition(position, size, padding);

            const setDataOptions: Office.SetSelectedDataOptions = {
                coercionType: Office.CoercionType.Image,
                imageLeft: p.x,
                imageTop: p.y
            };
            
            if (size?.sizeMode === ChartSizeMode.height) setDataOptions.imageHeight = size.value;
            else setDataOptions.imageWidth = size?.value ?? 400;
            
            return await new Promise((resolve) => Office.context.document.setSelectedDataAsync(
                imgContent, 
                setDataOptions, 
                result => {
                    if (!!result.error) {
                        console.log(result.error);
                        resolve(false);
                    }

                    resolve(true);
                }
            ));
        }
        catch (e) {
            console.log(e);
            return false;
        }
    };

    return {
        getImageFromChart,
        pasteChartImageToActiveSlide
    }
}