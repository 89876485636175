import React from "react";
import { ChartType, IChart } from "../../../types/charts.schema";
import Flex from "../container/Flex";
import { useAppSelector } from "../../../state/reduxHooks";
import { Badge, Button, Card, CardFooter, CardHeader, Checkbox, Skeleton, SkeletonItem, Spinner, Switch, Text } from "@fluentui/react-components";
import UpdateChartForm from "./UpdateChartForm";
import useChartUtil from "../../../hooks/useChartUtil";
import DeleteChartButton from "./DeleteChartButton";
import CustomChart from "./CustomChart";
import { Chart } from "chart.js";
import useChartData from "../../../hooks/useChartData";
import { CopyRegular, EyeOffRegular, EyeRegular, TrayItemRemoveRegular } from "@fluentui/react-icons";
import "./ChartItem.css";
import InsertChartButton from "./InsertChartButton";
import { parseVarColorToHex } from "../../../util/color";
import ChartPreview from "./ChartPreview";
import useResponsive, { Breakpoint } from "../../../hooks/useResponsive";
import ChartPresetBanner from "./ChartPresetBanner";
import ChartOrientationBanner from "./ChartOrientationBanner";
import YearsToggle from "../year/YearsToggle";
import YearToggle from "../year/YearToggle";
import useYearUtil from "../../../hooks/useYearUtil";
import ChartGroupByBanner from "./ChartGroupByBanner";
import ChartYoyBadge from "./ChartYoyBadge";

export interface IChartItemProps {
    chart: IChart,
    isSelectable?: boolean,
    isSelected?: boolean,
    onSelect?: (chart: IChart) => void,
    isInsertable?: boolean,
    isEditable?: boolean,
    isDeletable?: boolean,
    previewAlwaysVisible?: boolean
}

export default function ChartItem(props: IChartItemProps) {

    const {
        chart,
        isDeletable = true,
        isEditable = true,
        isSelectable = false,
        onSelect,
        isSelected = false,
        isInsertable = true,
        previewAlwaysVisible = false    
    } = props;

    const [previewVisible, setPreviewVisible] = React.useState<boolean>(previewAlwaysVisible);

    const {
        getLabelForYear
    } = useYearUtil();

    const {
        mappedResults
    } = useAppSelector(state => state.numbersImport);

    const {
        getLabelForChartType
    } = useChartUtil();
   
    const {
        isNarrower
    } = useResponsive(Breakpoint.Mobile);
    
    const previewToggle = (
        isNarrower
        ? (
            <Button
                icon={previewVisible ? <EyeOffRegular /> : <EyeRegular />}
                appearance="transparent"
                onClick={() => setPreviewVisible(!previewVisible)}
            />
        )
        : (
            <Switch
                label="Vorschau"
                slot="start"
                labelPosition="before"
                checked={previewVisible}
                onChange={(_, data) => setPreviewVisible(data.checked)}
            />
        )
    )

    return (
        <Card 
            className="w-100" 
            selected={!!(isSelectable && !!onSelect) ? isSelected : undefined}
            floatingAction={
                !!(isSelectable && !!onSelect) 
                ? (
                    <Checkbox 
                        checked={isSelected}
                        onChange={(_, data) => onSelect(chart)}
                    />
                )
                : undefined
            }
        >
            <CardHeader
                header={
                    <Flex row fullWidth align="start" justify="between">
                        <Flex gap={0}>
                            <Flex row>
                                { chart.title && <Text weight="bold">{chart.title}</Text> }
                                <ChartPresetBanner chart={chart} />
                                <ChartOrientationBanner chart={chart} />
                                <ChartGroupByBanner chart={chart} />
                                <ChartYoyBadge chart={chart} />
                            </Flex>
                            <Text>{getLabelForChartType(chart.type, false)}</Text>
                            
                        </Flex>
                        {
                            !(isSelectable && !!onSelect) && previewToggle
                        }
                    </Flex>
                }
            />
            <Flex fullWidth>
                {
                    previewVisible 
                    ? <ChartPreview chart={chart} />
                    : (
                        <Flex row wrap>
                            {
                                chart.years && chart.years.map(y => <Badge key={y} color="informative" size="small">{getLabelForYear(y)}</Badge>)
                            }
                        </Flex>
                    )
                }
            </Flex>
            <CardFooter>
                <Flex fullWidth row wrap justify="between">
                    <Flex row wrap>
                        {
                            isEditable && <UpdateChartForm chart={chart} />
                        }
                        {
                            (isEditable && !previewVisible) && <UpdateChartForm chart={chart} isDuplication />
                        }
                        {
                            (isSelectable && !!onSelect) && previewToggle
                        }
                        {/* {
                            (isInsertable && !!mappedResults) && <InsertChartButton chart={chart} />
                        } */}
                    </Flex>
                    {
                        isDeletable && <DeleteChartButton chart={chart} />
                    }
                </Flex>
            </CardFooter>
        </Card>
    )
}