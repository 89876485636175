import useSWR from "swr";
import { RouteParam, Routes } from "../../api/apiRoutes";
import { IChart } from "../../types/charts.schema";

export interface IChartState {
    charts: Array<IChart>,
    chartsById: {[key: string]: IChart}
}

export const useCharts = () => {

    const { data, isLoading, mutate } = useSWR<IChartState>(Routes.charts.all.route);

    return {
        charts: data,
        loadingCharts: isLoading,
        reloadCharts: mutate
    }
}