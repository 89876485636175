import { ErrorMessage, Field, FieldAttributes, Formik, useFormikContext } from "formik";
import React from "react";
import "./FormikField.css";
import IElementProps from "../../../types/element.types";
import { generateClassName } from "../../../hooks/useAttributes";
import { Button, Input, InputOnChangeData, Label, Slider, Text } from "@fluentui/react-components";
import useFormikValue from "../../../hooks/useFormikValue";
import { ChevronDown12Regular, ChevronUp12Regular, DeleteRegular, EyeOffRegular, EyeRegular } from "@fluentui/react-icons";
import Flex from "../container/Flex";

interface IFormikRangeProps extends IElementProps {
    label?: string,
    bold?: boolean,
    min?: number,
    max?: number,
    step?: number,
    defaultValue: number,
    hideReset?: boolean,
    valueFormat?: "number" | "percent",
    name: string,
    appearance?: "outline" | "underline" | "filled-darker" | "filled-lighter" | "filled-darker-shadow" | "filled-lighter-shadow",
    formatValue?: (v?: number) => string | number | undefined
}

export default function FormikRange<T>(props: IFormikRangeProps) {
    const {
        label, 
        formatValue,
        hideReset,
        appearance = "filled-darker",
        defaultValue,
        min = 0, 
        max = 100, 
        bold,
        step, 
        name, 
        style,
        valueFormat
    } = props;

    const {
        handleChange,
        values
    } = useFormikContext<T>();

    const {
        value
    } = useFormikValue(name);

    const getFormattedValue = () => {
        if (formatValue) return formatValue(value);
        else if (!valueFormat) {
            switch (valueFormat) {
                case "percent": return `${value}%`;
                default: return value;
            }
        } 
        else return value;
    }

    const updateValue = (v: number) => {
        if (v < min) v = min;
        if (v > max) v = max;

        handleChange({
            target: {
                name,
                value: v
            }
        });
    }

    const increment = () => updateValue((value ?? 0) + 1);
    const decrement = () => updateValue((value ?? 0) - 1);

    const fieldId = `${name}field`;

    return (
        <Flex row>
            {
                label && <Text wrap={false} weight={bold ? "bold" : "regular"} truncate={false} >{label}</Text>
            }
            <Flex row>
                <Field name={name} >
                    {
                        ({ field, form: { }, meta }: FieldAttributes<any>) => (
                            <Flex row>
                                <Slider
                                    style={style}
                                    value={value}
                                    onChange={(e, v) => {
                                        handleChange({
                                            target: {
                                                name,
                                                value: v.value
                                            }
                                        })
                                    }}
                                    min={min ?? 0}
                                    max={max ?? 100}
                                    id={fieldId}
                                    vertical={false}
                                    name={name}
                                />
                                <Flex gap={0}>
                                    <Button
                                        style={{ height: "12px", width: "12px" }}
                                        size="small"
                                        icon={<ChevronUp12Regular />}
                                        appearance="transparent" 
                                        onClick={increment}
                                    />
                                    <Label className="text-nowrap" size="small" htmlFor={fieldId} >{getFormattedValue()}</Label>
                                    <Button
                                        style={{ height: "12px", width: "12px" }}
                                        size="small"
                                        icon={<ChevronDown12Regular />}
                                        onClick={decrement}
                                        appearance="transparent" 
                                    />
                                </Flex>
                                {
                                    !hideReset && (
                                        <Button
                                            icon={<DeleteRegular />}
                                            onClick={() => handleChange({ target: { name, value: defaultValue ?? 0 }})}
                                            appearance="transparent"
                                        />
                                    )
                                }
                            </Flex>
                        )
                    }
                </Field>
                <ErrorMessage name={name} render={(msg) => <span className="mt-2" style={{color: "#FF0000"}}>{msg}</span>} />
            </Flex>
        </Flex>
    )
}