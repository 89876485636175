import React, { PropsWithChildren, ReactNode } from "react";
import { Formik, Form, FormikBag, FormikHelpers, FormikProps, FormikValues } from "formik";
import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, Spinner, Text } from "@fluentui/react-components";
import CustomButton, { ButtonAppearance } from "../button/CustomButton";
import { SaveRegular } from "@fluentui/react-icons";
import Flex from "../container/Flex";

export interface IModalPropsBase {
    size?: "small" | "medium" | "large",
    priority?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10,
    icon?: React.ReactElement,
    title: string,
    button?: (clickHandler: () => void) => React.ReactElement,
    text?: string,
    loading?: boolean,
    renderChildrenOnlyWhenVisible?: boolean,
    appearance?: ButtonAppearance,
}

interface IModalFormProps<T extends FormikValues> extends IModalPropsBase {
    className?: string,
    initialValues: T,
    closeAfterSubmit?: boolean,
    description?: string,
    disabled?: boolean,
    submitDisabled?: boolean,
    enableReinitialize?: boolean,
    onSubmit: (values: T, helpers: FormikHelpers<T>) => Promise<boolean>,
    submitText?: string,
    submitIcon?: React.ReactElement,
    children: (formik: FormikProps<T>, close: Function) => ReactNode
}

export default function ModalForm<T extends FormikValues>(props: IModalFormProps<T>) {
    
    const { 
        submitText,
        submitDisabled, 
        enableReinitialize,
        description,
        appearance, 
        renderChildrenOnlyWhenVisible,
        priority = 5,
        size = "medium",
        closeAfterSubmit = true, 
        disabled, 
        initialValues, 
        title, 
        button,
        children, 
        className,
        text,
        submitIcon,
        loading,
        icon,
        onSubmit
    } = props;

    const [visible, setVisible] = React.useState<boolean>(false);

    const getMinWidth = () => {
        const sizeMap = {
            small: 400,
            medium: 600,
            large: 800
        }

        const priorityAddition = 10;
        return sizeMap[size] + priority * priorityAddition;
    }

    const close = () => setVisible(false);

    return (
        <Dialog onOpenChange={(val, data)=> setVisible(data.open)} open={visible}>
            { button ? button(() => setVisible(true)) : <Button appearance={appearance} className={className} onClick={() => setVisible(true)} icon={icon}>{text}</Button> }
            <DialogSurface style={{width: getMinWidth() + "px", maxWidth: "calc(100vw - 25px)", display: renderChildrenOnlyWhenVisible && !visible ? "none" : undefined}}>
                {
                    (visible || !renderChildrenOnlyWhenVisible) && (
                        <Formik 
                            initialValues={initialValues}
                            enableReinitialize={enableReinitialize}
                            onSubmit={async (values, actions) => {
                                try {
                                    const res = await onSubmit(values, actions)
                                    if (!res) return;
                                    if (!closeAfterSubmit) return;
                                    close();
                                }
                                catch {}
                            }}
                        >
                            {
                                formik => (
                                    <Form>
                                        <DialogBody>
                                            <DialogTitle>
                                                <Flex fullWidth gap={0}>
                                                    <Text size={500} weight="bold" color="#0f6cbd">{title}</Text>
                                                    { description && <Text size={200}>{description}</Text> }
                                                </Flex>
                                            </DialogTitle>
                                            <DialogContent>
                                                <Flex fullWidth>
                                                    {
                                                        loading 
                                                        ? <Spinner />
                                                        : children(formik, close)
                                                    }
                                                </Flex>
                                            </DialogContent>
                                            <DialogActions>
                                                <DialogTrigger disableButtonEnhancement>
                                                    {
                                                        !formik.isSubmitting && <Button type="button" onClick={close}>Abbrechen</Button>
                                                    }
                                                </DialogTrigger>
                                                <CustomButton
                                                    type="submit"
                                                    text={submitText ?? "Speichern"}
                                                    icon={submitIcon ?? <SaveRegular />}
                                                    onClick={formik.submitForm} 
                                                    wrap={false}
                                                    disabled={disabled || submitDisabled || formik.isSubmitting}
                                                    loading={formik.isSubmitting}
                                                />
                                            </DialogActions>
                                        </DialogBody>
                                    </Form>
                                )
                            }
                        </Formik>
                    )
                }
            </DialogSurface>
        </Dialog>
    );
}