import useSWR from "swr";
import { RouteParam, Routes } from "../../api/apiRoutes";
import { IMappingGroup, IMappingGroupWithMappings } from "../../types/mappingGroup.schema";
import { ICustomer } from "../../types/customer.schema";
import { IUser } from "../../types/user.schema";

export const useUsers = (customer: string) => {

    const { data, isLoading, mutate } = useSWR<Array<IUser>>(Routes.customers.users.getValue([{ param: RouteParam.Customer, value: customer }]));

    return {
        users: data,
        loadingUsers: isLoading,
        reloadUsers: mutate
    }
}