import React from "react";
import { useSlideDecks } from "../../../state/slideDecks/useSlideDecks";
import { Badge, Card, Spinner, Table, TableBody, TableCell, TableCellLayout, TableHeader, TableHeaderCell, TableRow, Text } from "@fluentui/react-components";
import Flex from "../container/Flex";
import { formatDateGmailStyle, formatFileSize } from "../../../util/formatter";
import UpdateSlideDeckForm from "./UpdateSlideDeckForm";
import DeleteSlideDeckButton from "./DeleteSlideDeckButton";
import useOfficeVersion from "../../../hooks/useOfficeVersion";
import ApplySlideDeckButton from "./ApplySlideDeckButton";
import { CheckmarkRegular, EditRegular, FolderRegular } from "@fluentui/react-icons";
import { Link } from "react-router-dom";
import { AppRoutes } from "../menu/Sidebar";
import CustomButton from "../button/CustomButton";

export default function SlideDeckList() {
    const {
        loadingSlideDecks,
        reloadSlideDecks,
        slideDecks
    } = useSlideDecks();

    if (loadingSlideDecks) return <Spinner />

    if (!slideDecks || !slideDecks.length) return <Text>Keine Vorlagen hinterlegt</Text>

    return (
        <Flex>
            {
                slideDecks.map(s => (
                    <Card key={s._id} className="w-100 p-2">
                        <Flex fullWidth>
                            <Flex row wrap>
                                <Text weight="bold">{s.name}</Text>
                                {
                                    s.isGlobalPreset && <Badge color="danger">Vorlage</Badge>
                                }
                            </Flex>
                            {
                                s.presetFile && (
                                    <Card className="w-100" appearance="outline">
                                        <Flex fullWidth gap={0}>
                                            <Text size={200}>Mit Präsentationsdatei</Text>
                                            <Text>{s.presetFile.name}</Text>
                                        </Flex>
                                        <Flex row>
                                            <Text>{formatFileSize(s.presetFile.size)}</Text>
                                            <FolderRegular />
                                        </Flex>
                                    </Card>
                                )
                            }
                            <Flex row>
                                <Text>{s.slides?.length || 0} Folien</Text>
                            </Flex>
                            <Flex row>
                                <ApplySlideDeckButton slideDeck={s} />
                                <Link to={AppRoutes.SlideDeck.getPath(s._id)}>
                                    <CustomButton onClick={() => {}} icon={<EditRegular />} text="Bearbeiten" />
                                </Link>
                                <DeleteSlideDeckButton slideDeck={s} />
                            </Flex>
                        </Flex>
                    </Card>
                ))
            }
        </Flex>
    )
}