import React from "react";
import { ChartOrientation, IChartDocument } from "../../../types/charts.schema";
import { Badge } from "@fluentui/react-components";

export default function ChartOrientationBanner(props: { chart: IChartDocument }) {

    if (!props.chart || !props.chart.orientation) return null;
    if (props.chart.orientation !== ChartOrientation.Horizontal) return null;

    return (
        <Badge color="informative">Horizontal</Badge>
    )
}