import {configureStore} from "@reduxjs/toolkit";
import ModalSlice from "./modal/modal.state";
import NumbersImportSlice from "./datev/numbersImport.state";
import CurrentGroupSlice from "./datev/currentGroup.state";
import ColorModeSlice from "./color/color.state";

const store = configureStore({
    reducer: {
        modal: ModalSlice,
        numbersImport: NumbersImportSlice,
        currentGroup: CurrentGroupSlice,
        colorMode: ColorModeSlice
    }
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;