import { IChartDocument } from "../types/charts.schema";
import useDynamicChartData from "./useDynamicChartData";

export default function useChartData(chart: IChartDocument, small: boolean = false) {
    
    const {
        data,
        options,
        loading
    } = useDynamicChartData(chart, small);

    return {
        loading,
        chartData: data,
        options
    }
}