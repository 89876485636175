import { FluentProvider, Theme, webLightTheme } from "@fluentui/react-components";
import React from "react";
import { useCustomer } from "../state/customers/useCustomer";
import FullScreenSpinner from "./components/spinner/FullScreenSpinner";
import { hexWithBrightness, hexWithOpacity } from "../util/color";
import { ICustomerTheme } from "../types/customer.schema";
import useTheme from "../hooks/useTheme";

export default function ThemeProvider({ children }: { children: any }) {

    const {
        customer,
        loadingCustomer
    } = useCustomer();

    const colors = useTheme();

    if (loadingCustomer) return <FullScreenSpinner />

    const theme: Theme = {
        ...webLightTheme,
        colorBrandBackground: colors.primary,
        colorBrandBackgroundSelected: hexWithBrightness(colors.primary, 0.8),
        colorBrandBackgroundHover: hexWithBrightness(colors.primary, 0.9),
        colorBrandBackgroundPressed: hexWithBrightness(colors.primary, 0.7),
        colorBrandBackground2: colors.secondary,
        colorBrandBackground2Hover: hexWithBrightness(colors.secondary, 0.9),
        colorCompoundBrandBackground: colors.secondary,
        colorCompoundBrandBackgroundHover: hexWithBrightness(colors.secondary, 0.9),
        colorCompoundBrandBackgroundPressed: hexWithBrightness(colors.secondary, 0.8),
        colorBrandStroke1: colors.secondary,
        colorBrandStroke2Contrast: hexWithOpacity(colors.secondary, 0.1),
        colorCompoundBrandStroke: colors.secondary,
        colorCompoundBrandStrokeHover: hexWithOpacity(colors.secondary, 0.9),
        colorCompoundBrandForeground1: colors.secondary,
        colorNeutralForeground2BrandSelected: colors.secondary,
        colorNeutralForeground2BrandPressed: colors.secondary,
        colorNeutralForeground2BrandHover: hexWithBrightness(colors.secondary, 0.9),
        colorSubtleBackgroundHover: hexWithOpacity(colors.secondary, 0.02),
    }

    return (
        <FluentProvider theme={theme}>
            { children }
        </FluentProvider>
    )
}