import React from "react";
import { ISlideDeck } from "../../../types/slideDeck.schema";
import useOfficeVersion from "../../../hooks/useOfficeVersion";
import { useCharts } from "../../../state/charts/useCharts";
import CustomButton from "../button/CustomButton";
import { getId } from "../../../util/mongoUtil";
import useDynamicChartImage from "../../../hooks/useDynamicChartImage";
import ModalForm from "../modal/ModalForm";
import Flex from "../container/Flex";
import useNotifications from "../../../hooks/useModal";
import { ModalType } from "../../../state/modal/modal.state";
import { Card, CardHeader, Spinner, Text } from "@fluentui/react-components";
import { ArrowRightRegular, Folder24Regular, Folder48Regular, FolderRegular } from "@fluentui/react-icons";
import CheckBox from "../formik/CheckBox";
import { formatFileSize } from "../../../util/formatter";
import useSlideDeckPresetFile from "../../../hooks/useSlideDeckPreset";

export interface IApplySlideDeckButtonProps {
    slideDeck: ISlideDeck
}

export enum SlideDeckApplyStep {
    DownloadingPresetFile = 0,
    ApplyingPresetFile = 1,
    ApplyingCharts = 2
}

export default function ApplySlideDeckButton(props: IApplySlideDeckButtonProps) {
    
    const [step, setStep] = React.useState<SlideDeckApplyStep>(SlideDeckApplyStep.ApplyingCharts);

    const {
        slideDeck
    } = props;

    const {
        isOpenOutsideOfOffice
    } = useOfficeVersion();

    const {
        showNotification
    } = useNotifications();

    const {
        downloadFile,
        applyPresetFileSlides,
        applySlideDeckCharts
    } = useSlideDeckPresetFile(slideDeck);

    if (isOpenOutsideOfOffice) return null;
    if (!slideDeck || !slideDeck.slides || !slideDeck.slides.length) return null;


    const getText = () => {
        switch (step) {
            case SlideDeckApplyStep.DownloadingPresetFile: return "Vorlagendatei wird heruntergeladen...";
            case SlideDeckApplyStep.ApplyingPresetFile: return "Vorlagendatei wird angewendet...";
            case SlideDeckApplyStep.ApplyingCharts: return "Diagramme werden angewendet...";
        }
    }

    return (
        <ModalForm
            title={`Präsentation anwenden: ${slideDeck.name}`}
            text="Anwenden"
            submitText="Präsentation übernehmen" 
            submitIcon={<ArrowRightRegular />}
            description={`Diese Präsentation beinhaltet Diagramme für ${slideDeck.slides.length} Folien. ${!!slideDeck.presetFile && "Diese sind angepasst für die Vorlagendatei."}`}        
            initialValues={{
                applyWithPresetFile: true,
                applyToExistingSlides: true
            }}
            onSubmit={async (values) => {
                try {
                    if (values.applyWithPresetFile) {
                        setStep(SlideDeckApplyStep.DownloadingPresetFile);
                        const file = await downloadFile();
    
                        if (!file || !file.success) {
                            showNotification({
                                text: file.message || "Fehler beim Herunterladen der Vorlagendatei",
                                type: ModalType.Error
                            });
                            return false;
                        }
    
                        setStep(SlideDeckApplyStep.ApplyingPresetFile);
    
                        const fileApplied = await applyPresetFileSlides(file.data, !values.applyToExistingSlides);
    
                        if (!fileApplied) {
                            showNotification({
                                text: "Fehler beim Anwenden der Vorlagendatei",
                                type: ModalType.Error
                            });
                            return false;
                        }
                    }
    
                    setStep(SlideDeckApplyStep.ApplyingCharts);
                    return await applySlideDeckCharts(!values.applyToExistingSlides);
                }
                catch (err) {
                    console.log(err);
                    showNotification({
                        text: "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
                        type: ModalType.Error
                    });
                    return false;
                }
            }}
        >
            {
                formik => (
                    formik.isSubmitting 
                    ? (
                        <Flex fullWidth justify="center" align="center" className="p-4">
                            <Spinner 
                                label={getText()} 
                                labelPosition="below" 
                                size="extra-large"
                            />
                        </Flex>
                    )
                    : (
                        <Flex fullWidth>
                            {
                                !formik.values.applyToExistingSlides && (
                                    <Text>Das Anwenden dieser Präsentation wird die {slideDeck.presetFile && formik.values.applyWithPresetFile  ? "Folien" : "Diagramme"} der Vorlage ans Ende Ihrer aktuellen Datei anhängen.</Text>
                                )
                            }
                            {
                                slideDeck.presetFile && (
                                    <>
                                        <CheckBox name="applyWithPresetFile" label="Vorlagendatei herunterladen und anwenden" />
                                        {
                                            formik.values.applyWithPresetFile && (
                                                <Card>
                                                    <CardHeader
                                                        image={<FolderRegular/>}
                                                        header={<Text>{slideDeck.presetFile.name}</Text>}
                                                        description={<Text size={200}>{formatFileSize(slideDeck.presetFile.size)}</Text>}
                                                    />
                                                </Card>
                                            )
                                        }
                                    </>
                                )
                            }
                            <CheckBox 
                                name="applyToExistingSlides" 
                                label={
                                    slideDeck.presetFile && formik.values.applyWithPresetFile 
                                    ? "Existierenden Folien überschreiben" 
                                    : "Auf existierenden Folien einfügen anstatt neue Folien anhängen" 
                                }
                            />
                        </Flex>
                    )
                )
            }
        </ModalForm>
    )
}