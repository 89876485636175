import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum ModalType {
    Information = "info",
    Warning = "warning",
    Success = "success",
    Error = "error"
}

export interface IModalActionPayload {
    text: string, 
    title?: string,
    type: ModalType
}

export interface IModalNotification extends IModalActionPayload {
    id: number
}

interface IModalSlice {
    messages: Array<IModalNotification>
}

export const ModalSlice = createSlice({
    name: "modal",
    initialState: {
        messages: []
    } as IModalSlice,
    reducers: {
        addNotification: (state, action: PayloadAction<IModalActionPayload>) => {
            const payload = action.payload;
            if (!payload) return;
            const id = (state.messages.length || 0) + 1;

            const newItem = {
                id: id,
                title: "",
                type: ModalType.Information,
                text: ""
            } as IModalNotification;

            if (payload) {
                newItem.title = payload.title || "";
                newItem.type = payload.type;
                newItem.text = payload.text;
            }

            state.messages.push(newItem);
        },
        removeNotification: (state, action: PayloadAction<number>) => { 
            const index = state.messages.findIndex(x => x.id === action.payload);
            if (index === -1) return;
            state.messages.splice(index, 1);
        }
    }
});

export const { addNotification, removeNotification } = ModalSlice.actions;

export default ModalSlice.reducer;