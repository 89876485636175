import { ChartData, ChartOptions, ChartTypeRegistry, Color, SankeyDataPoint, Scriptable, ScriptableContext } from "chart.js";
import React from "react";
import { TreemapControllerDatasetLabelsOptions, TreemapScriptableContext } from "../charts/treemap/treemap";
import { useMappingGroups } from "../state/mappingGroups/useMappingGroups";
import { useMappings } from "../state/mappings/useMappings";
import { useAppSelector } from "../state/reduxHooks";
import { ChartGroupByType, ChartOrientation, ChartType, IChartDocument, IChartMapping } from "../types/charts.schema";
import { BalanceIndicator, CalculationResultType, IMapping, Year } from "../types/mapping.schema";
import { getTextColorFromBackground } from "../util/color";
import { getFormattedMappingValue, getMappingValue } from "../util/formatter";
import { getId } from "../util/mongoUtil";
import useCachedImport from "./useCachedImport";
import useChartUtil from "./useChartUtil";
import useColorMode from "./useColorMode";
import useColorPalette from "./useColorPalette";
import useCurrentGroup from "./useCurrentGroup";
import useYearUtil from "./useYearUtil";

export type ChartAxis = "y" | "y2";

export interface IDatalabelConfig {
    color: string,
    backgroundColor?: CanvasGradient,
    anchor?: "start" | "center" | "end",
    offset?: number,
    align?: "start" | "center" | "end" | "right" | "left" | "top" | "bottom", 
    formatter?: (val: number, ctx: any) => string,
    rotation?: number
}

export interface IDataset {
    datalabels: IDatalabelConfig,
    label: string,
    fill?: boolean,
    tree: Array<number>,
    type?: keyof ChartTypeRegistry,
    data: Array<number | SankeyDataPoint>,
    yAxisID: ChartAxis,
    cubicInterpolationMode?: "default" | "monotone",
    tension?: number,
    order?: number,
    borderWidth?: number,
    borderRadius?: number,
    borderColor?: string,
    backgroundColor?: string | Scriptable<Color, TreemapScriptableContext> | Array<string>,
    colorFrom?: (data: ScriptableContext<'sankey'>) => string,
    colorTo?: (data: ScriptableContext<'sankey'>) => string,
    colorMode?: 'gradient' | 'from' | 'to',
    priority?: Record<string, number>,
    column?: Record<string, number>,
    size?: 'min' | 'max',
    nodeWidth?: number,
    spacing?: number,
    labels: Array<string> | Record<string, string> | TreemapControllerDatasetLabelsOptions,
    color?: string,
}

export type CustomChartData = ChartData<any, IDataset, string>;

export default function useDynamicChartData(forChart: IChartDocument, small: boolean = false, isForExport: boolean = false) {

    const {
        currentGroupId
    } = useCurrentGroup();

    useCachedImport();

    const {
        getPaletteColor
    } = useColorPalette();

    const {
        colorMode
    } = useColorMode();

    const {
        mappingGroups
    } = useMappingGroups();

    const {
        mappings,
        loadingMappings
    } = useMappings();

    const {
        getLabelForYear,
        formatYear,
        getLastYear
    } = useYearUtil();

    const {
        convertTypeToChartType
    } = useChartUtil();

    const {
        mappedResults
    } = useAppSelector(state => state.numbersImport);

    const [options, setOptions] = React.useState<ChartOptions | null>(null);
    const [data, setData] = React.useState<CustomChartData | null>(null);
    const [loading, setLoading] = React.useState<boolean>(false);

    React.useEffect(() => {

        setLoading(true);

        try {
            if (!forChart) return;
    
            setOptions(getOptions(forChart));
            setData(getChartData(forChart));
        }
        finally {
            setLoading(false);
        }

    }, [forChart, small, isForExport, colorMode, currentGroupId, mappingGroups, mappings, mappedResults, loadingMappings]);

    const getMapping = (m: IMapping | string) => {
        if (m === null) return null;
        if (typeof m === "string") return mappings?.[m];
        return m;
    }

    const formatTickCallback = (orientation: ChartOrientation, type: CalculationResultType, value: number) => {
        switch (orientation) {
            case ChartOrientation.Vertical:

                return getFormattedMappingValue(value, type, { logScale: true, withFraction: true });

            case ChartOrientation.Horizontal:

                return formatYear(value);
        }
    }
    
    const getChartTypes = (chart: IChartDocument): { [key in ChartAxis]: CalculationResultType } => {
        const result: { [key in ChartAxis]: CalculationResultType } = {
            y: CalculationResultType.Currency,
            y2: CalculationResultType.Currency
        }

        const chartGroups = chart.groups.find(g => getId(g.mappingGroup) === currentGroupId);

        if (!chartGroups) return result;

        const firstMappingForPrimaryAxis = getMapping(chartGroups.mappings.find(m => !m.useSecondaryType)?.mapping);
        const firstMappingForSecondaryAxis = getMapping(chartGroups.mappings.find(m => m.useSecondaryType)?.mapping);

        if (firstMappingForPrimaryAxis) result.y = firstMappingForPrimaryAxis.resultType;
        if (firstMappingForSecondaryAxis) result.y2 = firstMappingForSecondaryAxis.resultType;

        return result;
    }

    const getOptions = (chart: IChartDocument) => {

        const hideLegendForTypes = [ChartType.TreeMap, ChartType.Sankey];
        const hideAxisForTypes = [ChartType.PieChart, ChartType.DonutChart, ChartType.TreeMap, ChartType.Sankey];
        
        const types = getChartTypes(chart);

        const options: ChartOptions = {
            font: {
                size: isForExport ? 30 : undefined
            },
            elements: {
                line: {
                    tension: 0.4,
                    cubicInterpolationMode: "monotone",
                    borderWidth: isForExport ? 12 : 4
                },
                point: {
                    radius: isForExport ? 20 : undefined,
                }
            },
            indexAxis: (chart.orientation === ChartOrientation.Horizontal && chart.type === ChartType.BarChart) ? "y" : "x",
            events: [ ],
            plugins: {
                datalabels: {
                    display: !hideAxisForTypes.includes(chart.type),
                    anchor: "start",
                    align: "start"
                },
                legend: {
                    display: !small && !hideLegendForTypes.includes(chart.type) && !chart.isYoY,
                    labels: {
                        padding: isForExport ? 10 : undefined,
                        font: {
                            size: isForExport ? 36 : undefined,
                            lineHeight: isForExport ? 1.5 : undefined
                        },
                        boxPadding: isForExport ? 8 : undefined,
                        pointStyle: "circle"
                    }
                },
                title: {
                    text: chart.title,
                    font: {
                        size: isForExport ? 64 : undefined
                    },
                    display: !small
                },
                tooltip: {
                    enabled: false
                },
                subtitle: {
                    display: !small && chart.type === ChartType.PieChart,
                    text: getLabelForYear(chart.years?.[0] ?? Year.Current),
                    font: {
                        size: isForExport ? 56 : undefined
                    }
                }
            },
            responsive: true,
            maintainAspectRatio: false,
            animation: {
                duration: 0
            },
            interaction: {
                mode: 'index' as const,
                intersect: false,
            },
            scales: {
                x: {
                    display: !hideAxisForTypes.includes(chart.type),
                    stacked: chart.groups && chart.groups.length > 1,
                    ticks: {
                        font: {
                            size: isForExport ? 36 : undefined
                        }
                    }
                },
                y: {
                    display: !hideAxisForTypes.includes(chart.type),
                    position: "left",
                    stacked: chart.groups && chart.groups.length > 1,
                    ticks: {
                        font: {
                            size: isForExport ? 36 : undefined
                        }
                    }
                }
            },
        }

        if (!!chart.secondaryType) {
            options.scales.y2 = {
                display: !hideAxisForTypes.includes(chart.type),
                position: "right",
                stacked: chart.groups && chart.groups.length > 1,
                ticks: {
                    font: {
                        size: isForExport ? 36 : undefined
                    }
                }
            }
        }

        if (chart.orientation === ChartOrientation.Horizontal) {
            options.scales.x.ticks.callback = (value: number) => getFormattedMappingValue(value, types["y"], { logScale: true, withFraction: true });
        }
        else {
            options.scales.y.ticks.callback = (value: number) => formatTickCallback(chart.orientation, types["y"], value);
            if (!!chart.secondaryType) options.scales.y2.ticks.callback = (value: number) => formatTickCallback(chart.orientation, types["y2"], value);
        }

        return options;
    }

    const getValueForMapping = (id: string, year: Year, positiveType: BalanceIndicator) => {
        if (!mappedResults || !mappedResults[year]) return 0;
        const result = mappedResults?.[year]?.[currentGroupId]?.[id];
        if (!result) return 0;
        const value = getMappingValue(result);

        if (!result.isCalculated || result.resultType === CalculationResultType.Percentage) return Math.abs(value);
        if ((positiveType ?? result.indicator) === BalanceIndicator.Credit) return -value;
        return value;
    }

    const getCanvasContext = () => {
        try {
            return (document.getElementById("chart-canvas") as HTMLCanvasElement).getContext("2d");
        }
        catch {}
        return null;
    }

    const getDatalabelConfig = (orientation: ChartOrientation, type: ChartType, color: string): IDatalabelConfig => {
        const result: IDatalabelConfig = {
            color: getTextColorFromBackground(color)
        }

        switch (type) {
            case ChartType.LineChart: 

                const ctx = getCanvasContext();
                const bg = ctx?.createRadialGradient(0, 0, 15, 0, 0, 0);

                if (!!bg) {
                    bg.addColorStop(0, "#00000000");
                    bg.addColorStop(0.5, "#00000022");
                    bg.addColorStop(1, "#00000000");
                }

                result.color = color;
                result.backgroundColor = bg;
                result.anchor = "center",
                result.align = "top",
                result.offset = 5;

                break;
            
            default:

                result.rotation = orientation === ChartOrientation.Horizontal ? 0 : -90;
        }

        return result;
    }

    const getBorderColor = (type: ChartType, color: string) => {
        switch (type) {
            case ChartType.LineChart: return color;
            default: return undefined;
        }
    }

    const getDefaultDataset = (chart: IChartDocument, label: string, index: number, totalMappings: number, type?: ChartType) => {
        const color = getPaletteColor(index, totalMappings);

        const item: IDataset = {
            label: label,
            datalabels: getDatalabelConfig(chart.orientation, type ?? chart.type, color),
            yAxisID: "y",
            data: [],
            backgroundColor: color,
            borderColor: getBorderColor(type ?? chart.type, color),
            labels: {},
            tree: []
        }

        item.fill = chart.type === ChartType.AreaChart;
        return item;
    }

    const getMappingTitle = (cm: IChartMapping, m: IMapping, itemCount: number) => {
        if (itemCount > 5 && m?.shortName) return m.shortName;
        return cm?.adjustments?.name ?? m?.name;
    } 

    const getChartData = (chart: IChartDocument): CustomChartData => {

        const result: CustomChartData = {
            datasets: [],
            labels: []
        }

        if (!mappingGroups || !mappingGroups.length) return result;
        if (!chart) return result;
        if (!chart.groups || !chart.groups.length) return result;

        const group = chart.groups.find(g => getId(g.mappingGroup) === currentGroupId) ?? chart.groups[0];

        let item: IDataset;
        const resultTypes: Array<CalculationResultType> = [];
        const totalMappings = group.mappings?.length ?? 0;

        if (chart.isYoY) {

            const yoyStart = group.yoyStart;
            const yoyMapping = getMapping(yoyStart);

            if (!yoyMapping) return result;

            const previousYear = getLastYear(chart.years[0]);
            const startValue = getValueForMapping(yoyMapping._id, previousYear, yoyMapping.indicator);
            const endValue = getValueForMapping(yoyMapping._id, chart.years[0], yoyMapping.indicator);
            const item = getDefaultDataset(chart, "", 1, totalMappings);

            item.data.push(startValue);
            result.labels.push(`${yoyMapping.name} ${getLabelForYear(previousYear)}`);
            item.backgroundColor = [ getPaletteColor(0, totalMappings) ];

            let currentPoint = startValue;

            group.mappings.forEach((m, index) => {
                const mId = getId(m.mapping);

                if (mId === yoyMapping._id) return;

                const mapping = getMapping(mId);

                if (!mapping) return;

                const title = getMappingTitle(m, mapping, totalMappings);
                
                const value = getValueForMapping(mapping._id, chart.years[0], m.yoyPositiveResultIs);

                currentPoint += value;

                item.data.push(currentPoint);
                result.labels.push(`${title} \n${getFormattedMappingValue(value, mapping.resultType, { logScale: true, appendix: "€" })}`);
                (item.backgroundColor as Array<string>).push(getPaletteColor(index, totalMappings));
            });

            const difference = endValue - currentPoint;

            if ((Math.abs(difference) / endValue) > 0.1) {
                item.data.push(endValue);
                result.labels.push(`Sammelkonto ${getFormattedMappingValue(difference, yoyMapping.resultType, { logScale: true, appendix: "€" })}`);
                (item.backgroundColor as Array<string>).push(getPaletteColor(0, totalMappings));
            }

            item.data.push(endValue);
            (item.backgroundColor as Array<string>).push(getPaletteColor(0, totalMappings));
            result.labels.push(`${yoyMapping.name} ${getLabelForYear(chart.years[0])}`);
            result.datasets.push(item);

            console.log(result);
        }
        else switch (chart.type) {
            case ChartType.TreeMap:

                item = getDefaultDataset(chart, chart.title, 0, totalMappings);
                item.borderWidth = 1;
                item.spacing = 0;
                item.backgroundColor = (context: TreemapScriptableContext) => getPaletteColor(context.dataIndex, totalMappings);

                for (const m of group.mappings) {
                    const mapping = getMapping(m.mapping);
                    if (!mapping) continue;

                    const value = getValueForMapping(mapping._id, chart.years[0], mapping.indicator);

                    if (value == 0) continue;

                    result.labels.push(getMappingTitle(m, mapping, totalMappings));
                    resultTypes.push(mapping.resultType);
                    item.tree.push(value);
                }
                
                item.labels = {
                    display: true,
                    overflow: "fit",
                    color: (ctx) => getTextColorFromBackground(getPaletteColor(ctx.dataIndex, totalMappings)),
                    formatter: (ctx) => [result.labels[ctx.dataIndex], getFormattedMappingValue(ctx.raw.v, resultTypes[ctx.dataIndex])],
                };

                result.datasets.push(item);
                break;

            case ChartType.Sankey:

                item = getDefaultDataset(chart, chart.title, 0, totalMappings);

                item.colorFrom = (data: ScriptableContext<'sankey'>) => getPaletteColor(data.dataIndex, totalMappings);
                item.colorTo = (data: ScriptableContext<'sankey'>) => getPaletteColor(data.dataIndex, totalMappings);

                item.color = "colorFrom";

                for (const m of group.mappings) {
                    const mapping = getMapping(m.mapping);
                    if (!mapping) continue;

                    const value = getValueForMapping(mapping._id, chart.years[0], mapping.indicator);

                    if (m.sankeyData?.sourceMapping) {
                        const dataPoint: SankeyDataPoint = {
                            from: m.sankeyData?.sourceMapping,
                            to: mapping._id,
                            flow: value
                        }

                        item.data.push(dataPoint);
                        item.label = getMappingTitle(m, mapping, totalMappings);
                    }

                    item.labels[mapping._id] = `${getMappingTitle(m, mapping, totalMappings)} \n${getFormattedMappingValue(value, mapping.resultType, { logScale: true, appendix: "€" })}`;
                }

                result.datasets.push(item);
                break;

            case ChartType.PieChart:
            case ChartType.DonutChart:

                item = getDefaultDataset(chart, chart.title, 0, totalMappings);

                item.backgroundColor = [];
                item.labels = [];

                group.mappings.forEach((m, index) => {
                    const mapping = getMapping(m.mapping);
                    if (!mapping) return;
    
                    const value = getValueForMapping(mapping._id, chart.years[0], mapping.indicator);
    
                    if (value == 0) return;

                    item.data.push(value);
                    item.datalabels.formatter = (val: number, ctx: any) => getFormattedMappingValue(val, mapping.resultType, { logScale: true, appendix: "€" });

                    const label = `${getMappingTitle(m, mapping, totalMappings)} \n${getFormattedMappingValue(value, mapping.resultType, { logScale: true, appendix: "€" })}`

                    item.label = label;
                    result.labels.push(label);
                    (item.labels as Array<string>).push(label);
                    (item.backgroundColor as Array<string>).push(getPaletteColor(index, totalMappings));
                });

                result.datasets.push(item);
                break;

            default:

                if (chart.groupByType === ChartGroupByType.Year) {
                    for (const year of chart.years) {
                        result.labels.push(getLabelForYear(year));
                    }

                    result.datasets = [];

                    group.mappings.forEach((m: IChartMapping, index: number) => {
                        try {
                            const mapping = getMapping(m.mapping);
                            if (!mapping) return;
    
                            const title = m.adjustments?.name ?? mapping.name;
                            item = getDefaultDataset(chart, title, index, totalMappings, m.useSecondaryType ? chart.secondaryType : chart.type);
    
                            item.datalabels.formatter = (val: number, ctx: any) => getFormattedMappingValue(val, mapping.resultType, { logScale: true, appendix: "€" });

                            if (m.useSecondaryType) {
                                item.type = convertTypeToChartType(chart.secondaryType);
                                item.yAxisID = "y2";
                            }
                            
                            item.order = m.useSecondaryType ? 1 : 2;
    
                            for (const year of chart.years) {
                                item.data.push(getValueForMapping(mapping._id, year, mapping.indicator));
                            }
    
                            result.datasets.push(item);
                        }
                        catch { }
                    });
                }
                else {
                    result.datasets = [];
                    
                    chart.years.forEach((year: Year, yearIndex: number) => {
                        item = getDefaultDataset(chart, getLabelForYear(year), yearIndex, chart.years.length);

                        for (const m of group.mappings) {
                            const mapping = getMapping(m.mapping);

                            if (!mapping) continue;
                            
                            item.datalabels.formatter = (val: number, ctx: any) => getFormattedMappingValue(val, mapping.resultType, { logScale: true, appendix: "€" });

                            const label = getMappingTitle(m, mapping, totalMappings);

                            if (yearIndex === 0) {
                                result.labels.push(label);  
                            } 
                            
                            item.data.push(getValueForMapping(mapping._id, year, mapping.indicator));
                        }

                        result.datasets.push(item);
                    });
                }

                break;
        }

        return result;
    }

    return {
        loading: loading || loadingMappings,
        options,
        data,
        getChartData,
        getChartOptions: getOptions
    }
}