import useSWR from "swr";
import { RouteParam, Routes } from "../../api/apiRoutes";
import { IMappingGroup, IMappingGroupWithMappings } from "../../types/mappingGroup.schema";
import { ICustomer } from "../../types/customer.schema";

export const useCustomers = () => {

    const { data, isLoading, mutate } = useSWR<Array<ICustomer>>(Routes.customers.all.route);

    return {
        customers: data,
        loadingCustomers: isLoading,
        reloadCustomers: mutate
    }
}