import React from "react";
import { useAppSelector } from "../../../state/reduxHooks";
import CustomChart, { ICustomChartProps } from "./CustomChart";
import { IChartDocument } from "../../../types/charts.schema";
import { Skeleton, SkeletonItem, Spinner, Text } from "@fluentui/react-components";
import Flex from "../container/Flex";
import useChartData from "../../../hooks/useChartData";
import { Chart as ChartClass } from "chart.js";
import usePersistance, { StorageKey } from "../../../hooks/usePersistance";
import { generateStyle } from "../../../hooks/useAttributes";

export interface IChartPreviewProps {
    chart: IChartDocument,
    small?: boolean
}

export default function ChartPreview(props: IChartPreviewProps) {
    
    const {
        chart,
        small
    } = props;

    const {
        mappedResults
    } = useAppSelector(state => state.numbersImport);

    const {
        chartData,
        options,
        loading
    } = useChartData(chart, small);
    
    const style = generateStyle({
        name: "height",
        value: "150px",
        applyCondition: !small
    }, {
        name: "height",
        value: "90px",
        applyCondition: !!small
    });

    if (loading) return (
        <Skeleton className="w-100" animation="wave">
            <SkeletonItem style={style}>
                <Flex align="center" fullWidth justify="center" style={style} gap={1}>
                    <Spinner />
                </Flex>
            </SkeletonItem>
        </Skeleton>
    )

    if (!mappedResults) return (
        <Skeleton className="w-100" animation="wave">
            <SkeletonItem style={style}>
                <Flex align="center" fullWidth justify="center" style={style} gap={1}>
                    <Text weight="bold" align="center" className="w-100">Keine Zahlen importiert</Text>
                    <Text align="center" className="w-100">Bitte importieren Sie Zahlen, um eine Vorschau dieses Diagramms zu generieren.</Text>
                </Flex>
            </SkeletonItem>
        </Skeleton>
    );

    if (!chartData || !chartData.datasets || !chartData.datasets.length) return (
        <Flex align="center" fullWidth justify="center" style={style} gap={1}>
            <Text weight="bold" align="center" className="w-100">Keine Daten verfügbar</Text>
            <Text align="center" className="w-100">Das Diagramm inkludiert die ausgewählte Zuordnungsgruppe nicht.</Text>
        </Flex>
    );

    return (
        <CustomChart 
            small={small}
            chart={chart} 
            chartData={chartData}
            options={options}
        />
    );
}