import React from "react";
import { CalculationElementValueSource, IMappingCalculationElement, MappingCalculationElementType, Operator } from "../../../../types/mapping.schema";
import { Badge, Text } from "@fluentui/react-components";
import Flex from "../../container/Flex";

export interface ICalculationElementBadge {
    element: IMappingCalculationElement,
    index?: number,
    onClick?: () => void,
    isActive?: boolean
}

export default function CalculationElementBadge(props: ICalculationElementBadge) {
    const {
        onClick,
        index,
        element,
        isActive
    } = props;
    
    const {
        type,
        operator,
        accountFrom,
        accountTo,
        value,
        valueSource,
        mappings
    } = element;

    const isOperator = type === MappingCalculationElementType.Operator;

    const getAppearance = () => {
        if (isActive || !isOperator) return "filled";
        return "outline";
    }
    
    const getColor = () => {
        if (isActive || !isOperator) return "danger";
        return "informative";
    }

    const getContent = () => {
        if (isOperator) return operator;
        switch (valueSource) {
            case CalculationElementValueSource.Accounts: return <Text size={200}>Konten: {accountFrom}{accountTo > 0 && ` - ${accountTo}`}</Text>;
            case CalculationElementValueSource.Mappings: return <Text size={200}>Gruppen: {mappings.length}</Text>;
            case CalculationElementValueSource.FixedValue: return <Text size={200}>{value}</Text>;
        }
    }

    return (
        <Badge 
            style={{
                lineHeight: isOperator ? "0" : undefined,
                fontWeight: isOperator ? "bold" : undefined,
            }}
            className={onClick ? "clickable" : undefined}
            onClick={onClick}
            appearance={getAppearance()} 
            color={getColor()}
            size="large"
        >
            {
                getContent()
            }
        </Badge>
    )

}