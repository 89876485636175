import { Button, Card, Checkbox, Spinner, Text } from "@fluentui/react-components";
import { AddRegular, ArrowClockwiseRegular, ChevronDown48Regular, ChevronUp48Regular, DeleteRegular, EditRegular } from "@fluentui/react-icons";
import { FieldArray, useFormikContext } from "formik";
import React from "react";
import useChartUtil from "../../../hooks/useChartUtil";
import useCurrentGroup from "../../../hooks/useCurrentGroup";
import { useMappingGroup } from "../../../state/mappingGroups/useMappingGroup";
import { ChartType, IChartDocument, IChartMapping, IChartMappingGroup } from "../../../types/charts.schema";
import { BalanceIndicator, IMapping } from "../../../types/mapping.schema";
import { IMappingGroupWithMappings } from "../../../types/mappingGroup.schema";
import { parseAppColor } from "../../../util/color";
import { getId } from "../../../util/mongoUtil";
import CustomButton from "../button/CustomButton";
import Flex from "../container/Flex";
import CheckBox from "../formik/CheckBox";
import FormikField from "../formik/FormikField";
import FormikSelect from "../formik/FormikSelect";
import BalanceTypeBadge from "../mappings/BalanceTypeBadge";
import MappingList from "../mappings/MappingList";
import ModalDialog from "../modal/ModalDialog";
import AddNewMappingGroupButton from "./AddNewMappingGroupButton";
import ChartGroupTabs from "./ChartGroupTabs";

export default function ChartGroupsField() {

    const {
        currentGroupId
    } = useCurrentGroup();

    const [selectedGroup, setSelectedGroup] = React.useState<string>(currentGroupId);
    
    const {
        loadingMappingGroup,
        mappingGroup
    } = useMappingGroup(selectedGroup);

    const {
        values,
        setFieldValue
    } = useFormikContext<IChartDocument>();


    const selectedGroupIndex = values.groups && !!values.groups.length 
    ? values.groups.findIndex(g => getId(g.mappingGroup) === selectedGroup)
    : -1;

    const selectedChartMappingGroup = selectedGroupIndex > -1 ? values.groups[selectedGroupIndex] : undefined;
    
    const selectedIds = selectedChartMappingGroup?.mappings.map(m => getId(m.mapping)) ?? [];

    const getAvailableSankeyMappings = (soruceMappingId: string): Array<IMapping> => {
        if (!selectedChartMappingGroup || !mappingGroup || !selectedChartMappingGroup.mappings || !selectedChartMappingGroup.mappings.length) return [];
        if (!mappingGroup || !mappingGroup.mappingsById) return [];

        const existingMappings = new Set<string>();
        const result: Array<IMapping> = [];

        for (const id of selectedIds) {
            if (existingMappings.has(id)) continue;
            if (id === soruceMappingId) continue;
            const mapping = mappingGroup.mappingsById[id];
            if (!mapping) continue;
            existingMappings.add(id);
            result.push(mapping);
        }
        
        return result;
    }

    return (
        <Card className="w-100" appearance="outline">
            <Flex row justify="between" fullWidth>
                <ChartGroupTabs 
                    selectedGroup={selectedGroup} 
                    onSelect={group => setSelectedGroup(group)}
                    onRemove={group => {
                        const newGroups = values.groups.filter(g => getId(g.mappingGroup) !== group);
                        setFieldValue("groups", newGroups);
                    }} 
                />
                <AddNewMappingGroupButton 
                    addGroup={groupId => {
                        setSelectedGroup(groupId);

                        const newGroups = [
                            ...values.groups,
                            {
                                mappingGroup: groupId,
                                mappings: []
                            }
                        ]

                        setFieldValue("groups", newGroups);
                    }}
                />
            </Flex>
            {
                loadingMappingGroup 
                ? <Spinner />
                : (
                    selectedGroupIndex > -1 && !!selectedChartMappingGroup && !!mappingGroup && (
                        <FieldArray name={`groups.${selectedGroupIndex}.mappings`}>
                            {
                                arrayHelpers => (
                                    <Flex fullWidth>
                                        <Flex fullWidth row justify="between">
                                            <Text>Ausgewählte Kontengruppen</Text>
                                            <ModalDialog
                                                title="Kontengruppe auswählen"
                                                appearance="transparent"
                                                icon={selectedIds.length > 0 ? <EditRegular /> : <AddRegular />}
                                            >
                                                <MappingList 
                                                    mappingGroup={mappingGroup} 
                                                    onSelect={m => {
                                                        arrayHelpers.push<IChartMapping>({
                                                            mapping: m,
                                                            useSecondaryType: false,
                                                            adjustments: undefined,
                                                            sankeyData: { sourceMapping: "" },
                                                            yoyPositiveResultIs: BalanceIndicator.Debit
                                                        })

                                                        if (!values.title) setFieldValue("title", m.name);
                                                    }}
                                                    selectedKeys={selectedIds}
                                                    onRemove={m => {
                                                        const index = selectedIds.indexOf(getId(m));
                                                        if (index > -1) arrayHelpers.remove(index);
                                                    }}
                                                    canSelect
                                                />
                                            </ModalDialog>
                                        </Flex>
                                        <Flex fullWidth gap={3}>
                                            {
                                                selectedChartMappingGroup.mappings && !!selectedChartMappingGroup.mappings
                                                ? selectedChartMappingGroup.mappings.map((m, index) => {
    
                                                    const mappingId = getId(m.mapping);
                                                    const mapping = mappingGroup.mappingsById?.[mappingId];
                                                    const isAdjusted = !!m.adjustments;

                                                    if (!mapping) return null;
    
                                                    return (
                                                        <ChartGroupMappingField 
                                                            key={index} 
                                                            isAdjusted={isAdjusted} 
                                                            chartMapping={m}
                                                            getAvailableSankeyMappings={getAvailableSankeyMappings}
                                                            mappingGroup={mappingGroup}
                                                            chartGroup={selectedChartMappingGroup}
                                                            remove={arrayHelpers.remove}
                                                            groupIndex={selectedGroupIndex}
                                                            mapping={mapping} 
                                                            mappingId={mappingId} 
                                                            index={index}
                                                            swap={arrayHelpers.swap}
                                                        />
                                                    )
                                                })
                                                : <Text>Keine Zuweisungen ausgewählt</Text>
                                            }
                                        </Flex>
                                    </Flex>
                                )
                            }
                        </FieldArray>
                    )
                )
            }
         </Card>
    )
}

function ChartGroupMappingField(props: {
    isAdjusted: boolean,
    mapping: IMapping,
    chartMapping: IChartMapping,
    mappingId: string,
    mappingGroup: IMappingGroupWithMappings,
    chartGroup: IChartMappingGroup,
    swap: (a: number, b: number) => void,
    remove: (i: number) => void,
    groupIndex: number,
    index: number,
    getAvailableSankeyMappings: (soruceMappingId: string) => Array<IMapping>
}) {
    
    const [hover, setHover] = React.useState<boolean>(false);

    const {
        canTypeHaveSecondaryType,
        isTypeAllowedForYoyChart
    } = useChartUtil();

    const {
        setFieldValue,
        values
    } = useFormikContext<IChartDocument>();

    const {
        isAdjusted,
        mapping,
        mappingId,
        chartGroup,
        chartMapping,
        getAvailableSankeyMappings,
        groupIndex,
        mappingGroup,
        index,
        swap,
        remove
    } = props;

    const getExpectedBalanceTypeForPositiveAdditionBadge = (b: BalanceIndicator) => (
        <BalanceTypeBadge
            onClick={() => setFieldValue(`groups.${groupIndex}.mappings.${index}.yoyPositiveResultIs`, b)}
            type={b}
            activeType={chartMapping.yoyPositiveResultIs}
        />
    )

    return (
        <Flex row fullWidth>
            <Flex>
                <Button
                    appearance="transparent"
                    icon={<ChevronUp48Regular />}
                    onClick={() => swap(index, index - 1)}
                    disabled={index === 0}
                />
                <Button
                    appearance="transparent"
                    icon={<ChevronDown48Regular />}
                    onClick={() => swap(index, index + 1)}
                    disabled={index === values.groups.length - 1}
                />
            </Flex>
            <Card 
                className="w-100" 
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                appearance="outline"
            >
                <Flex fullWidth>
                    <Flex fullWidth gap={1}>
                        <Flex row justify="between" fullWidth align="start">
                            <Flex row gap={1}>
                                <Flex gap={0}>
                                    <Text>{mapping.name}</Text>
                                    {
                                        isAdjusted && <Text size={200}>Angezeigt als: {chartMapping.adjustments.name}</Text>
                                    }
                                </Flex>
                                {
                                    !isAdjusted && (
                                        <CustomButton
                                            appearance="transparent"
                                            icon={<EditRegular />}
                                            onClick={() => setFieldValue(
                                                `groups.${groupIndex}.mappings.${index}.adjustments`,
                                                { name: mapping.name }
                                            )}
                                            text="Name ändern"
                                        />
                                    )
                                }
                                {
                                    (!isAdjusted && hover) && (
                                        <ModalDialog
                                            title="Kontengruppe auswählen"
                                            appearance="outline"
                                            text="Auswählen"
                                        >
                                            <MappingList 
                                                mappingGroup={mappingGroup} 
                                                onSelect={m => setFieldValue(`groups.${groupIndex}.mappings.${index}.mapping`, m._id)}
                                                selectedKeys={[ mappingId ]}
                                                canSelect
                                            />
                                        </ModalDialog>
                                    )
                                }
                            </Flex>
                            <Button
                                style={parseAppColor("danger").getStyle(true, true)}
                                icon={<DeleteRegular />}
                                appearance="transparent"
                                onClick={() => remove(index)} 
                            />
                        </Flex>
                        {
                            isAdjusted && (
                                <Flex row fullWidth>
                                    <FormikField 
                                        className="w-100"
                                        name={`groups.${groupIndex}.mappings.${index}.adjustments.name`} 
                                    />
                                    <CustomButton
                                        appearance="transparent"
                                        icon={<ArrowClockwiseRegular />}
                                        onClick={() => setFieldValue(
                                            `groups.${groupIndex}.mappings.${index}.adjustments`,
                                            undefined
                                        )}
                                        text="Zurücksetzen"
                                    />
                                </Flex>
                            )
                        }
                    </Flex>
                    {
                        (canTypeHaveSecondaryType(values.type) && !!values.secondaryType && (chartMapping.useSecondaryType || hover)) && (
                            <CheckBox   
                                name={`groups.${groupIndex}.mappings.${index}.useSecondaryType`} 
                                label="Auf sekundärer Achse darstellen" 
                            />
                        )
                    }
                    {
                        (values.isYoY && isTypeAllowedForYoyChart(values.type) && getId(chartGroup.yoyStart) === mappingId) && (
                            <Checkbox 
                                checked={getId(chartGroup.yoyStart) === mappingId}
                                label="Start- und Endpunkt für Jahresentwicklung"
                                onChange={(_, e) => setFieldValue(`groups.${groupIndex}.yoyStart`, e.checked ? mappingId : undefined)}
                            />
                        )
                    }
                    {
                        (values.isYoY && isTypeAllowedForYoyChart(values.type ) && getId(chartGroup.yoyStart) !== mappingId) && (
                            <Flex row gap={3}>
                                <Text>Addition bei Wert im</Text>
                                <Flex row gap={1}>
                                    {getExpectedBalanceTypeForPositiveAdditionBadge(BalanceIndicator.Debit)}
                                    {getExpectedBalanceTypeForPositiveAdditionBadge(BalanceIndicator.Credit)}
                                </Flex>
                            </Flex>
                        )
                    }
                    {
                        values.type === ChartType.Sankey && (
                            <FormikSelect
                                label="Zahlenquelle für Mengenfluss"
                                description="Die Quellkontengruppe, aus welcher der Saldo dieser Gruppe stammt"
                                name={`groups.${groupIndex}.mappings.${index}.sankeyData.sourceMapping`}
                                options={getAvailableSankeyMappings(mappingId)}
                                valueKey="_id"
                                bold={false}
                                emptyPlaceholder="Bitte hinterlegen Sie mehr als eine Gruppe"
                                allowEmptyValue
                                labelKey="name"
                            />
                        )
                    }
                </Flex>
            </Card>
        </Flex>
    )
}