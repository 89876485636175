import React from "react";
import { ChartSizeMode, ISlide, ISlideChart, ISlideDeck, ISlideDeckDocument } from "../../../types/slideDeck.schema";
import ModalForm from "../modal/ModalForm";
import Flex from "../container/Flex";
import useApi from "../../../api/useApi";
import FormikField from "../formik/FormikField";
import { FieldArray } from "formik";
import { Avatar, Button, Card, CardHeader, Checkbox, Radio, RadioGroup, Text } from "@fluentui/react-components";
import { AddRegular, CheckmarkRegular, DeleteRegular, EditRegular, SlideTextRegular } from "@fluentui/react-icons";
import { IChart } from "../../../types/charts.schema";
import FormikSelect from "../formik/FormikSelect";
import ChartSelectDialog from "../charts/ChartSelectDialog";
import Grid from "../container/Grid";
import { useUser } from "../../../state/user/useUser";
import CheckBox from "../formik/CheckBox";
import { useSlideDecks } from "../../../state/slideDecks/useSlideDecks";
import useResponsive, { Breakpoint } from "../../../hooks/useResponsive";
import { getId } from "../../../util/mongoUtil";
import FormikRange from "../formik/FormikRange";
import useOfficeVersion from "../../../hooks/useOfficeVersion";
import CustomButton from "../button/CustomButton";
import FileSelect from "../formik/FileSelect";

export default function CreateSlideDeckForm() {
    const {
        reloadSlideDecks
    } = useSlideDecks();
    
    const {
        slideDecksCreate
    } = useApi();

    const {
        isOpenOutsideOfOffice
    } = useOfficeVersion();

    const {
        user
    } = useUser();

    if (!isOpenOutsideOfOffice) return null;

    return (
        <ModalForm
            title="Neue Präsentation erstellen"
            text="Neue Präsentation"
            icon={<AddRegular />}
            initialValues={{
                isGlobalPreset: false,
                name: "",
                slides: [],
                presetFile: undefined
            } as ISlideDeckDocument}
            onSubmit={async (values) => {
                const res = (await slideDecksCreate(values)).success;

                if (!res) return false;

                await reloadSlideDecks();
                
                return true;
            }}
        >
            {
                formik => (
                    <Flex fullWidth>
                        <FormikField name="name" label="Name der Präsentation" placeholder="Name" />
                        {
                            (!!user && user.isGlobalAdmin) && <CheckBox name="isGlobalPreset" label="Diese Präsentation kann von jedem Kunden genutzt werden" />
                        }
                        <Card className="w-100">
                            <Text weight="bold">Dateivorlage</Text>
                            <FileSelect 
                                value={formik.values.presetFile}
                                onChange={v => { console.log(v); formik.setFieldValue("presetFile", v) }}
                                onClear={() => formik.setFieldValue("presetFile", undefined)}
                                formats="application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation"
                            />
                        </Card>
                    </Flex>
                )
            }
        </ModalForm>
    )

}