import useSWR from "swr";
import { RouteParam, Routes } from "../../api/apiRoutes";
import { IMapping } from "../../types/mapping.schema";

export const useMappings = () => {

    const { data, isLoading, mutate } = useSWR<{ [key: string]: IMapping }>(Routes.mappings.all);

    return {
        mappings: data,
        loadingMappings: isLoading,
        reloadMappings: mutate
    }
}