import React from "react";
import { IDatevSingleYearDataFormProps } from "./DatevSingleYearDataForm";
import { BalanceIndicator, Year } from "../../../types/mapping.schema";
import { IMappingValue, MappedNumberImport, YearlyValue } from "../../../state/datev/numbersImport.state";
import ModalDialog from "../modal/ModalDialog";
import { BuildingBankRegular, CalculatorRegular, EyeRegular } from "@fluentui/react-icons";
import { Button, Tab, TabList, Table, TableBody, TableCell, TableCellActions, TableCellLayout, TableHeader, TableHeaderCell, TableRow, Text } from "@fluentui/react-components";
import Flex from "../container/Flex";
import { formatCurrency, formatMappingValue } from "../../../util/formatter";
import useYearUtil from "../../../hooks/useYearUtil";
import IElementProps from "../../../types/element.types";
import useCurrentGroup from "../../../hooks/useCurrentGroup";
import PaginatedList from "../list/PaginatedList";
import { MappingListTab } from "../mappings/MappingList";

export interface IMappedResultDialogProps extends IElementProps {
    loading?: boolean,
    data: YearlyValue<MappedNumberImport>
}

export default function MappedResultDialog({loading, data, className}: IMappedResultDialogProps) {

    const {
        currentGroupId
    } = useCurrentGroup();

    const {
        fancyYears
    } = useYearUtil();

    const items = Object.values(data ?? {}).flatMap(y => Object.values(y ?? {})).flatMap(y => Object.values(y ?? {}));

    return (
        <ModalDialog
            className={className}
            text={"Zuordnungen"}
            icon={<EyeRegular />}
            size="large"
            priority={10}
            disabled={loading}
            loading={loading}
            appearance="outline"
            title="Zuordnungen und Werte"
        >
            <PaginatedList
                tabs={MappingListTab}
                getTabIcon={t => {
                    switch (t) {
                        case "Calculated": return <CalculatorRegular />;
                        case "NotCalculated": return <BuildingBankRegular />;
                        default: return null;
                    }
                }}
                getTabLabel={t => {
                    switch (t) {
                        case "Calculated": return "Berechnete Positionen";
                        case "NotCalculated": return "Kontengruppen";
                        default: return "Alle";
                    }
                }}
                noItemsPlaceholder="Keine passenden Konten gefunden. Bitte überprüfen Sie Ihre Zuordnungen für die gewählte Zuordnungsgruppe und den Import."
                headers={["Zuordnungsgruppe", "Typ", ...fancyYears.map(f => f.label), "Aktionen"]}
                getKey={r => r._id}
                pageSize={10}
                filterPlaceholder="Nach Kontengruppen suchen..."
                itemMatchesFilter={(f, i) => {
                    const name = i.name?.toLowerCase();
                    return name?.includes(f.toLowerCase());
                }}
                itemMatchesTab={(tab, m) => {
                    if (tab === "Calculated") return m.isCalculated;
                    if (tab === "NotCalculated") return !m.isCalculated;
                    return true;
                }}
                items={items}
                renderItem={(r: IMappingValue) => [
                    <TableCellLayout>
                        <Text>
                            {r.name}
                        </Text>
                    </TableCellLayout>,
                    <TableCell>
                        <Text>{r.indicator === BalanceIndicator.Credit ? "H" : "S"}</Text>
                    </TableCell>,
                    ...fancyYears.map(f => {
                        const getValue = () => {
                            const yearValues = data[f.year];
                            if (!yearValues) return "-";
                            const groupValues = yearValues[currentGroupId];
                            if (!groupValues) return "-";
                            const mapping = groupValues[r._id];
                            if (!mapping) return "-";
                            return formatMappingValue(mapping);
                        }
                        return (
                            <TableCell>
                                <Text>{getValue()}</Text>
                            </TableCell>
                        )
                    }),
                    <TableCellActions>
                        <ModalDialog
                            button={onClick => <Button appearance="transparent" icon={<EyeRegular />} onClick={onClick} >Konten sehen</Button>}
                            text="Zuordnungen ansehen"
                            title="Konten und Werte"
                        >
                            <Text weight="bold">{r.name}</Text>
                            <Table>
                                <TableHeader>
                                    <TableRow>
                                        <TableHeaderCell>Konto</TableHeaderCell>
                                        <TableHeaderCell>EB</TableHeaderCell>
                                        <TableHeaderCell>JVZ S / H</TableHeaderCell>
                                        <TableHeaderCell>SB</TableHeaderCell>
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    {
                                        r.values.map((v, index) => (
                                            <TableRow key={index}>
                                                <TableCell>
                                                    <TableCellLayout
                                                        truncate
                                                        description={v.accountName}
                                                    >
                                                        {v.accountNumber}
                                                    </TableCellLayout>
                                                </TableCell>
                                                <TableCell>
                                                    <Text size={200}>{formatCurrency(v.startDebit - v.startCredit)}</Text>
                                                </TableCell>
                                                <TableCell>
                                                    <Flex gap={0}>
                                                        <Text size={200}>{formatCurrency(v.movementDebit)}</Text>
                                                        <Text size={200}>{formatCurrency(v.movementCredit)}</Text>
                                                    </Flex>
                                                </TableCell>
                                                <TableCell>
                                                    <Text size={200}>{formatCurrency(v.endDebit - v.endCredit)}</Text>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </ModalDialog>
                    </TableCellActions>,
                ]}
            />
        </ModalDialog>
    )

}