import React from "react";
import { IChartDocument } from "../../../types/charts.schema";
import { Badge } from "@fluentui/react-components";

export default function ChartPresetBanner(props: { chart: IChartDocument }) {
    if (!props.chart || !props.chart.isGlobal) return null;

    return (
        <Badge color="warning">Vorlage</Badge>
    )
}