import { useCustomer } from "../state/customers/useCustomer"
import { ICustomerTheme } from "../types/customer.schema";

export default function useTheme() {
    const {
        customer
    } = useCustomer();

    return {
        primary: customer?.settings?.theme?.primary ?? "#0455bf",
        secondary: customer?.settings?.theme?.secondary ?? "#011140"
    } as ICustomerTheme;
}