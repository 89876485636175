import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import React from "react";
import CustomButton from "../button/CustomButton";
import { Text } from "@fluentui/react-components";
import Flex from "../container/Flex";

export interface IFormikFormProps<T> {
    className?: string,
    initialValues: T,
    disabled?: boolean,
    submitDisabled?: boolean,
    enableReinitialize?: boolean,
    onSubmit: (values: T, helpers: FormikHelpers<T>) => Promise<boolean>,
    title?: string,
    secondaryAction?: React.ReactNode,
    submitText?: string,
    submitIcon?: React.ReactElement,
    loading?: boolean,
    children: (formik: FormikProps<T>) => React.ReactNode
}

export default function FormikForm<T>(props: IFormikFormProps<T>) {

    const {
        children,
        initialValues,
        onSubmit,
        title,
        className,
        secondaryAction,
        disabled,
        enableReinitialize,
        loading,
        submitDisabled,
        submitIcon,
        submitText = "Speichern"
    } = props;

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize={enableReinitialize}
            onSubmit={onSubmit}
        >
            {
                formik => (
                    <Form>
                        <Flex className={className}>
                            {
                                title && <Text weight="bold">{title}</Text>
                            }
                            {children(formik)}
                            <Flex fullWidth row justify="between">
                                {
                                    secondaryAction ?? <div />
                                }
                                <CustomButton 
                                    className="align-self-end"
                                    onClick={formik.submitForm}
                                    text={submitText}
                                    loading={formik.isSubmitting || loading}
                                    disabled={disabled || submitDisabled}
                                    icon={submitIcon}
                                />  
                            </Flex>
                        </Flex>
                    </Form>
                ) 
            }
        </Formik>
    )
}