import React from "react";
import ModalForm from "../modal/ModalForm";
import ChartList, { IChartListProps } from "./ChartList";
import { IChart } from "../../../types/charts.schema";
import { useCharts } from "../../../state/charts/useCharts";
import Flex from "../container/Flex";
import { Button, Card, CardHeader, CardPreview, Text } from "@fluentui/react-components";
import { DeleteRegular, DiagramRegular } from "@fluentui/react-icons";
import ChartPreview from "./ChartPreview";
import useChartUtil from "../../../hooks/useChartUtil";

export interface IChartSelectDialogProps {
    onSelect?: (chart: string) => void,
    onRemove?: (chart: string) => void,
    selectedItem?: string,
    className?: string
}

export default function ChartSelectDialog(props: IChartSelectDialogProps) {

    const {
        onSelect,
        onRemove,
        selectedItem,
        className
    } = props;

    const {
        getChartById
    } = useChartUtil();

    const getSelectedChart = (): IChart | null => {
        if (!selectedItem) return null;
        return getChartById(selectedItem);
    }

    const selectedChart = getSelectedChart();

    return (
        <Card className={className}>
            <Flex fullWidth row justify="between">
                <Text wrap={false} truncate={false} weight="bold">{selectedChart?.title ?? "Diagramm wählen..."}</Text>
                <Flex row>
                    <ModalForm
                        title="Chart auswählen"
                        appearance="transparent"
                        text=""
                        icon={<DiagramRegular />}
                        initialValues={{
                            chart: selectedItem
                        }}
                        onSubmit={async (values) => {
                            if (!onSelect) return false;
                            onSelect(values.chart);
                            return true;
                        }}
                    >
                        {
                            formik => (
                                <ChartList
                                    canSelect
                                    onSelect={c => {
                                        formik.setFieldValue("chart", c._id)
                                        if (onSelect) onSelect(c._id);
                                    }}
                                    selectedItem={formik.values.chart ?? selectedItem}
                                />
                            )
                        }
                    </ModalForm>
                    <Button
                        icon={<DeleteRegular />}
                        appearance="transparent"
                        onClick={() => onRemove(selectedItem)}
                    />
                </Flex>
            </Flex>
        </Card>
    )
}