import React from "react";
import { useAppDispatch, useAppSelector } from "../../../state/reduxHooks";
import CustomButton from "../button/CustomButton";
import useCachedImport from "../../../hooks/useCachedImport";
import { clearImportedData } from "../../../state/datev/numbersImport.state";
import { DeleteRegular } from "@fluentui/react-icons";
import IElementProps from "../../../types/element.types";

export default function ClearImportButton({ className }: IElementProps) {
    const dispatch = useAppDispatch();

    const {
        mappedResults
    } = useAppSelector(state => state.numbersImport);

    const {
        clearImport
    } = useCachedImport();

    if (!mappedResults) return null;

    return (
        <CustomButton
            className={className}
            text="Import entfernen"
            color="danger"
            icon={<DeleteRegular />}
            onClick={() => {
                try {
                    clearImport();
                    dispatch(clearImportedData());
                }
                catch (err) {
                    console.log(err);
                }
            }}
        />
    )
}