import React from "react";
import IElementProps from "../../../types/element.types";
import "./Grid.css";
import { generateClassName } from "../../../hooks/useAttributes";

export type GridColumnCount = 1 | 2 | 3 | 4 | 5;

export interface IGridProps extends IElementProps {
    cols?: GridColumnCount
}

export default function Grid(props: IGridProps) {

    const {
        cols = 1,
        children,
        className,
        style
    } = props;


    const realClass = generateClassName(className, "grid w-100", {
        base: "grid-",
        value: cols
    });

    return (
        <div className={realClass} style={style}>
            {children}
        </div>
    )
}