import useSWR from "swr";
import { RouteParam, Routes } from "../../api/apiRoutes";
import { IMapping } from "../../types/mapping.schema";
import { ISlideDeck } from "../../types/slideDeck.schema";

export const useSlideDecks = () => {

    const { data, isLoading, mutate } = useSWR<Array<ISlideDeck>>(Routes.slideDecks.all);

    return {
        slideDecks: data,
        loadingSlideDecks: isLoading,
        reloadSlideDecks: mutate
    }
}