import { ChartTypeRegistry } from "chart.js";
import { ChartType, IChart } from "../types/charts.schema";
import { useCharts } from "../state/charts/useCharts";
import { getId } from "../util/mongoUtil";

export default function useChartUtil() {
    const SecondaryFriendlyChartTypes = [
        ChartType.AreaChart,
        ChartType.LineChart,
        ChartType.BarChart,
    ];

    const {
        charts,
        loadingCharts
    } = useCharts();

    const getChartById = (id: string | IChart) => {
        if (!charts) return null;
        const usedId = typeof id === "string" ? id : getId(id);
        const existingChart = charts.chartsById[usedId];
        if (existingChart) return existingChart;
        return charts.charts.find(c => getId(c) === usedId);
    }
    
    return {
        SecondaryFriendlyChartTypes,
        getChartById,
        canTypeHaveSecondaryType: (t: ChartType) => SecondaryFriendlyChartTypes.includes(t),
        isTypeAllowedForYoyChart: (t: ChartType) => t === ChartType.BarChart || t === ChartType.LineChart,
        getLabelForChartType: (chart: ChartType, short: boolean = true): string => {
            let value = "";

            switch (chart) {
                case ChartType.BarChart: value = "Balken"; break;
                case ChartType.LineChart: value = "Linien"; break;
                case ChartType.AreaChart: value = "Flächen"; break;
                case ChartType.PieChart: value = "Torten"; break;
                case ChartType.DonutChart: value = "Donut"; break;
                case ChartType.Sankey: value = "Mengenfluss"; break;
                case ChartType.TreeMap: value = "Kachel"; break;
            }

            if (short) return value;
            
            return `${value}diagramm`;
        },
        convertTypeToChartType: (type: ChartType): keyof ChartTypeRegistry => {
            switch (type) {
                case ChartType.AreaChart: return "line";
                case ChartType.BarChart: return "bar";
                case ChartType.LineChart: return "line";
                case ChartType.PieChart: return "pie";
                case ChartType.DonutChart: return "doughnut";
                case ChartType.Sankey: return "sankey";
                case ChartType.TreeMap: return "treemap" as keyof ChartTypeRegistry;
            }
        }
    }
}