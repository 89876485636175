import React from "react";
import useNotifications from "../../../hooks/useModal";
import { ModalType } from "../../../state/modal/modal.state";
import { Menu, MenuButtonProps, MenuItem, MenuList, MenuPopover, MenuTrigger, Spinner, SplitButton, Text } from "@fluentui/react-components";
import { ArrowClockwiseRegular, ArrowUploadRegular, CheckmarkRegular, DeleteRegular } from "@fluentui/react-icons";
import { formatFileSize, formatNumber } from "../../../util/formatter";
import Flex from "../container/Flex";
import { IUploadableFile } from "../../../types/googlecloudstorage.types";

interface IFileSelectProps {
    formats?: string,
    value: IUploadableFile,
    onChange: (data: IUploadableFile) => void,
    onClear?: () => void,
    textOnEmpty?: string,
    textOnSelected?: string
}

export default function FileSelect(props: IFileSelectProps) {

    const {
        textOnEmpty,
        textOnSelected,
        onChange,
        formats,
        value,
        onClear
    } = props;

    const [loading, setLoading] = React.useState<boolean>(false);
    const [file, setFile] = React.useState<File>();

    const {
        showNotification
    } = useNotifications();

    const fileSelectRef = React.useRef<HTMLInputElement>(null);

    const preventDefaults = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
    }

    const handleSelectedFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
        preventDefaults(e);
        if (!e || !e.target || !e.target.files || !e.target.files.length) return;
        handleFiles(e.target.files);
    }

    const toBase64 = (file: File) => new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result.toString());
        reader.onerror = reject;
    });

    const uploadFile = async (file: File) => {
        setLoading(true);
        setFile(file);

        const content = await toBase64(file);

        onChange({
            content: content,
            fileId: "",
            name: file.name,
            size: file.size,
            mime: file.type
        });

        setLoading(false);
    }

    const handleFiles = (files: FileList) => {
        if (files.length > 1) {
            showNotification({text: "Bitte nur eine Datei auswählen.", type: ModalType.Error});
            return;
        }

        uploadFile(files[0]);
    }

    const removeFile = async () => {
        setFile(undefined);
        if (!!onClear) onClear();        
        if (fileSelectRef && fileSelectRef.current) fileSelectRef.current.value = "";
    }

    const canDelete = !loading && (value && onClear);

    return (
        <Flex fullWidth>
            <div className="d-flex w-100 flex-row align-items-start justify-content-between">
                <Flex fullWidth>
                    <span className="d-flex flex-row align-items-center">
                        {
                            value
                            ? (textOnSelected || value.name)
                            : (textOnEmpty || "Keine Datei ausgewählt")
                        }
                    </span>
                    { value && (
                        <Flex row>
                            <CheckmarkRegular />
                            <Text size={200}>Datei hochgeladen ({formatFileSize(value.size)})</Text>
                        </Flex>
                    )}
                </Flex>
                <Menu>
                    <MenuTrigger>
                        {(triggerProps: MenuButtonProps) => (
                            <SplitButton
                                primaryActionButton={{
                                    onClick: async () => fileSelectRef && fileSelectRef.current && fileSelectRef.current.click(),
                                    disabled: loading,
                                    icon: loading ? <Spinner size="extra-tiny" /> : (canDelete ? <ArrowClockwiseRegular /> : <ArrowUploadRegular />),
                                }}
                                menuButton={canDelete ? triggerProps : { disabled: true, hidden: true }}
                            >
                                <Text wrap={false}>
                                    {
                                        loading
                                        ? "Wird verarbeitet..."
                                        : (value ? "Andere Datei wählen" : "Datei auswählen")
                                    }
                                </Text>
                            </SplitButton>
                        )}
                    </MenuTrigger>
                    <MenuPopover>
                        <MenuList>
                            <MenuItem 
                                icon={<DeleteRegular />}
                                onClick={value ? removeFile : onClear}
                                disabled={loading}
                            >
                                {
                                    file ? "Datei entfernen" : "Daten löschen"
                                }
                            </MenuItem>
                        </MenuList>
                    </MenuPopover>
                </Menu>
            </div>
            <input 
                ref={fileSelectRef} 
                accept={formats || "*"} 
                style={{visibility: "hidden", opacity: 0, width: 0, height: 0, overflow: "hidden !important"}} 
                type="file" 
                onChange={(e) => handleSelectedFiles(e)} 
            />
        </Flex>
    )
}