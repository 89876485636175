import { MenuItem, Spinner } from "@fluentui/react-components";
import React from "react";

export interface ICustomMenuButtonProps {
    onClick?: () => (Promise<any> | any),
    text: string,
    secondStepQuestion?: string,
    icon?: React.ReactElement
}

export default function CustomMenuButton(props: ICustomMenuButtonProps) {
    const {
        onClick,
        secondStepQuestion,
        icon,
        text
    } = props;

    const [loading, setLoading] = React.useState<boolean>(false);
    const [secondStepActivated, setSecondStepActivated] = React.useState<boolean>(false);

    const handleClick = async () => {
        if (!onClick) return;
        
        if (secondStepQuestion && !secondStepActivated) {
            setSecondStepActivated(true);
            return;
        }

        setLoading(true);
        setSecondStepActivated(false);
        await onClick();
        setLoading(false);
    }

    const realIcon = (
        loading
        ? <Spinner size="extra-tiny" />
        : icon
    )

    const realText = (
        secondStepQuestion && secondStepActivated
        ? secondStepQuestion
        : text
    )

    return (
        <MenuItem
            disabled={loading}
            onClick={handleClick}
            icon={realIcon}
            persistOnClick
        >
            {realText}
        </MenuItem>
    )
}