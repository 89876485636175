import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import "./ModalBanner.css";
import { useAppSelector } from "../../../state/reduxHooks";
import useModal from "../../../hooks/useModal";
import { generateStyle } from "../../../hooks/useAttributes";
import { Button, MessageBar, MessageBarActions, MessageBarBody, MessageBarGroup, MessageBarTitle, Text } from "@fluentui/react-components";
import { DismissRegular } from "@fluentui/react-icons";
import Flex from "../container/Flex";
import { IModalNotification } from "../../../state/modal/modal.state";
import useNotifications from "../../../hooks/useModal";

export default function ModalBanner() {

    const { messages } = useAppSelector(state => state.modal);

    return (
        <MessageBarGroup className="modal-banner d-flex align-items-center justify-content-between">
            {
                messages && messages.length
                ? messages.map((message, index) => <ModalNotification key={index} content={message} />)
                : null
            }
        </MessageBarGroup>
    )
}

function ModalNotification({content}: {content: IModalNotification}) {
    
    const [showTimer, setShowTimer] = React.useState<any>(null);

    const {
        text,
        id,
        title,
        type
    } = content;
    
    const {
        hideNotification
    } = useNotifications();

    React.useEffect(() => {
        clearTimeout(showTimer);
        const timer = setTimeout(() => hideNotification(id), 3000);
        setShowTimer(timer);
        return () => clearTimeout(showTimer);
    }, [id]);
    
    return (
        <MessageBar intent={type}>
            <MessageBarBody className="p-2">
                <Flex row wrap>
                    {
                        title && <MessageBarTitle>{title}</MessageBarTitle>
                    }
                    <Text wrap>{text}</Text>
                </Flex>
            </MessageBarBody>
            <MessageBarActions
                containerAction={
                    <Button icon={<DismissRegular />} area-label="dismiss" appearance="transparent" onClick={() => hideNotification(id)} />
                }
            />
        </MessageBar>
    )
}