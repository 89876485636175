import React from "react";

export enum Breakpoint {
    Tiny = 360,
    Mobile = 768,
    Tablet = 992,
    Desktop = 1400,
    Wide = 1800
}

export enum Height {
    Small = 640,
    Mobile = 800,
    Tablet = 1000,
    Desktop = 1200,
    High = 1400
}

export default function useResponsive(matchThis: Breakpoint = Breakpoint.Mobile, matchHeight: Height = Height.Mobile) {
    
    const [breakpointMatched, setBreakpointMatched] = React.useState<boolean>(window.innerWidth < matchThis);
    const [breakpoint, setBreakpoint] = React.useState<Breakpoint>();
    
    const [heightMatched, setHeightMatched] = React.useState<boolean>(window.innerHeight < matchHeight);
    const [height, setHeight] = React.useState<Height>();

    const resizeTimer = React.useRef<any>();

    React.useEffect(() => {
        function updateSize() {
            clearTimeout(resizeTimer.current);
            resizeTimer.current = setTimeout(() => {
                setBreakpointMatched(window.innerWidth <= matchThis);
                setBreakpoint(getBreakpoint());
            }, 50);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    React.useEffect(() => {
        function updateHeight() {
            clearTimeout(resizeTimer.current);
            resizeTimer.current = setTimeout(() => {
                setHeightMatched(window.innerHeight <= matchHeight);
                setHeight(getHeight());
            }, 50);
        }
        window.addEventListener('resize', updateHeight);
        updateHeight();
        return () => window.removeEventListener('resize', updateHeight);
    }, []);


    const getHeight = (): Height => {
        if (window.innerHeight <= Height.Mobile) return Height.Small;
        if (window.innerHeight <= Height.Tablet) return Height.Mobile;
        if (window.innerHeight <= Height.Desktop) return Height.Tablet;
        if (window.innerHeight <= Height.High) return Height.Desktop;
        return Height.High;
    }

    const getBreakpoint = (): Breakpoint => {
        if (window.innerWidth <= Breakpoint.Mobile) return Breakpoint.Tiny;
        if (window.innerWidth <= Breakpoint.Tablet) return Breakpoint.Mobile;
        if (window.innerWidth <= Breakpoint.Desktop) return Breakpoint.Tablet;
        if (window.innerWidth <= Breakpoint.Wide) return Breakpoint.Desktop;
        return Breakpoint.Wide;
    }

    return {
        isNarrower: breakpointMatched,
        currentBreakpoint: breakpoint,
        isLower: heightMatched,
        currentHeight: height
    }
}