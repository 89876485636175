import React from "react";
import Page from "../components/container/Page";
import { useNavigate } from "react-router-dom";
import { Link, Button, Text, Card } from "@fluentui/react-components";
import { AppRoutes, IAppRoute } from "../components/menu/Sidebar";
import { ArrowDownloadRegular, ArrowLeftRegular, ArrowStepBackRegular } from "@fluentui/react-icons";
import Flex from "../components/container/Flex";
import { useUser } from "../../state/user/useUser";
import CustomButton from "../components/button/CustomButton";

export default function Help() {

    const navigate = useNavigate();

    const {
        user
    } = useUser();

    const downloadSampleData = () => {
        var blob = new Blob(["Kontonummer;Kontenbeschriftung;EbWertSoll;EbWertHaben;JvzSoll;JvzHaben;SaldoSoll;SaldoHaben\n135;EDV-Software;3,00;0,00;0,00;0,00;3,00;0,00"], { type: 'text/csv;charset=utf-8;' });
        var link = document.createElement("a");
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        if (link.download) link.setAttribute("download", "sample_data.txt");
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const createTitle = (s: string, link?: IAppRoute, linkText?: string) => (
        <Flex row fullWidth justify="between">
            <Text size={500} weight="bold">{s}</Text>
            {
                link && user && (user.isCustomerAdmin || user.isGlobalAdmin) && (
                    <Button
                        appearance="subtle"
                        onClick={() => navigate(link.path)}
                    >
                        {linkText}
                    </Button>
                )
            }
        </Flex>

    )
    
    const createLink = (r: IAppRoute, label: string) => (
        <Link 
            href={r.path}
            inline
        >
            {label}
        </Link>
    )

    return (
        <Page 
            title="Hilfe" 
            className={!user && "p-5"}
            action={
                !user && (
                    <Button 
                        onClick={() => navigate(AppRoutes.Dashboard.path)}
                        icon={<ArrowLeftRegular />}
                    >
                        Zurück
                    </Button>
                )
            }
        >
            <Card className="w-100">
                {createTitle("Einleitung")}
                <Text>
                    Mit SBT können Sie Ihre Exportdaten aus dem Buchhaltungssystem in schöne Diagramme verwandeln. 
                </Text>
                <Card appearance="filled-alternative" className="w-100">
                    <Text>
                        <strong>Neukunde oder anderes Thema?</strong><br/>
                        Sie möchten SBT in Ihrer Kanzlei verwenden oder haben eine Frage, die sich nicht auf die Funktion des Systems bezieht?
                        <br/>
                        Dann freuen wir uns auf Ihre E-Mail an <Link href="mailto:winkekatze@steuerkoepfe.de">winkekatze@steuerkoepfe.de</Link>.
                    </Text>
                </Card>
            </Card>
            <Card className="w-100">
                {createTitle("Grundstruktur", AppRoutes.Mappings, "Verwaltung")}
                <Text>
                    SBT basiert auf einem an Kontenrahmen angelehnten Zuordnungssystem. Innerhalb eines Zuordnungssystems können mehrere Zuordnungsgruppen angelegt werden. Eine Zuordnungsgruppe kann hierbei mit einem Kontenrahmen verglichen werden. Die Anlage mehrerer solcher Gruppen ermöglicht es auch Sonderfälle ordnungsgemäß abzubilden.
                    Ihre Zuordnungsgruppen können Sie oder Ihr Administrator {createLink(AppRoutes.Mappings, "hier")} verwalten.
                    <br/><br/>
                    Innerhalb dieser Zuordnungsgruppen können Sie dann Kontengruppen anlegen. Diese hat einen Namen und eine Liste an zugeordneten Konten. Sie können sowohl einzelne Konten oder Konten innerhalb eines Bereichs hinzufügen. Ein Beispiel für eine Kontengruppe wäre etwa "Umsatzerlöse" mit allen Konten mit den Nummern 4000 bis 5000.
                    <br/><br/>  
                    Wir stellen Ihnen einige Zuordnungsgruppen zur Verfügung, die für die meisten Fälle ausreichen werden. Sie können sich aber auch selbst Gruppen erstellen, hierfür wenden Sie sich an Ihren Administrator.
                </Text>
            </Card>
            <Card className="w-100">
                {createTitle("Zahlenwelt", AppRoutes.Dashboard, "Dashboard")}
                <Text>
                    Um Ihre Kontengruppen zu verwenden, müssen Sie das System mit Zahlen füttern. Dies geschieht über den Import-Dialog.
                    <br/><br/>
                    <Card className="w-100" appearance="filled-alternative">
                        <Text>
                            <strong>Wichtig:</strong> Ihre Zahlendaten werden <strong>niemals</strong> in das Internet übertragen. Sämtliche Verarbeitung findet ausschließlich lokal auf Ihrem Rechner statt! Die importierten Zahlen werden außerdem ausschließlich auf Ihrem Gerät lokal gespeichert.
                        </Text>
                    </Card>
                    <br/>
                    Im Import-Dialog können Sie sowohl das aktuelle Geschäftsjahr eingeben als auch für jedes der letzten fünf Geschäftsjahre einen Zahlenimport vornehmen. Nach dem Import können Sie sich die jeweiligen Ergebnisse für die von Ihnen erstellten Kontengruppen ansehen.
                    <br/><br/>  
                    Aktuell unterstützen wir den Import der Summen- und Saldenliste aus DATEV. Diese ist ein Textdokument (.txt, .csv). Diese Datei kann aus DATEV einfach exportiert werden.
                    <br/><br/>Die Datei muss folgende Spalten in einem Trennzeichenformat beinhalten:<br/>"Kontonummer;Kontenbeschriftung;EbWertSoll;EbWertHaben;JvzSoll;JvzHaben;SaldoSoll;SaldoHaben". Jede Spalte sowie deren Werte muss entsprechend mit einem Semikolon getrennt sein. Sie können eine solche CSV-Datei mit Microsoft Excel erstellen und bearbeiten.<br/><br/>
                    <CustomButton
                        onClick={downloadSampleData}
                        icon={<ArrowDownloadRegular />}
                        text="Beispieldatei herunterladen"
                    />
                </Text>
            </Card>
            <Card className="w-100">
                {createTitle("Diagramme", AppRoutes.Dashboard, "Diagramme")}
                <Text>
                    Die erstellten Kontengruppen können Sie dann in Diagramme verwandeln. Sie können für jedes Diagramm auswählen, welche Kontengruppen aus welcher Zuordnungsgruppe verwendet werden soll. Dies ermöglicht es, ein Diagramm für mehrere Mandanten mit unterschiedlichen Kontenrahmen zu verwenden.
                    Für jedes Diagramm können Sie auswählen, welche Jahre verwendet werden sollen. Dies ermöglicht es, Vorjahresvergleiche oder sogar Fünfjahresvergleiche zu erstellen.
                    <br/><br/>
                    Nach dem Import von Zahlen müssen Sie auswählen, für welche Zuordnungsgruppe Sie Diagramme generieren möchten. Sie können die aktuelle Zuordnungsgruppe jederzeit wechseln, eine Einstellung ist hier jedoch wichtig, damit das System aus Ihrem Import die korrekten Zahlen berechnen kann.
                    <br/><br/>
                    Sobald Sie Ihre Zahlen importiert und die Zuordnungsgruppe ausgewählt haben, können Sie Diagramme in Office einfügen. Gerne können Sie auch im Web auf unser System zugreifen, dort können Sie die Diagramme als Bild kopieren, falls das für Sie praktischer ist.
                    <br/><br/>
                    Mit Administrationsrechten können Sie durch einen Klick auf Ihr Profil das Einstellungsmenü aufrufen. Hier können Sie Ihre Firmenfarben hinterlegen. Diese Farbe wird verwendet, um eine Palette an ähnlichen Farben zu generieren und Ihren Diagrammen das erwartete Aussehen zu verpassen.
                </Text>
            </Card>
            <Card className="w-100">
                {createTitle("Präsentationsvorlagen", AppRoutes.SlideDecks, "Präsentationen")}
                <Text>
                    Nachdem Sie einige Diagramme erstellt haben, können Sie diese als Präsentationsvorlage zusammenfassen. Diese Vorlage beinhalten für jede Folie Ihrer Präsentation ein oder mehrere Diagramme. Sie können für jedes Diagramm festlegen, wie groß dieses sein soll oder an welcher Stelle es angewendet wird.
                    <br/><br/>
                    Diese Präsentationsvorlagen können Sie dann in Office anwenden. Hierfür werden die hinterlegten Folien ans Ende Ihrer aktuellen Präsentation eingefügt.
                    <br/><br/>
                    Bitte beachten: Wir bieten keine vollumfängliche Konfiguration der Folien an, da dies technisch mit der aktuellen Office-Schnittstelle nicht möglich ist. Sie müssen also das Design und Layout der Folien nach dem Einfügen selbst anpassen. Dies betrifft etwa Textboxen oder Folienhintergründe.
                    <br/><br/>
                    Nichtsdestotrotz ist eine Präsentationsvorlage eine gute Möglichkeit, viele einzelne Diagramme gleichzeitig einzufügen und schnell eine Präsentation zu erstellen.
                </Text>
            </Card>
        </Page>
    )
}