import React from "react";
import { ICustomer, ICustomerDocument } from "../../../types/customer.schema";
import ModalForm from "../modal/ModalForm";
import { AddRegular, EditRegular } from "@fluentui/react-icons";
import useApi from "../../../api/useApi";
import Flex from "../container/Flex";
import FormikField from "../formik/FormikField";
import { useCustomers } from "../../../state/customers/useCustomers";

export interface IUpdateCustomerFormProps {
    customer?: ICustomer
}

export default function UpdateCustomerForm(props: IUpdateCustomerFormProps) {
    const {
        customer
    } = props;

    const {
        reloadCustomers
    } = useCustomers();

    const {
        customersCreate,
        customersUpdate
    } = useApi();

    return (
        <ModalForm
            text={customer ? "Bearbeiten" : "Neuer Kunde"}
            icon={customer ? <EditRegular /> : <AddRegular /> }
            title={customer ? "Kunden bearbeiten" : "Neuen Kunden erstellen"}
            initialValues={{
                name: customer?.name ?? ""
            } as ICustomerDocument}
            onSubmit={async (values) => {
                const res = (
                    customer
                    ? await customersUpdate(customer._id, values as ICustomer)
                    : await customersCreate(values)
                ).success;

                if (res) await reloadCustomers();

                return res;
            }}
        >
            {
                formik => (
                    <Flex>
                        <FormikField name="name" placeholder="Name des Kunden" label="Name des Kunden" />
                    </Flex>
                )
            }
        </ModalForm>
    )
}