import React from "react";
import { IUser } from "../../../types/user.schema";
import useApi from "../../../api/useApi";
import { useUsers } from "../../../state/user/useUsers";
import CustomButton from "../button/CustomButton";
import { DeleteRegular, MoreHorizontalRegular } from "@fluentui/react-icons";
import { Button, Menu, MenuDivider, MenuGroup, MenuGroupHeader, MenuList, MenuPopover, MenuTrigger } from "@fluentui/react-components";
import CustomMenuButton from "../button/CustomMenuButton";
import { useUser } from "../../../state/user/useUser";
import useResponsive, { Breakpoint } from "../../../hooks/useResponsive";

export interface IDeleteUserButtonProps {
    user: IUser
}

export default function DeleteUserButton(props: IDeleteUserButtonProps) {
    const {
        user
    } = props;

    const {
        reloadUsers
    } = useUsers(user.customer);

    const {
        user: currentUser
    } = useUser();

    const {
        usersDelete
    } = useApi();

    const clickHandler = async () => {
        const res = await usersDelete(user._id);
        if (!res.success) return;
        await reloadUsers();
    }

    if (user._id === currentUser?._id) return null;
    if (user.isGlobalAdmin && !currentUser?.isGlobalAdmin) return null;

    return (
        <Menu>
            <MenuTrigger>
                <Button
                    icon={<MoreHorizontalRegular />}
                    appearance="transparent"
                />
            </MenuTrigger>
            <MenuPopover>
                <MenuList>
                    <MenuGroup>
                        <MenuGroupHeader>Gefahrenzone</MenuGroupHeader>
                        <CustomMenuButton
                            text="Benutzer löschen"
                            secondStepQuestion="Sicher?"
                            onClick={clickHandler}
                            icon={<DeleteRegular />}
                        />
                    </MenuGroup>
                </MenuList>
            </MenuPopover>
        </Menu>
    )
}