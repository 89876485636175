import React from "react";
import Flex from "../container/Flex";
import { Spinner } from "@fluentui/react-components";
import Page from "../container/Page";

export default function FullScreenSpinner() {
    return (
        <Page fullScreen centered noBackground>
            <Spinner size="huge" />
        </Page>
    )
}