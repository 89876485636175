import React from "react";

export type AppColor = "primary" | "secondary" | "success" | "danger" | "warning" | "info" | "light" | "dark";

export interface IAppColor {
    bg: string,
    fg: string,
    getStyle: (inverted?: boolean, onlyColor?: boolean, hover?: boolean) => React.CSSProperties
}

const createParsedAppColor = (back: string, fore: string, backHover: string, foreHover: string, hover: boolean = false): IAppColor => ({
    bg: hover ? back : backHover,
    fg: hover ? fore : foreHover,
    getStyle: (inverted: boolean = false, onlyColor: boolean = false) => ({
        backgroundColor: (
            onlyColor 
            ? undefined 
            : (
                inverted 
                ? hover ? foreHover : fore 
                : hover ? backHover : back
            )
        ),
        color: (
            inverted 
            ? hover ? backHover : back 
            : hover ? foreHover : fore
        )
    })
})

export const parseAppColor = (color: AppColor, hover: boolean = false): IAppColor => {
    switch (color) {
        case "primary": return createParsedAppColor("var(--colorBrandBackground)", "#FFFFFF", "var(--colorBrandBackgroundHover)", "#FFFFFF", hover);
        case "secondary": return createParsedAppColor("var(--colorBrandBackground2)", "#FFFFFF", "var(--colorBrandBackground2Hover)", "#FFFFFF", hover);
        case "success": return createParsedAppColor("var(--colorStatusSuccessForeground1)", "#FFFFFF", "var(--colorStatusSuccessBackground2)", "#000000", hover);
        case "danger": return createParsedAppColor("var(--colorStatusDangerForeground1)", "#FFFFFF", "var(--colorStatusDangerBackground3)", "#FFFFFF", hover);
        case "warning": return createParsedAppColor("var(--colorStatusWarningForeground1)", "#000000", "var(--colorStatusWarningBackground2)", "#000000", hover);
        case "info": return createParsedAppColor("var(--colorStatusInfoForeground1)", "#000000", "var(--colorStatusInfoBackground2)", "#000000", hover);
        case "light": return createParsedAppColor("var(--colorNeutralBackground1)", "#000000", "var(--colorNeutralBackground2)", "#000000", hover);
        case "dark": return createParsedAppColor("var(--colorNeutralForeground1)", "#FFFFFF", "var(--colorNeutralForeground2)", "#FFFFFF", hover);
    }
}

/* hexToComplimentary : Converts hex value to HSL, shifts
 * hue by 180 degrees and then converts hex, giving complimentary color
 * as a hex value
 * @param  [String] hex : hex value  
 * @return [String] : complimentary color as hex value
 */
export const hexToComplimentary = (hex: string) => {


    const rgb = hexToRgb(hex);
    const maxColor = Math.max(rgb.r, rgb.g, rgb.b);

    // Convert RGB to HSL
    // Adapted from answer by 0x000f http://stackoverflow.com/a/34946092/4939630
    let r = rgb.r === maxColor ? 0.9 : rgb.r / 255.0;
    let g = rgb.b === maxColor ? 0.9 : rgb.g / 255.0;
    let b = rgb.b === maxColor ? 0.9 : rgb.b / 255.0;

    let max = Math.max(r, g, b);
    let min = Math.min(r, g, b);

    let h: number, s: number, l: number = (max + min) / 2.0;

    if (max == min) 
        h = s = 0;
    else {
        let d = max - min;
        s = (l > 0.5 ? d / (2.0 - max - min) : d / (max + min));

        if(max == r && g >= b) {
            h = 1.0472 * (g - b) / d ;
        } else if(max == r && g < b) {
            h = 1.0472 * (g - b) / d + 6.2832;
        } else if(max == g) {
            h = 1.0472 * (b - r) / d + 2.0944;
        } else if(max == b) {
            h = 1.0472 * (r - g) / d + 4.1888;
        }
    }

    h = h / 6.2832 * 360.0 + 0;

    // Shift hue to opposite side of wheel and convert to [0-1] value
    h+= 180;
    if (h > 360) { h -= 360; }
    h /= 360;

    // Convert h s and l values into r g and b values
    // Adapted from answer by Mohsen http://stackoverflow.com/a/9493060/4939630
    if(s === 0){
        r = g = b = l; // achromatic
    } else {
        var hue2rgb = function hue2rgb(p, q, t){
            if(t < 0) t += 1;
            if(t > 1) t -= 1;
            if(t < 1/6) return p + (q - p) * 6 * t;
            if(t < 1/2) return q;
            if(t < 2/3) return p + (q - p) * (2/3 - t) * 6;
            return p;
        };

        var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        var p = 2 * l - q;

        r = hue2rgb(p, q, h + 1/3);
        g = hue2rgb(p, q, h);
        b = hue2rgb(p, q, h - 1/3);
    }

    r = Math.round(r * 255);
    g = Math.round(g * 255); 
    b = Math.round(b * 255);

    // Convert r b and g values to hex
    let resultRgb = b | (g << 8) | (r << 16); 
    return "#" + (0x1000000 | resultRgb).toString(16).substring(1);
}  

export const hexWithBrightness = (color: string, brightness: number, isVar: boolean = false): string => {
    try {
        const realInitialColor = isVar ? parseVarColorToHex(color) : color;
        const realBrightness = brightness < 0 ? 0 : brightness;

        const rgb = hexToRgb(realInitialColor);

        if (!rgb) return color;

        const r = Math.floor(rgb.r * realBrightness);
        const g = Math.floor(rgb.g * realBrightness);
        const b = Math.floor(rgb.b * realBrightness);

        return rgbToHex(r, g, b);
    }
    catch { }

    return color;
}

export const hexWithOpacity = (color: string, opacity: number, isVar: boolean = false) => {
    try {
        const realInitialColor = isVar ? parseVarColorToHex(color) : color;
        const realOpacity = opacity < 0 ? 0 : (opacity > 1 ? 1 : opacity);
        const opacityHex = Math.floor(realOpacity * 255).toString(16).padStart(2, "0");

        const realColor = realInitialColor ? realInitialColor.replace("#", "").substring(0, 6) : "000000";

        if (opacity >= 1) return realColor;

        return `#${realColor}${opacityHex}`;
    }
    catch { }

    return color;
}

export const generatePleasantBlueHex = () => {
    const hasMoreGreen = Math.random() > 0.5;
    const r = Math.floor(Math.random() * 50);
    const g = (hasMoreGreen ? 50 : 0) + Math.floor(Math.random() * 100);
    const b = 110 + Math.floor(Math.random() * 106); // 150 to 255

    return rgbToHex(r, g, b);
}

export const rgbToHex = (r: number, g: number, b: number): string => {
    const toHex = (value: number) => {
        const usableValue = value < 0 ? 0 : (value > 255 ? 255 : value);
        let hex = usableValue.toString(16);
        return hex.length < 2 ? "0" + hex : hex;
    };

    return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}

export const getColorOrShade = (color?: string) => {
    if (!color) return generatePleasantBlueHex();
    if (color === "#F0F0F0") return generatePleasantBlueHex();
    return color;
}


export const parseVarColorToHex = (color?: string, standard: string = "#FFFFFF", opacity: number = 1): string => {

    const getColor = (): string => {
        if (!color) return parseVarColorToHex(standard);
        if (color.includes("#") && (color.length === 7 || color.length === 4 || color.length === 9)) return color;
        
        const computedValue = getComputedStyle(document.documentElement).getPropertyValue(`--${color}`);
        
        if (!computedValue) return "#FFFFFF";
    
        return computedValue;
    }

    return hexWithOpacity(getColor(), opacity);
}

export interface IRgbColor {
    r: number, 
    b: number, 
    g: number,
    stringified: string
}

export const hexToRgb = (hex: string, opacity?: number): IRgbColor | null => {
    if (!hex || typeof hex !== "string") return null;

    var result = /^#?([a-fA-F\d]{2})([a-fA-F\d]{2})([a-fA-F\d]{2})$/i.exec(hex);

    if (!result) return null;

    const r = parseInt(result[1], 16);
    const g = parseInt(result[2], 16);
    const b = parseInt(result[3], 16);

    return {
        r,
        g,
        b,
        stringified: `rgba(${r}, ${g}, ${b}, ${opacity ?? 1})`
    };
}

export const getTextColorFromBackground = (color?: string, standard: string = "#000000"): string => {

    const realColor = parseVarColorToHex(color, standard);

    if (!realColor) return standard;

    const rgb = hexToRgb(realColor);

    if (!rgb) return standard;

    const brightness = (rgb.r*0.299 + rgb.g*0.587 + rgb.b*0.114);

    if (brightness > 186) return "#000000";
    
    return "#FFFFFF";

}