import { ErrorMessage, Field, FieldAttributes, Formik, useFormikContext } from "formik";
import React from "react";
import "./FormikField.css";
import IElementProps from "../../../types/element.types";
import { generateClassName } from "../../../hooks/useAttributes";
import { Button, Input, InputOnChangeData, Text } from "@fluentui/react-components";
import useFormikValue from "../../../hooks/useFormikValue";
import { EyeOffRegular, EyeRegular } from "@fluentui/react-icons";

type FieldType = "number" | "search" | "text" | "time" | "email" | "password" | "tel" | "url" | "date" | "datetime-local" | "month" | "week";

interface IFormikFieldProps extends IElementProps {
    label?: string,
    placeholder?: string,
    inputClass?: string,
    min?: string,
    max?: string,
    step?: string,
    defaultValue?: any,
    rounded?: boolean,
    name: string,
    noMargin?: boolean,
    input?: React.ReactNode,
    onChange?: (e: InputOnChangeData) => void,
    readOnly?: boolean,
    appearance?: "outline" | "underline" | "filled-darker" | "filled-lighter" | "filled-darker-shadow" | "filled-lighter-shadow",
    passwordVisibleInitially?: boolean,
    bold?: boolean,
    type?: FieldType,
    inline?: boolean,
    noHover?: boolean,
    autoComplete?: "off" | "on",
    onBlur?: () => void,
    onFocus?: () => void
}

export default function FormikField<T>(props: IFormikFieldProps) {
    
    const {
        handleChange,
        values
    } = useFormikContext<T>();

    const {
        label, 
        rounded, 
        appearance = "filled-darker",
        inline, 
        passwordVisibleInitially,
        noHover,
        defaultValue,
        readOnly, 
        input, 
        min, 
        max, 
        autoComplete,
        onFocus, 
        onChange,
        onBlur, 
        step, 
        placeholder, 
        className = "w-100", 
        type,
        inputClass, 
        name, 
        bold = false,
        style,
    } = props;
    
    const [passwordVisible, setPasswordVisible] = React.useState<boolean>(passwordVisibleInitially ?? false);

    const fieldId = `${name}field`;

    const controlClass = generateClassName(inputClass, "form-control w-100 field-with-label", {
        value: rounded,
        onTrue: "field-with-label-rounded"
    }, {
        value: noHover,
        onTrue: "field-no-hover"
    });

    const containerClass = generateClassName(className, "d-flex", {
        value: inline,
        standard: "flex-column align-items-start gap-1",
        onTrue: "flex-row align-items-center gap-3"
    });

    return (
        <div className={containerClass}>
            {
                label && <Text className="w-100" wrap={false} weight={bold ? "bold" : "regular"} truncate={false} >{label}</Text>
            }
            <div className="position-relative w-100">
                <Field name={name} >
                    {
                        ({ field, form: { }, meta }: FieldAttributes<any>) => (
                            input || (
                                <Input 
                                    autoComplete={autoComplete}
                                    style={style}
                                    onBlur={onBlur} 
                                    value={field.value}
                                    onFocus={onFocus} 
                                    contentAfter={type === "password" && (
                                        <Button
                                            appearance="transparent"
                                            onClick={() => setPasswordVisible(!passwordVisible)}
                                            icon={passwordVisible ? <EyeOffRegular /> : <EyeRegular />}
                                        />
                                    )}
                                    disabled={readOnly} 
                                    min={min} 
                                    appearance={appearance}
                                    max={max} 
                                    type={passwordVisible ? "text" : type}
                                    step={step} 
                                    id={fieldId} 
                                    name={name} 
                                    onChange={handleChange}
                                    placeholder={placeholder || label} 
                                    className={controlClass} 
                                />
                            )
                        )
                    }
                </Field>
            </div>
            <ErrorMessage name={name} render={(msg) => <span className="mt-2" style={{color: "#FF0000"}}>{msg}</span>} />
        </div>
    )
}