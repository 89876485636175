import { useAppSelector } from "../state/reduxHooks";
import { Year } from "../types/mapping.schema";

export default function useYearUtil(useThisYearInstead?: number) {

    const {
        currentYear     
    } = useAppSelector(state => state.numbersImport);



    const getYearLabel = (defaultValue: string, yearsInThePast: number = 0) => {
        if (!currentYear && !useThisYearInstead) return defaultValue;
        const year = (useThisYearInstead || currentYear) - yearsInThePast;
        return year.toString();
    }

    const getLabelForYear = (year: Year): string => {
        switch (year) {
            case Year.Current: return getYearLabel("Aktuelles Jahr", 0);
            case Year.First: return getYearLabel("Jahr N - 1", 1);
            case Year.Second: return getYearLabel("Jahr N - 2", 2);
            case Year.Third: return getYearLabel("Jahr N - 3", 3);
            case Year.Fourth: return getYearLabel("Jahr N - 4", 4);
        }
    }

    const getFancyYears = (): Array<{year: Year, label: string}> => {
        return [
            { year: Year.Current, label: getLabelForYear(Year.Current) },
            { year: Year.First, label: getLabelForYear(Year.First) },
            { year: Year.Second, label: getLabelForYear(Year.Second) },
            { year: Year.Third, label: getLabelForYear(Year.Third) },
            { year: Year.Fourth, label: getLabelForYear(Year.Fourth) }
        ]
    }

    const YearArray = [ Year.Current, Year.First, Year.Second, Year.Third, Year.Fourth ];

    const getNextYear = (y: Year): Year | null => {
        switch (y) {
            case Year.Current: return null;
            case Year.First: return Year.Current;
            case Year.Second: return Year.First;
            case Year.Third: return Year.Second;
            case Year.Fourth: return Year.Third;
        }
    }

    const getLastYear = (y: Year): Year | null => {
        switch (y) {
            case Year.Current: return Year.First;
            case Year.First: return Year.Second;
            case Year.Second: return Year.Third;
            case Year.Third: return Year.Fourth;
            case Year.Fourth: return null;
        }
    }

    const formatYear = (index: number): string => {
        if (index >= YearArray.length || index < 0) return getLabelForYear(Year.Current);
        return getLabelForYear(YearArray[index]);
    }

    return {
        getLabelForYear,
        formatYear,
        fancyYears: getFancyYears(),
        getLastYear,
        getNextYear,
        years: YearArray
    }
}