import React from "react";
import { IMapping } from "../../../types/mapping.schema";
import { useMappingGroups } from "../../../state/mappingGroups/useMappingGroups";
import useApi from "../../../api/useApi";
import CustomButton from "../button/CustomButton";
import { DeleteRegular } from "@fluentui/react-icons";
import { useUser } from "../../../state/user/useUser";
import { IMappingGroup } from "../../../types/mappingGroup.schema";
import { ISlideDeck } from "../../../types/slideDeck.schema";
import { useSlideDecks } from "../../../state/slideDecks/useSlideDecks";
import useResponsive, { Breakpoint } from "../../../hooks/useResponsive";
import useOfficeVersion from "../../../hooks/useOfficeVersion";

export interface IDeleteSlideDeckButtonProps {
    slideDeck: ISlideDeck
}

export default function DeleteSlideDeckButton(props: IDeleteSlideDeckButtonProps) {
    const {
        slideDeck
    } = props;

    const {
        isNarrower
    } = useResponsive(Breakpoint.Mobile);
    
    const {
        isOpenOutsideOfOffice
    } = useOfficeVersion();

    const {
        user
    } = useUser();

    const {
        reloadSlideDecks
    } = useSlideDecks();

    const {
        slideDecksDelete     
    } = useApi();

    const clickHandler = async () => {
        const res = await slideDecksDelete(slideDeck._id);
        if (!res || !res.success) return;
        await reloadSlideDecks();
    }

    if (!isOpenOutsideOfOffice) return null;
    if (slideDeck.isGlobalPreset && !user.isGlobalAdmin) return null;

    return (
        <CustomButton
        text={!isNarrower && "Entfernen"}
            onClick={clickHandler}
            color="danger"
            icon={<DeleteRegular />}
            secondStepQuestion="Sicher?"
        />
    )
}