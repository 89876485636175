import React from "react";
import Page from "../components/container/Page";
import SlideDeckList from "../components/slideDecks/SlideDeckList";
import UpdateSlideDeckForm from "../components/slideDecks/UpdateSlideDeckForm";
import UpdateUserForm from "../components/user/UpdateUserForm";
import UserList from "../components/user/UserList";
import { useCustomer } from "../../state/customers/useCustomer";
import { Spinner } from "@fluentui/react-components";
import { ICustomer } from "../../types/customer.schema";

export default function Users() {
    const {
        customer: currentCustomer,
        loadingCustomer
    } = useCustomer();

    const [customer, setCustomer] = React.useState<ICustomer>();

    React.useEffect(() => {
        if (!currentCustomer) return;
        if (!!customer) return;
        setCustomer(currentCustomer);
    }, [currentCustomer]);
    
    if (loadingCustomer) return <Spinner />;
    if (!customer || !currentCustomer) return null;

    return (
        <Page title="Nutzerverwaltung" action={<UpdateUserForm customer={customer} />}>
            {
                loadingCustomer 
                ? <Spinner />
                : <UserList onCustomerChange={c => setCustomer(c)} />
            }
        </Page>
    )
}   