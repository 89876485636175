import React from "react";
import * as Papa from "papaparse";
import useNotifications from "../../../hooks/useModal";
import { ModalType } from "../../../state/modal/modal.state";
import { Menu, MenuButtonProps, MenuItem, MenuList, MenuPopover, MenuTrigger, Spinner, SplitButton, Text } from "@fluentui/react-components";
import { ArrowClockwiseRegular, ArrowUploadRegular, CheckmarkRegular, DeleteRegular } from "@fluentui/react-icons";
import { formatNumber } from "../../../util/formatter";
import Flex from "../container/Flex";
import { generateClassName } from "../../../hooks/useAttributes";

interface ICsvSelectProps {
    processResult: (data: Papa.ParseStepResult<any>[]) => Promise<void> | void,
    onClear?: () => void,
    hasData?: boolean,
    hideLoadingState?: boolean,
    existingRows?: number,
    textOnEmpty?: string,
    textOnSelected?: string,
    bold?: boolean
}

export default function CsvSelect({processResult, hideLoadingState, hasData, onClear, bold = true, existingRows, textOnEmpty, textOnSelected }: ICsvSelectProps) {

    const [rows, setRows] = React.useState<Papa.ParseStepResult<any>[]>([]);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [file, setFile] = React.useState<any>();

    const {
        showNotification
    } = useNotifications();

    const fileSelectRef = React.useRef<HTMLInputElement>(null);

    const preventDefaults = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
    }

    const handleSelectedFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
        preventDefaults(e);
        if (!e || !e.target || !e.target.files || !e.target.files.length) return;
        handleFiles(e.target.files);
    }

    const uploadFile = (file: Papa.LocalFile) => {
        setLoading(true);
        setFile(file);

        Papa.parse(file, {
            worker: true,
            encoding: "ISO-8859-1",
            skipEmptyLines: true,
            header: true,
            download: true,
            step: (row) => rows.push(row),
            complete: () => {
                processResult(rows);
                setLoading(false)
            }
        });
    }

    const handleFiles = (files: FileList) => {
        if (files.length > 1) {
            showNotification({text: "Bitte nur eine Datei auswählen.", type: ModalType.Error});
            return;
        }

        uploadFile(files[0]);
    }

    const removeFile = async () => {
        setFile(undefined);
        setRows([]);
        if (!!onClear) onClear();
        
        if (fileSelectRef && fileSelectRef.current) fileSelectRef.current.value = "";
    }

    const importedRows = (rows && file) ? rows.length : existingRows;

    const canDelete = !loading && (file || (!!existingRows && onClear));

    return (
        <Flex fullWidth>
            <div className="d-flex w-100 flex-row align-items-start justify-content-between">
                <Flex>
                    <span className={generateClassName("d-flex flex-row align-items-center", { value: bold, onTrue: "fw-bold"})}>
                        {
                            file 
                            ? (textOnSelected || file.name)
                            : (textOnEmpty || "Keine Datei ausgewählt")
                        }
                    </span>
                    <Flex row>
                        {
                            loading 
                            ? (hideLoadingState ? null : <Spinner />)
                            : importedRows > 0 && <CheckmarkRegular />
                        }
                        {
                            importedRows > 0 && <span>{formatNumber(importedRows)} Zeilen importiert </span>
                        }
                    </Flex>
                </Flex>
                <Menu>
                    <MenuTrigger>
                        {(triggerProps: MenuButtonProps) => (
                            <SplitButton
                                primaryActionButton={{
                                    onClick: async () => fileSelectRef && fileSelectRef.current && fileSelectRef.current.click(),
                                    disabled: loading,
                                    icon: loading ? <Spinner size="extra-tiny" /> : (canDelete ? <ArrowClockwiseRegular /> : <ArrowUploadRegular />),
                                }}
                                menuButton={canDelete ? triggerProps : { disabled: true, hidden: true }}
                            >
                                <Text wrap={false}>
                                    {
                                        loading
                                        ? "Wird verarbeitet..."
                                        : (hasData ? "Andere Datei wählen" : "Datei auswählen")
                                    }
                                </Text>
                            </SplitButton>
                        )}
                    </MenuTrigger>
                    <MenuPopover>
                        <MenuList>
                            <MenuItem 
                                icon={<DeleteRegular />}
                                onClick={file ? removeFile : onClear}
                                disabled={loading}
                            >
                                {
                                    file ? "Datei entfernen" : "Daten löschen"
                                }
                            </MenuItem>
                        </MenuList>
                    </MenuPopover>
                </Menu>
            </div>
            <input ref={fileSelectRef} accept=".csv,.txt" style={{visibility: "hidden", opacity: 0, width: 0, height: 0, overflow: "hidden !important"}} type="file" onChange={(e) => handleSelectedFiles(e)} />
        </Flex>
    )
}