import { Tab, TabList, Text } from "@fluentui/react-components";
import { Dismiss12Regular } from "@fluentui/react-icons";
import { useFormikContext } from "formik";
import React from "react";
import { useMappingGroups } from "../../../state/mappingGroups/useMappingGroups";
import { ChartType, IChartDocument } from "../../../types/charts.schema";

export enum UpdateChartFormTab {
    General = "general",
    Data = "data"
}

interface IUpdateChartFormTabsProps {
    selectedTab: UpdateChartFormTab,
    onSelect: (tab: UpdateChartFormTab) => void,
}

export default function UpdateChartFormTabs(props: IUpdateChartFormTabsProps) {
    
    const {
        onSelect,
        selectedTab
    } = props;

    const tabs: Array<{ 
        value: UpdateChartFormTab, 
        label: string
    }> = [
        {
            value: UpdateChartFormTab.General,
            label: "Allgemein"
        },
        {
            value: UpdateChartFormTab.Data,
            label: "Kontengruppen"
        }
    ];

    return (
        <TabList 
            selectedValue={selectedTab} 
            onTabSelect={(_, data) => onSelect(data.value as UpdateChartFormTab)}
            size="small"
            appearance="subtle"
        >
            {
                tabs.map(g => <Tab key={g.value} value={g.value}>{g.label}</Tab>)
            }
        </TabList>
    )
}
