import React from "react";
import Flex from "../container/Flex";
import { WarningRegular } from "@fluentui/react-icons";
import useResponsive, { Breakpoint, Height } from "../../../hooks/useResponsive";
import useNotifications from "../../../hooks/useModal";
import { ModalType } from "../../../state/modal/modal.state";
import { Tooltip } from "@fluentui/react-components";

export default function WindowSizeIndicator() {

    const {
        showNotification
    } = useNotifications();

    const {
        isNarrower,
        isLower    
    } = useResponsive(Breakpoint.Tiny, Height.Small);

    React.useEffect(() => {
        if (isNarrower || isLower) {
            showNotification({
                title: "Fenstergröße",
                text: "Die Anwendung ist für diese Bildschirmgröße nicht optimiert. Bitte vergrößern Sie das Fenster.",
                type: ModalType.Warning
            });
        }
    }, [isNarrower, isLower]);

    if (!isNarrower && !isLower) return null;

    return (
        <Flex>
            <Tooltip relationship="description" content="Für verbesserte Funktion Fenster vergrößern.">
                <WarningRegular fontSize={24} color="#F20000" />
            </Tooltip>
        </Flex>
    )
}