import React from "react";
import { useMappingGroups } from "../../../state/mappingGroups/useMappingGroups";
import { Button, Menu, MenuItem, MenuList, MenuPopover, MenuTrigger, Spinner, Text } from "@fluentui/react-components";
import useCurrentGroup from "../../../hooks/useCurrentGroup";
import { CalculatorRegular, CheckmarkRegular } from "@fluentui/react-icons";
import IElementProps from "../../../types/element.types";

export default function SelectNumberGroup({ className } : IElementProps) {

    const {
        loadingMappingGroups,
        mappingGroups
    } = useMappingGroups();

    
    const {
        currentGroupId,
        updateCurrentGroup
    } = useCurrentGroup();

    const group = mappingGroups && !!mappingGroups.length && mappingGroups.find(g => g._id === currentGroupId);

    if (loadingMappingGroups) return <Spinner />;

    if (!mappingGroups || !mappingGroups.length) return <Text color="danger">Keine Zuordnungen vorhanden.</Text>;

    return (
        <Menu hasIcons>
            <MenuTrigger>
                <Button
                    className={className}
                    icon={<CalculatorRegular />}
                    iconPosition="after"
                >
                    {
                        group?.name ?? "Zuordnung auswählen..."
                    }
                </Button>
            </MenuTrigger>
            <MenuPopover>
                <MenuList>
                    {
                        mappingGroups.map(g => (
                            <MenuItem
                                icon={g._id === currentGroupId ? <CheckmarkRegular /> : undefined}
                                key={g._id}
                                onClick={() => updateCurrentGroup(g._id)}
                            >
                                {g.name}
                            </MenuItem>
                        ))
                    }
                </MenuList>
            </MenuPopover>
        </Menu>
    )
}