import React from "react";
import { Badge } from "@fluentui/react-components";
import { useUser } from "../../../state/user/useUser";
import { IUser } from "../../../types/user.schema";

export default function UserAdminBanner({user}: { user: IUser }) {
    const {
        user: currentUser
    } = useUser();
    
    
    if (!user || !currentUser) return null;
    if (!user.isCustomerAdmin && !user.isGlobalAdmin) return null;
    if (user.isGlobalAdmin && !currentUser.isGlobalAdmin) return null;

    return (
        <Badge color={user.isGlobalAdmin ? "danger" : "important"}>
            {
                user.isGlobalAdmin
                ? "Globaler Administrator"
                : "Kunden-Administrator"
            }
        </Badge>
    )
}