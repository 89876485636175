import React from "react";
import Page from "../components/container/Page";
import { useNavigate } from "react-router-dom";
import { Link, Button, Text, Card } from "@fluentui/react-components";
import { AppRoutes, IAppRoute } from "../components/menu/Sidebar";
import { ArrowDownloadRegular, ArrowLeftRegular, ArrowStepBackRegular } from "@fluentui/react-icons";
import Flex from "../components/container/Flex";
import { useUser } from "../../state/user/useUser";
import CustomButton from "../components/button/CustomButton";

export default function Privacy() {

    const navigate = useNavigate();

    const {
        user
    } = useUser();

    const createTitle = (s: string, link?: IAppRoute, linkText?: string) => (
        <Flex row fullWidth justify="between">
            <Text size={500} weight="bold">{s}</Text>
        </Flex>

    )
    
    return (
        <Page 
            title="Datenschutz" 
            className={!user && "p-5"}
            action={
                !user && (
                    <Button 
                        onClick={() => navigate(AppRoutes.Dashboard.path)}
                        icon={<ArrowLeftRegular />}
                    >
                        Zurück
                    </Button>
                )
            }
        >
            <Card className="w-100">
                {createTitle("Einleitung")}
                <Text>
                    SBT ist auf Ihre Privatsphäre ausgerichtet. Wir verwenden keine Ihrer Daten für Zwecke außerhalb der Dienstleistung.
                    Ihre Importe werden ausschließlich lokal verarbeitet. <br/><br/>
                    <strong>SBT wird bereitgestellt von:</strong><br/><br/>
                    Registernummer:  Geschäftsführer: Claas Beckmann. Kontakt. Tel.: +49 421 69516445. E-Mail: winkekatze@steuerkoepfe.de
                    VIP Steuerköpfe GmbH<br/>
                    Hastedter Osterdeich 222<br/>
                    28207 Bremen<br/>
                    Registernummer: HRB 37026 HB<br/>
                    Geschäftsführer: Claas Beckmann<br/>
                    Mail: info@steuerkoepfe.de<br/><br/>
                    <strong>SBT ist entwickelt von:</strong><br/><br/>
                    jumoca GmbH & Co. KG<br/>
                    Renchtalblick 38<br/>
                    77767 Appenweier<br/>
                    info@jumoca.de<br/>
                    <br/>
                    Datenschutzbeauftragter: Julian Caspary
                </Text>
            </Card>
            <Card className="w-100">
                {createTitle("Gespeicherte Daten")}
                <Text>
                    Damit Sie unsere App auf verschiedenen Geräten nutzen können, verwenden wir ein verteiltes System.
                    Dieses stellt Ihnen sowohl die Anmeldemöglichkeit zur Verfügung als auch die Speicherung von Vorlagen, damit Sie Ihre Diagramme und Zuordnungen auf jedem Gerät verwenden können.
                    Ihre Importe müssen Sie aber dennoch auf jedem Gerät erneut tätigen, da diese lokal gespeichert sind.
                    Wir speichern lediglich die kleinstmögliche Menge an Strukturen, um Ihnen die Dienstleistung zur Verfügung zu stellen. 
                </Text>
            </Card>
            <Card className="w-100">
                {createTitle("Verwendung von Daten")}
                <Text>
                    Wir verwenden Ihre Daten nicht, auch nicht für Marketingzwecke. Ihre E-Mail wird lediglich für Ihren Log-In gespeichert.
                </Text>
            </Card>
            <Card className="w-100">
                {createTitle("DIenstleister")}
                <Text>
                    Um unser Verteiltes System bereitzustellen, verwenden wir folgende externe Dienstleister:
                    <br/><br/>
                    - Firebase, Inc. 1600 Amphitheatre Pkwy, GB40, Mountain View, CA, United States // https://firebase.google.com/support/privacy?hl=de<br/>
                    - Heroku, Inc. 415 Mission Street, Suite 300, San Francisco, CA, United States  // https://www.heroku.com/policy/security<br/>
                    - MongoDB, Inc. Paramount Plaza, 1633 Broadway, 38th Floor, NY, United States   // https://www.mongodb.com/legal/privacy/privacy-policy<br/>
                    <br/>
                    Diese Dienstleister stellen Datenbank und Hosting für Backend und Frontend zur Verfügung. 
                </Text>
            </Card>
        </Page>
    )
}