import { Menu, MenuItem, MenuList, MenuPopover, MenuTrigger } from "@fluentui/react-components";
import { CheckmarkRegular, ColorRegular } from "@fluentui/react-icons";
import React from "react";
import useColorPalette from "../../../hooks/useColorPalette";
import { ColorMode } from "../../../state/color/color.state";
import useColorMode from "../../../hooks/useColorMode";

export default function ColorModeSwitch() {

    const {
        colorMode,
        updateCurrentColorMode
    } = useColorMode();

    const getMenuItem = (mode: ColorMode, label: string) => (
        <MenuItem
            icon={
                mode === colorMode
                ? <CheckmarkRegular />
                : undefined
            }
            onClick={() => updateCurrentColorMode(mode)}
        >
            {label}
        </MenuItem>
    )

    return (
        <Menu>
            <MenuTrigger>
                <ColorRegular />
            </MenuTrigger>
            <MenuPopover>
                <MenuList>
                    {getMenuItem(ColorMode.Primary, "Primär")}
                    {getMenuItem(ColorMode.Accent, "Sekundär")}
                    {getMenuItem(ColorMode.Complementary, "Komplementäre")}
                </MenuList>
            </MenuPopover>
        </Menu>

    )
}