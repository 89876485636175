import React from "react";
import { ICustomer } from "../../../types/customer.schema";
import useApi from "../../../api/useApi";
import { useCustomer } from "../../../state/customers/useCustomer";
import CustomButton from "../button/CustomButton";
import { useCustomers } from "../../../state/customers/useCustomers";
import { DeleteRegular } from "@fluentui/react-icons";

export interface IDeleteCustomerButtonProps {
    customer: ICustomer
}

export default function DeleteCustomerButton(props: IDeleteCustomerButtonProps) {
    
    const {
        customer
    } = props;

    const {
        customer: currentCustomer
    } = useCustomer();

    const {
        customersDelete
    } = useApi();

    const {
        reloadCustomers
    } = useCustomers();

    const clickHandler = async () => {
        const res = await customersDelete(customer._id);
        if (!res.success) return;
        await reloadCustomers();
    }

    if (customer.isMaster) return null;
    if (!currentCustomer) return null;
    
    return (
        <CustomButton
            secondStepQuestion="Sicher? Alle Daten des Kunden werden gelöscht!"
            disabled={customer._id === currentCustomer?._id}
            text="Kunden löschen"
            onClick={clickHandler}
            color="danger"
            icon={<DeleteRegular />}
        />
    )
}