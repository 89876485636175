import * as React from "react";
import * as ReactDOM from "react-dom";
import App from "./App";
import { FluentProvider, Theme,  } from "@fluentui/react-components";
import SWRProvider from "../state/swrProvider";
import ErrorBoundary from "./ErrorBoundary";
import { Provider } from "react-redux";
import store from "../state/store";
import "./index.css";
import ModalBanner from "./components/modal/ModalBanner";
import { HashRouter, RouterProvider } from "react-router-dom";
import ThemeProvider from "./ThemeProvider";

/* global document, Office, module, require */

const title = "Contoso Task Pane Add-in";

const render = (Component: any) => {
    ReactDOM.render(
        <Provider store={store}>
            <SWRProvider>
                <ThemeProvider>
                    <ErrorBoundary>
                        <HashRouter>
                            <Component title={title} />
                        </HashRouter>
                        <ModalBanner />
                    </ErrorBoundary>
                </ThemeProvider>
            </SWRProvider>
        </Provider>,
        document.getElementById("container")
    );
};


/* Render application after Office initializes */
Office.onReady(() => {
    render(App);
});

if ((module as any).hot) {
  (module as any).hot.accept("./App", () => {
    const NextApp = require("./App").default;
    render(NextApp);
  });
}
