import React from "react";
import { IMappingGroup, IMappingGroupDocument, IMappingGroupImport } from "../../../../types/mappingGroup.schema";
import ModalForm from "../../modal/ModalForm";
import useApi from "../../../../api/useApi";
import Flex from "../../container/Flex";
import FormikField from "../../formik/FormikField";
import { Button, Card, MenuItem, Radio, RadioGroup, Text, Tooltip } from "@fluentui/react-components";
import { AddRegular, CopyRegular, Edit16Regular, EditRegular, QuestionCircleRegular, QuestionRegular } from "@fluentui/react-icons";
import { Field } from "formik";
import { useMappingGroups } from "../../../../state/mappingGroups/useMappingGroups";
import { useUser } from "../../../../state/user/useUser";
import CheckBox from "../../formik/CheckBox";
import IElementProps from "../../../../types/element.types";
import CsvSelect from "../../csv/CsvSelect";
import { ParseStepResult } from "papaparse";
import { BalanceIndicator, CalculationResultType, IMappingDocument } from "../../../../types/mapping.schema";
import { ExternalDataSourceColumnMap } from "../../../../hooks/useNumberImport";

export interface IUpdateMappingGroupFormProps extends IElementProps {
    mappingGroup?: IMappingGroup,
    isDuplication?: boolean,
}

export interface IExternalMappingImport {
    accountFrom: string,
    accountTo: string,
    groupName: string
}

export const MappingImportColumns: ExternalDataSourceColumnMap<IExternalMappingImport> = {
    datev: {
        accountFrom: "Konto von",
        accountTo: "Konto bis",
        groupName: "Beschriftung"
    }
}

export default function UpdateMappingGroupForm({mappingGroup, isDuplication, className }: IUpdateMappingGroupFormProps) {
    
    const {
        reloadMappingGroups
    } = useMappingGroups();

    const {
        mappingGroupsUpdate,
        mappingGroupsDuplicate,
        mappingGroupsCreate
    } = useApi();

    const {
        user
    } = useUser();

    const text = (
        mappingGroup
        ? isDuplication
            ? "Kontenrahmen duplizieren"
            : "Kontenrahmen bearbeiten"
        : "Neuer Kontenrahmen"
    );

    const icon = (
        mappingGroup
        ? isDuplication
            ? <CopyRegular />
            : <EditRegular />
        : <AddRegular />
    ); 

    if (mappingGroup && mappingGroup.isGlobalPreset && !user?.isGlobalAdmin && !isDuplication) return null;
    
    return (
        <ModalForm
            appearance={mappingGroup ? "transparent" : undefined}
            text={text}
            className={className}
            title={text}
            icon={icon}
            initialValues= {{
                isGlobalPreset: mappingGroup?.isGlobalPreset ?? false,
                name: mappingGroup?.name ?? "",
                mappings: []
            } as IMappingGroupImport}
            enableReinitialize
            onSubmit={async (values) => {
                const res = (
                    mappingGroup 
                    ? (
                        isDuplication
                        ? await mappingGroupsDuplicate(mappingGroup._id, values)
                        : await mappingGroupsUpdate(mappingGroup._id, values)
                    )
                    : await mappingGroupsCreate(values)
                ).success;

                if (res) await reloadMappingGroups();
                return res;
            }}
        >
            {
                formik => (
                    <Flex fullWidth>
                        <FormikField name="name" label="Name des Kontenrahmens" />
                        {
                            (!!user && user.isGlobalAdmin) && (
                                <Flex fullWidth gap={0}>
                                    <Text>Vorlagenstatus</Text>
                                    <CheckBox name="isGlobalPreset" label="Dieser Kontenrahmen kann von jedem Kunden genutzt werden" />
                                </Flex>
                            )
                        }
                        <Card appearance="outline" className="w-100">
                            <Flex gap={0}>
                                <Text>Massendaten-Import</Text>
                                <Flex fullWidth row>
                                    <Text size={200}>Übernimmt aus einer Export-Datei alle Konten Ihres Kontenrahmens.</Text>
                                    <Tooltip relationship="description" content="Für den Export einer CSV-Datei können Sie eine Tabelle in Excel über Datei > Exportieren > Dateityp ändern > CSV passend ausgeben.">
                                        <QuestionCircleRegular />    
                                    </Tooltip>                            
                                </Flex>
                            </Flex>
                            <CsvSelect
                                bold={false}
                                processResult={(parsed: ParseStepResult<any>[]) => {
                                    const data = parsed.map(r => r.data);
                                    const result: Array<IMappingDocument> = [];

                                    let lastName = "";
                                    let currentItem: IMappingDocument | undefined = undefined;

                                    for (const d of data) {
                                        try {

                                            if (!d) continue;
    
                                            const accountFrom = parseInt(d[MappingImportColumns.datev.accountFrom]);
                                            const accountTo = parseInt(d[MappingImportColumns.datev.accountTo]);
    
                                            const name = d[MappingImportColumns.datev.groupName];
                                            if (!name && !lastName) continue;
                                            
                                            if (name && name !== lastName) {
                                                lastName = name;
                                                if (currentItem) result.push(currentItem);
                                                currentItem = {
                                                    accounts: [],
                                                    resultIsDeltaToLastYear: false,
                                                    resultType: CalculationResultType.Currency,
                                                    indicator: BalanceIndicator.Credit,
                                                    isCalculated: false,
                                                    calculationSteps: [],
                                                    name,
                                                    shortName: ""
                                                }
                                            }
    
                                            if (!currentItem) continue;
    
                                            
                                            currentItem.accounts.push({
                                                accountFrom,
                                                accountTo: accountTo === accountFrom ? undefined : accountTo
                                            });
                                        }
                                        catch (err) { console.log(err); }
                                    }

                                    if (!!currentItem) result.push(currentItem);

                                    const realResult: Array<IMappingDocument> = [];

                                    for (const r of result) {
                                        if (!r.accounts || !r.accounts.length) continue;

                                        let startOfRange = -1;
                                        let endOfRange = -1;

                                        for (const acc of r.accounts) {
                                            try {
                                                if (startOfRange === -1) {
                                                    startOfRange = acc.accountFrom;
                                                    endOfRange = acc.accountTo ?? acc.accountFrom;
                                                    continue;
                                                }
                                                
                                                if (acc.accountFrom === endOfRange + 1) {
                                                    endOfRange = acc.accountTo ?? acc.accountFrom;
                                                    continue;
                                                }
    
                                                realResult.push({
                                                    accounts: [{ accountFrom: startOfRange, accountTo: endOfRange }],
                                                    indicator: BalanceIndicator.Credit,
                                                    resultIsDeltaToLastYear: false,
                                                    isCalculated: false,
                                                    resultType: CalculationResultType.Currency,
                                                    calculationSteps: [],
                                                    name: r.name,
                                                    shortName: r.shortName
                                                });
    
                                                startOfRange = acc.accountFrom;
                                                endOfRange = acc.accountTo ?? acc.accountFrom;
                                            }
                                            catch (err) { console.log(err); }
                                        }

                                        realResult.push({
                                            accounts: [{ accountFrom: startOfRange, accountTo: endOfRange }],
                                            indicator: BalanceIndicator.Credit,
                                            resultIsDeltaToLastYear: false,
                                            resultType: CalculationResultType.Currency,
                                            isCalculated: false,
                                            calculationSteps: [],
                                            name: r.name,
                                            shortName: r.shortName
                                        });
                                    }

                                    formik.setFieldValue("mappings", realResult);
                                }}
                                onClear={() => formik.setFieldValue("mappings", [])}
                            />
                        </Card>
                    </Flex>
                )
            }
        </ModalForm>
    )
}