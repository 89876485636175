import React from "react";
import { Year } from "../../../types/mapping.schema";
import { ToggleButton } from "@fluentui/react-components";
import { IYearsToggleProps } from "./YearsToggle";
import useYearUtil from "../../../hooks/useYearUtil";

export interface IYearToggleProps extends IYearsToggleProps {
    year: Year
}

export default function YearToggle({saveValues, maxValues, values, year}: IYearToggleProps) {

    const {
        getLabelForYear
    } = useYearUtil();

    const selected = values.includes(year);

    const save = (year: Year) => {
        if (!maxValues || values.length < maxValues) {
            saveValues([...values, year]);
            return;
        }
        
        const newValues = [ year, ...values].slice(0, maxValues);
        saveValues(newValues);
    } 

    const remove = (year: Year) => saveValues(values.filter(y => y !== year));

    return (
        <ToggleButton
            checked={selected}
            onClick={() => selected ? remove(year) : save(year)}
            appearance={selected ? "primary" : undefined}
        >
            {getLabelForYear(year)}
        </ToggleButton>
    )
}