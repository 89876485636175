export enum Modes {
    DEV = 'development',
    PROD = 'production'

}

export const Mode = process.env.NODE_ENV || Modes.PROD;

const getConfig = (dev: string, prod: string) => Mode === Modes.DEV ? dev : prod;

export const Config = {
    ApiUrl: getConfig('https://localhost:8080/v1', 'https://datev-pp-addin-sb-api-1b19f5df5ea3.herokuapp.com/v1')
}