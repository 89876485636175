import React from "react";
import useSWR from "swr";
import { Routes } from "../../api/apiRoutes";
import usePersistance, { StorageKey } from "../../hooks/usePersistance";
import { IUser } from "../../types/user.schema";

export const useUser = () => {
    const { data, isLoading, mutate } = useSWR<IUser>(Routes.session.current.route);

    return {
        user: data,
        loadingUser: isLoading,
        reloadUser: mutate
    }
} 