import { Card, Text } from "@fluentui/react-components";
import React from "react";
import { ISlidePadding } from "../../../types/slideDeck.schema";
import useSlideUtil from "../../../hooks/useSlideUtil";
import { generateStyleWithBase } from "../../../hooks/useAttributes";
import useTheme from "../../../hooks/useTheme";
import Flex from "../container/Flex";
import IElementProps from "../../../types/element.types";

export default function PaddedSlidePreview(props: {
    padding: ISlidePadding,
    inactive?: boolean,
    children: any
}) {

    const {
        padding,
        inactive,
        children
    } = props;

    const {
        htmlSlideSize,
        slideCss
    } = useSlideUtil();

    const {
        bottom,
        left,
        right,
        top
    } = padding;

    const { 
        primary,
        secondary
    } = useTheme();

    const previewStyle = generateStyleWithBase(slideCss, {
        name: "backgroundColor",
        value: inactive ? secondary : primary
    }, {
        name: "padding",
        value: 0
    })

    const getPadding = (p: number, side: number) => (p / 100) * side;

    return (
        <Card style={previewStyle}>
            <Flex 
                fullHeight
                style={{
                    backgroundColor: "white", 
                    marginTop: getPadding(top, htmlSlideSize.y), 
                    marginBottom: getPadding(bottom, htmlSlideSize.y), 
                    marginLeft: getPadding(left, htmlSlideSize.x), 
                    marginRight: getPadding(right, htmlSlideSize.x),
                    width: (htmlSlideSize.x - getPadding(left + right, htmlSlideSize.x)) + "px",
                    borderRadius: "5px", 
                    border: "1px dashed #00000033"
                }}
                className="position-relative"
                align="center"
                justify="center"
            >
                {children}
            </Flex>
        </Card>
    )
}