import React from "react";
import { Year } from "../../../types/mapping.schema";
import Flex from "../container/Flex";
import YearToggle from "./YearToggle";
import { Text } from "@fluentui/react-components";

export interface IYearsToggleProps {
    values: Array<Year>,
    label?: string,
    description?: string,
    maxValues?: number,
    saveValues: (years: Array<Year>) => void
}

export default function YearsToggle(props: IYearsToggleProps) {

    const {
        values, 
        maxValues, 
        saveValues,
        description,
        label
    } = props;

    return (
        <Flex fullWidth>
            {
                label && (
                    <Flex gap={1} fullWidth>
                        <Text>{label}</Text>
                        { description && <Text size={200}>{description}</Text>}
                    </Flex>
                )
            }
            <Flex fullWidth row wrap>
                {
                    Object.values(Year).map(year => (
                        <YearToggle 
                            key={year}
                            year={year}
                            values={values}
                            maxValues={maxValues}
                            saveValues={saveValues}
                        />
                    ))
                }
            </Flex>
        </Flex>
    )
}