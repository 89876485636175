import React from "react";
import { useAppDispatch, useAppSelector } from "../state/reduxHooks";
import usePersistance, { StorageKey } from "./usePersistance";
import { INumbersImportState, replaceImportedNumbers, setCurrentYear } from "../state/datev/numbersImport.state";
import { setGroupId } from "../state/datev/currentGroup.state";
import { useMappingGroups } from "../state/mappingGroups/useMappingGroups";

export default function useCurrentGroup() {

    const dispatch = useAppDispatch();

    const {
        mappingGroups,
        loadingMappingGroups
    } = useMappingGroups();

    const {
        groupId
    } = useAppSelector(state => state.currentGroup);

    const {
        updatePersistedValue,
        value
    } = usePersistance<string>(StorageKey.SelectedMappingGroup);

    const updateGroup = (id: string) => {
        dispatch(setGroupId(id));
        updatePersistedValue(id);
    }

    React.useEffect(() => {
        if (!value) return;
        
        if (groupId) {
            updateGroup(groupId);
            return;
        }

        if (value === groupId) return;

        updateGroup(value);
    }, [])

    const getCurrentGroup = () => mappingGroups?.find(g => g._id === groupId);

    return {
        currentGroupId: (groupId ?? value),
        canSelectGroup: loadingMappingGroups || (mappingGroups && !!mappingGroups.length),
        getCurrentGroup,
        updateCurrentGroup: updateGroup,
    }
}