import React from "react";
import { Text } from "@fluentui/react-components";

export interface IPageHeaderProps {
    title: string;
}

export default function PageHeader({ title }: IPageHeaderProps) {
    return (
        <Text size={600} weight="bold">{title}</Text>
    )
}