import React from "react";
import Page from "../components/container/Page";
import SlideDeckList from "../components/slideDecks/SlideDeckList";
import CreateSlideDeckForm from "../components/slideDecks/CreateSlideDeckForm";

export default function Presentations() {
    return (
        <Page title="Präsentationen" action={<CreateSlideDeckForm />}>
            <SlideDeckList />
        </Page>
    )
}