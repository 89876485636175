import { ErrorMessage, Field, FieldAttributes, useFormikContext } from "formik";
import React from "react";
import "./CheckBox.css";
import Flex from "../container/Flex";
import IElementProps from "../../../types/element.types";
import { generateClassName } from "../../../hooks/useAttributes";
import { Checkbox, CheckboxOnChangeData } from "@fluentui/react-components";
import useFormikValue from "../../../hooks/useFormikValue";

interface ICheckBoxProps extends IElementProps {
    label?: string,
    labelClass?: string,
    name: string,
    readOnly?: boolean,
    disabled?: boolean,
    onChange?: (data: CheckboxOnChangeData) => void,
    bold?: boolean,
    type?: string
}

export default function CheckBox({label, readOnly, name, disabled, onChange}: ICheckBoxProps ) {

    const {
        setFieldValue
    } = useFormikContext();

    const {
        value
    } = useFormikValue<any, boolean>(name, false);

    const changeHandler = (e: any, data: CheckboxOnChangeData) => {
        setFieldValue(name, data.checked);
        if (onChange) onChange(data);
    }


    return (
        <Checkbox
            checked={value}
            label={label}
            disabled={readOnly || disabled}
            onChange={changeHandler} 
        />
    )
}