import React from "react";
import useSWR, { SWRConfig, SWRConfiguration, SWRResponse, unstable_serialize } from "swr";
import useApi from "../api/useApi";

export default function SWRProvider({children}: {children: any}) {

    const {
        swrFetch
    } = useApi();

    return (
        <SWRConfig
            value={{
                fetcher: swrFetch,
                revalidateOnFocus: false,
                revalidateIfStale: false,
                revalidateOnReconnect: false
            }}
        >
            {
                children
            }
        </SWRConfig> 
    )
}