import React from "react";
import { ChartType, IChart, IChartDocument } from "../../../types/charts.schema";
import { Chart } from 'react-chartjs-2';
import { Chart as ChartClass, ChartOptions, ChartTypeRegistry } from "chart.js";
import { generateStyle } from "../../../hooks/useAttributes";
import useChartUtil from "../../../hooks/useChartUtil";
import { CustomChartData } from "../../../hooks/useDynamicChartData";

export interface ICustomChartProps {
    chart: IChartDocument,
    chartData: CustomChartData,
    options: ChartOptions,
    small?: boolean,
}

export default function CustomChart(props: ICustomChartProps) {

    const {
        chartData,
        options,
        chart,
        small
    } = props;
    
    const {
        type
    } = chart;

    const {
        convertTypeToChartType
    } = useChartUtil();
    

    if (!chart || !chartData) return null;
    
    const style = generateStyle({
        name: "height",
        value: "auto"
    }, {
        name: "width",
        value: "100%"
    }, {
        name: "minHeight",
        value: "150px",
        applyCondition: !small
    }, {
        name: "minWidth",
        value: "150px",
        applyCondition: !small
    }, {
        name: "minHeight",
        value: "50px",
        applyCondition: !!small
    }, {
        name: "minWidth",
        value: "50px",
        applyCondition: !!small
    }, {
        name: "maxWidth",
        value: "120px",
        applyCondition: !!small
    }, {
        name: "maxHeight",
        value: "120px",
        applyCondition: !!small
    });

    return (
        <Chart
            style={style}
            type={convertTypeToChartType(type)}
            data={chartData}
            options={options}
        />
    )
}