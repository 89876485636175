import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum ColorMode {
    Primary = "primary",
    Accent = "accent",
    Complementary = "complementary"
}

export const ColorModeSlice = createSlice({
    name: "color",
    initialState: {
        colorMode: ColorMode.Primary
    },
    reducers: {
        setColorMode: (state, action: PayloadAction<ColorMode>) => {
            state.colorMode = action.payload;
        }
    }
});

export const { setColorMode } = ColorModeSlice.actions;

export default ColorModeSlice.reducer;