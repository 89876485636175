import React from "react";
import { generateClassName, generateStyle } from "../../../hooks/useAttributes";
import "./Logo.css";
import Flex from "../container/Flex";
import { useCustomer } from "../../../state/customers/useCustomer";

export interface ILogoProps {
    size?: number,
    padding?: 1 | 2 | 3 | 4 | 5,
    centered?: boolean,
    className?: string
}

export default function Logo(props: ILogoProps) {

    const {
        customer
    } = useCustomer();

    const {
        className,
        size = 64,
        padding,
        centered = false
    } = props;

    const style = generateStyle({
        name: "width",
        value: size
    });

    const containerClass = generateClassName(className, "logo-img-container", {
        value: padding,
        base: "pt-"
    }, {
        value: padding,
        base: "pb-"
    });

    const logo = (
        <div className={containerClass} style={style}>
            <svg style={{width: "100%", height: "auto"}} viewBox="0 0 230 61.224503669505296">
                <defs id="SvgjsDefs1631"></defs>
                <g id="SvgjsG1632" transform="matrix(0.6122448979591837,0,0,0.6122448979591837,-0.6122448979591837,0.3877551020408163)" fill={customer?.settings?.theme?.secondary ?? "#011140"}>
                    <g xmlns="http://www.w3.org/2000/svg">
                        <path d="M99,99H1V1h98V99z"></path>
                    </g>
                </g>
                <g id="SvgjsG1633" transform="matrix(2.1865889699193874,0,0,2.1865889699193874,73.70262351639691,-26.239063468445167)" fill={customer?.settings?.theme?.primary ?? "#0455bf"}>
                    <path d="M15.52 22.8 c2.76 0 5 2.24 5 5 l0 7.24 c0 2.76 -2.24 4.96 -5 4.96 l-12.64 0 l0 -6.4 l11.2 0 l0 -4.36 l-6.24 0 c-2.76 0 -4.96 -2.24 -4.96 -5 l0 -7.24 c0 -2.76 2.24 -5 4.96 -5 l12.68 0 l0 6.44 l-11.24 0 l0 4.36 l6.24 0 z M41.08 12 c2.76 0 5 2.24 5 5 l0 5.28 c0 1.84 -1.32 3.4 -3.16 3.68 l-0.24 0.04 l0.24 0.04 c1.84 0.32 3.16 1.88 3.16 3.68 l0 5.32 c0 2.72 -2.24 4.96 -5 4.96 l-14.8 0 l0 -28 l14.8 0 z M39.68000000000001 32.28 l0 -1.72 c0 -0.76 -0.6 -1.32 -1.32 -1.32 l-5.68 0 l0 4.36 l5.68 0 c0.72 0 1.32 -0.6 1.32 -1.32 z M39.68000000000001 21.48 l0 -1.72 c0 -0.72 -0.6 -1.32 -1.32 -1.32 l-5.68 0 l0 4.36 l5.68 0 c0.72 0 1.32 -0.6 1.32 -1.32 z M51.64000000000001 12 l19.84 0 l0 6.44 l-6.72 0 l0 21.56 l-6.4 0 l0 -21.56 l-6.72 0 l0 -6.44 z"></path>
                </g>
            </svg>
        </div>
    )

    if (!centered) return logo;

    return (
        <Flex fullWidth justify="center" align="center">
            {logo}
        </Flex>
    )
}