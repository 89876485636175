import useSWR from "swr";
import { RouteParam, Routes } from "../../api/apiRoutes";
import { IHydratedMappingGroupWithMappings, IMappingGroup, IMappingGroupWithMappings } from "../../types/mappingGroup.schema";

export const useMappingGroup = (id: string) => {

    const { data, isLoading, mutate } = useSWR<IHydratedMappingGroupWithMappings>(Routes.mappingGroups.byId.getValue([ { param: RouteParam.MappingGroup, value: id }]));

    return {
        mappingGroup: data,
        loadingMappingGroup: isLoading,
        reloadMappingGroup: mutate
    }
}