import React from "react";
import Flex from "../container/Flex";
import { IUser } from "../../../types/user.schema";
import { useUser } from "../../../state/user/useUser";
import { Avatar, Card, CardHeader, Menu, MenuDivider, MenuList, MenuPopover, MenuTrigger, Text } from "@fluentui/react-components";
import { PersonRegular } from "@fluentui/react-icons";
import LogOutButton from "./LogOutButton";
import CustomMenuButton from "../button/CustomMenuButton";
import "./UserAvatar.css";
import CustomerSettingsForm from "../customer/CustomerSettingsForm";
import { useCustomer } from "../../../state/customers/useCustomer";
import useResponsive, { Breakpoint } from "../../../hooks/useResponsive";
import AdjustPaletteColorsForm from "../palette/AdjustPaletteColorsForm";

export interface IUserAvatarProps {
    user?: IUser
}

export default function UserAvatar(props: IUserAvatarProps) {
    
    const {
        user: propsUser
    } = props;

    const {
        user: currentUser
    } = useUser();

    const { 
        customer
    } = useCustomer();

    const isCurrent = !propsUser;
    const user = propsUser ?? currentUser;

    if (!user) return null;

    const name = [user.firstName, user.lastName].join(" ");

    const avatar = (
        <Avatar color="colorful" name={name} />
    )

    if (!isCurrent) return avatar;

    return (
        <Menu>
            <MenuTrigger>
                <Card 
                    className="w-100 p-1" 
                    appearance="subtle"
                >
                    <Flex gap={3} row fullWidth align="center" justify="center">
                        {avatar}
                        <Flex gap={0} className="user-avatar-label">
                            <Text>{name}</Text>
                            <Text size={200}>{user.mail}</Text>
                        </Flex>
                    </Flex>
                </Card>
            </MenuTrigger>
            <MenuPopover>
                <MenuList>
                    {
                        user && (user.isCustomerAdmin || user.isGlobalAdmin) && (
                            <>
                                <CustomerSettingsForm customer={customer} />
                                <AdjustPaletteColorsForm />
                                <MenuDivider />
                            </>
                        )
                    }
                    <LogOutButton />
                </MenuList>
            </MenuPopover>
        </Menu>
    )
}