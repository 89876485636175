import React from "react";
import Flex from "../container/Flex";
import { CounterBadge, Text } from "@fluentui/react-components";
import "./ListPaginator.css";

export interface IListPaginatorProps {
    currentPage: number;
    totalPages: number;
    onPageChange: (page: number) => void;
}

export default function ListPaginator(props: IListPaginatorProps) {
    const {
        currentPage,
        totalPages,
        onPageChange
    } = props;

    if (totalPages <= 0) return null;
    
    const maxPagesDisplayed = 3;
    const halfOfMaxPages = Math.floor(maxPagesDisplayed / 2);

    const showFirst = currentPage > (halfOfMaxPages + 1);
    const showLast = currentPage < (totalPages - halfOfMaxPages - 2);

    const getAvailablePages = (): {min: number, max: number} => {
        if (currentPage + maxPagesDisplayed > totalPages) return { min: totalPages - maxPagesDisplayed, max: totalPages };
        else if (currentPage - halfOfMaxPages > 1) return { min: currentPage - halfOfMaxPages, max: currentPage + halfOfMaxPages};
        return { min: 0, max: maxPagesDisplayed };
    }

    const getPages = (): number[] => {
        const result = [];
        const { min, max } = getAvailablePages();

        for (let i = min; i <= max; i++) {
            result.push(i);
        }
        return result;
    }

    const pages = getPages();

    const getPageSwitcher = (p: number) => {

        if (p < 0) return null;
        if (p > totalPages) return null;

        const isActive = p === currentPage;

        return (
            <div onClick={() => onPageChange(p)} key={p} className="list-paginator">
                <CounterBadge 
                    style={{userSelect: "none"}}
                    overflowCount={totalPages + 1} 
                    count={p + 1} 
                    color={isActive ? "brand" : "informative"} 
                    size={isActive ? "large" : "medium"}
                />
            </div>
        )
    }

    return (
        <Flex row>
            <Text size={200}>Seite:</Text>
            {
                showFirst && (
                    <>
                        {
                            getPageSwitcher(0)
                        }
                        <Text>...</Text>
                    </>
                )
            }
            {
                pages.map(getPageSwitcher)
            }
            {
                showLast && (
                    <>
                        <Text>...</Text>
                        {
                            getPageSwitcher(totalPages)
                        }
                    </>
                )
            }
        </Flex>
    )
}