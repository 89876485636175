import { BalanceIndicator, IMapping, Operator, Year } from "./mapping.schema";
import { IMappingGroup } from "./mappingGroup.schema";
import { IMongooseDocument } from "./mongoose";

export enum ChartType {
    BarChart = "bars",
    LineChart = "lines",
    AreaChart = "area",
    PieChart = "pie",
    DonutChart = "donut",
    TreeMap = "treeMap",
    Sankey = "sankey",
}

export interface IChartMappingAdjustments {
    name: string
}

export interface IChartMappingSankeyData {
    sourceMapping: string
}

export interface IChartMapping {
    mapping: string | IMapping,
    useSecondaryType: boolean,
    adjustments: IChartMappingAdjustments,
    sankeyData: IChartMappingSankeyData,
    yoyPositiveResultIs: BalanceIndicator   
}

export interface IChartMappingGroup {
    mappingGroup: string | IMappingGroup,
    mappings: Array<IChartMapping>,
    yoyStart: string | IMapping
}

export enum ChartGroupByType {
    Year = "year",
    Group = "group"
}

export enum ChartOrientation {
    Horizontal = "horizontal",
    Vertical = "vertical"
}

export interface IChartDocument {
    title: string,
    type: ChartType,
    secondaryType: ChartType,
    years: Array<Year>,
    isYoY: boolean,
    groups: Array<IChartMappingGroup>,
    groupByType: ChartGroupByType, 
    isGlobal: boolean,
    orientation: ChartOrientation
}

export interface IChart extends IChartDocument, IMongooseDocument {
}