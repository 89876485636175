import React from "react";
import { ICustomer, ICustomerSettings } from "../../../types/customer.schema";
import ModalForm from "../modal/ModalForm";
import CustomMenuButton from "../button/CustomMenuButton";
import { SettingsRegular } from "@fluentui/react-icons";
import useApi from "../../../api/useApi";
import Flex from "../container/Flex";
import { Text } from "@fluentui/react-components";
import ColorPicker from "../formik/ColorPicker";
import { useCustomer } from "../../../state/customers/useCustomer";

export interface ICustomerSettingsFormProps {
    customer: ICustomer
}

export default function CustomerSettingsForm(props: ICustomerSettingsFormProps) {

    const {
        customer
    } = props;

    const {
        reloadCustomer
    } = useCustomer();

    const {
        customerSettingsUpdate
    } = useApi();

    return (
        <ModalForm
            title="Einstellungen"
            button={onClick => <CustomMenuButton onClick={onClick} text="Einstellungen" icon={<SettingsRegular />} />}
            initialValues={{
                logo: customer.settings?.logo ?? "",
                theme: {
                    primary: customer.settings?.theme?.primary ?? "",
                    secondary: customer.settings?.theme?.secondary ?? ""
                }
            } as ICustomerSettings}
            onSubmit={async (values) => {
                const res = await customerSettingsUpdate(customer._id, values);
                if (!res.success) return false;
                await reloadCustomer();
                return true;
            }}
        >
            {
                formik => (
                    <Flex fullWidth>
                        <Flex fullWidth>
                            <Text weight="bold">Theme</Text>
                            <ColorPicker name="theme.primary" label="Primärfarbe" />
                            <ColorPicker name="theme.secondary" label="Sekundärfarbe" />
                        </Flex>
                    </Flex>
                )
            }

        </ModalForm>
    )
}