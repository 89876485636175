import React from "react";
import { ChartSizeMode, IChartPosition, IChartSize, ISlideChart, ISlidePadding } from "../../../types/slideDeck.schema";
import Flex from "../container/Flex";
import { Card, Spinner, Text } from "@fluentui/react-components";
import useColorPalette from "../../../hooks/useColorPalette";
import { getTextColorFromBackground } from "../../../util/color";
import { useCharts } from "../../../state/charts/useCharts";
import useChartUtil from "../../../hooks/useChartUtil";
import useSlideUtil from "../../../hooks/useSlideUtil";

export default function SlideChartPreview(props: {
    chart: ISlideChart,
    index: number,
    padding: ISlidePadding
}) {
    const {
        chart,
        index,
        padding
    } = props;

    const {
        getPercentOfSlideSize
    } = useSlideUtil();

    const [loading, setLoading] = React.useState(false);
    const [position, setPosition] = React.useState<IChartPosition>(chart.position);
    const [size, setSize] = React.useState<{ width: string, height: string }>({ height: "50px", width: "50px"});

    const resizeTimer = React.useRef<any>();

    const {
        getRandomColor
    } = useColorPalette();

    const { 
        getChartById
    } = useChartUtil();

    const getSize = (size: IChartSize) => {
        const imgSize: IChartPosition = {
            x: size.sizeMode === ChartSizeMode.width ? size.value : size.value / 9 * 16,
            y: size.sizeMode === ChartSizeMode.height ? size.value : size.value / 16 * 9
        }

        const percentOfSize = getPercentOfSlideSize(imgSize); 

        return {
            width: (percentOfSize.x + padding.left + padding.right) + "%",
            height: (percentOfSize.y + padding.top + padding.bottom) + "%"
        }
    }

    React.useEffect(() => {

        setLoading(true);
        clearTimeout(resizeTimer.current);

        resizeTimer.current = setTimeout(() => {
            const s = getSize(chart.size);
            setPosition(chart.position);
            setSize(s);
            setLoading(false);
        }, 200);

        return () => clearTimeout(resizeTimer.current);

    }, [chart]);

    const color = React.useRef<string>(getRandomColor());

    if (!chart) return null;
    
    const c = getChartById(chart.chart);

    return (
        <Card 
            style={{
                backgroundColor: color.current,
                position: "absolute", 
                borderRadius: "5px",
                left: position.x < 0 ? 0 : position.x + "%", 
                top: position.y < 0 ? 0 : position.y + "%",
                width: size?.width,
                color: getTextColorFromBackground(color.current),
                height: size?.height
            }} 
        >
            <Flex gap={0} fullWidth fullHeight align="center" justify="center">
                {
                    loading 
                    ? <Spinner />
                    : <Text size={200}>{c?.title ?? `NR: ${index + 1}`}</Text>
                }
            </Flex>
        </Card>
    )
    
}