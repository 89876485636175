import useSWR from "swr";
import { RouteParam, Routes } from "../../api/apiRoutes";
import { IHydratedMappingGroupWithMappings, IMappingGroup, IMappingGroupWithMappings } from "../../types/mappingGroup.schema";

export const useMappingGroups = () => {

    const { data, isLoading, mutate } = useSWR<Array<IHydratedMappingGroupWithMappings>>(Routes.mappingGroups.all.route);

    return {
        mappingGroups: data,
        loadingMappingGroups: isLoading,
        reloadMappingGroups: mutate
    }
}