import { Badge, TableCellActions, Text } from "@fluentui/react-components";
import { BuildingBankRegular, CalculatorRegular } from "@fluentui/react-icons";
import React from "react";
import useCurrentGroup from "../../../hooks/useCurrentGroup";
import { CalculationResultType, IMapping } from "../../../types/mapping.schema";
import { IMappingGroupWithMappings } from "../../../types/mappingGroup.schema";
import Flex from "../container/Flex";
import PaginatedList, { IPaginatedListBase } from "../list/PaginatedList";
import DeleteMappingButton from "./DeleteMappingButton";
import MappingPreviewForGroup from "./MappingPreviewForGroup";
import UpdateMappingForm from "./UpdateMappingsForm";
import CalculateMappingTest from "./calculations/CalculateMappingTest";
import BalanceTypeBadge from "./BalanceTypeBadge";

export interface IMappingListProps extends IPaginatedListBase<IMapping> {
    mappingGroup?: IMappingGroupWithMappings,
}

export enum MappingListTab {
    All = "all",
    Calculated = "calculated",
    NotCalculated = "notCalculated"
}

export default function MappingList(props: IMappingListProps) {

    const {
        mappingGroup: selectedMappingGroup,
        canSelect,
        onRemove,
        size = "medium",
        onSelect,
        slot,
        pageSize = 15,
        selectedKeys,
        slotPosition
    } = props;

    const {
        getCurrentGroup
    } = useCurrentGroup();

    const mappingGroup = selectedMappingGroup ?? getCurrentGroup();

    const getIcon = (t: CalculationResultType) => {
        switch (t) {
            case CalculationResultType.Percentage: return "%";
            case CalculationResultType.Number: return "#";
            default: return "€";
        }
    }

    return (
        <PaginatedList
            getKey={m => m._id}
            items={mappingGroup?.mappings ?? []}
            tabs={MappingListTab}
            headers={t => [ "Name", "Typ", t === "Calculated" ? "Berechnung" : "Konten", canSelect ? undefined : "Aktionen" ]}
            renderItem={m => [
                <Flex gap={0}>
                    <Flex row>
                        <Flex row>
                            <Text>{ m.name }</Text>
                           
                        </Flex>
                        {
                            m.isCalculated && <Badge color="informative" appearance="outline"><Text weight="bold">{getIcon(m.resultType)}</Text></Badge>
                        }
                        {
                            m.resultIsDeltaToLastYear && <Badge color="severe" appearance="tint">Vorjahresvergleich</Badge>
                        }
                    </Flex>
                    {m.shortName && <Text size={200}>({m.shortName})</Text>}
                </Flex>,
                <BalanceTypeBadge type={m.indicator} />,
                <MappingPreviewForGroup mapping={m} />,
                !canSelect && (
                    <Flex row>
                        { m.isCalculated && <CalculateMappingTest mapping={m} mappingGroup={mappingGroup} /> }
                        <UpdateMappingForm mappingGroup={mappingGroup} mapping={m} />
                        <DeleteMappingButton mappingGroup={mappingGroup} mapping={m} />
                    </Flex>
                )
            ]}
            canSelect={canSelect}
            selectedKeys={selectedKeys}
            filterPlaceholder="Nach Positionen oder Konten suchen"
            getTabIcon={t => {
                switch (t) {
                    case "Calculated": return <CalculatorRegular />;
                    case "NotCalculated": return <BuildingBankRegular />;
                    default: return null;
                }
            }}
            getTabLabel={t => {
                switch (t) {
                    case "Calculated": return "Berechnete Positionen";
                    case "NotCalculated": return "Kontengruppen";
                    default: return "Alle";
                }
            }}
            itemMatchesFilter={(filter, m) => {
                if (!m.name) return false;
                
                const usableFilter = filter.toLowerCase();
                const name = m.name.toLowerCase();
    
                const nameMatches = usableFilter.includes(name) || name.includes(usableFilter);
    
                if (nameMatches) return true;
    
                const numericFilter = parseInt(filter);
    
                if (isNaN(numericFilter)) return false;
    
                return m.accounts.some(a => a.accountFrom <= numericFilter && (a.accountTo === undefined || a.accountTo >= numericFilter));
            }}
            itemMatchesTab={(tab, m) => {
                if (tab === "Calculated") return m.isCalculated;
                if (tab === "NotCalculated") return !m.isCalculated;
                return true;
            }}
            onRemove={onRemove}
            onSelect={onSelect}
            pageSize={pageSize}
            size={size}
            slot={slot}
            slotPosition={slotPosition}
        />
    )
}