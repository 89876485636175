import { Field } from "formik";
import React from "react";
import "./ColorPicker.css";
import Flex from "../container/Flex";
import { Text } from "@fluentui/react-components";

export default function ColorPicker(props: {
    name: string,
    label?: string,
    readOnly?: boolean
}) {
    
    const {
        label,
        name,
        readOnly
    } = props;

    const picker = (
        <Field disabled={readOnly} name={name} type="color" className="color-picker" />
    );

    if (!label) return picker;

    return (
        <Flex row fullWidth justify="between">
            <Text>{label}</Text>
            {picker}
        </Flex>
    )
}