import React from "react";
import Flex from "../container/Flex";
import { Select, Text } from "@fluentui/react-components";
import { useFormikContext } from "formik";
import useFormikValue from "../../../hooks/useFormikValue";
import { IMappingGroup } from "../../../types/mappingGroup.schema";
import { Open20Filled } from "@fluentui/react-icons";

export interface IFormikSelectProps<T> {
    inline?: boolean,
    bold?: boolean,
    options: Array<T>,
    valueKey?: keyof T,
    labelKey?: keyof T,
    getLabel?: (value: T) => string,
    onChange?: (value: string) => void,
    name: string,
    allowEmptyValue?: boolean,
    label?: string,
    emptyPlaceholder?: string,
    description?: string
}

export default function FormikSelect<T>(props: IFormikSelectProps<T>) {
    
    const {
        inline,
        labelKey,
        name,
        bold = true,
        options,
        onChange,
        getLabel,
        valueKey,
        label,
        emptyPlaceholder,
        allowEmptyValue,
        description
    } = props;

    const {
        setFieldValue
    } = useFormikContext();

    const {
        value: id
    } = useFormikValue<T, string | IMappingGroup>(name);

    const getOptionLabel = (o: T): string => {
        if (!o) return "";
        if (labelKey && o[labelKey]) return o[labelKey].toString();
        else if (getLabel) return getLabel(o);
        else return o.toString();
    }

    const getValueFromOption = (o: T): string => {
        if (!o) return "";
        if (valueKey && o[valueKey]) return o[valueKey].toString();
        else return o.toString();
    }
    
    const getSelectedValueForLabel = (selectedValue: string | undefined | null): string | undefined => {
        if (!selectedValue) return undefined;

        const item = options.find(option => {
            if (labelKey) return option[labelKey] === selectedValue;
            else return getLabel(option) === selectedValue;
        });

        if (!item) return undefined;

        return getValueFromOption(item);
    }

    const getSelectedValueFromFormik = (): string | undefined => {
        if (!id) return undefined;
        const usableId = typeof id === "string" ? id : id._id;
        if (!options || !options.length) return undefined;
        const selectedItem = options.find(o => getValueFromOption(o) === usableId);
        if (!selectedItem) return undefined;
        return getOptionLabel(selectedItem);
    }

    return (
        <Flex fullWidth row={inline}>
            {
                label && (
                    <Flex gap={0}>
                        <Text weight={bold ? "bold" : undefined}>{label}</Text>
                        { description && <Text size={200}>{description}</Text>}
                    </Flex>
                )
            }
            <Select
                className="w-100"
                value={getSelectedValueFromFormik()}
                onChange={(_, data) => {
                    const v = getSelectedValueForLabel(data.value);
                    setFieldValue(name, v);
                    if (onChange) onChange(v);
                }}
                disabled={!options?.length}
            >
                {
                    options && options.length
                    ? (
                        <>
                            {
                                allowEmptyValue && <option key="formik-select-empty-value">Bitte wählen...</option>
                            }
                            {
                                options.map((o, index) => (
                                    <option key={index}>{getOptionLabel(o)}</option>
                                ))
                            }
                        </>
                    )
                    : (
                        <option key="formik-select-no-values-placeholder">{emptyPlaceholder ?? "Keine Werte"}</option>
                    )
                }
            </Select>
        </Flex>
    )
}