import * as React from "react";
import useOfficeVersion from "../hooks/useOfficeVersion";
import FullScreenSpinner from "./components/spinner/FullScreenSpinner";
import { useUser } from "../state/user/useUser";
import LogIn from "./pages/LogIn";
import Sidebar, { AppRoutes, IAppRoute } from "./components/menu/Sidebar";
import "./App.css";
import {
    Route,
    Routes
} from "react-router-dom";
import * as ChartJS from "chart.js/auto";
import { SankeyController } from "../charts/sankey/controller";
import { Flow } from "../charts/sankey/flow";
import { TreemapElement } from "../charts/treemap/element";
import { TreemapController } from "../charts/treemap/controller";
import ChartDataLabels  from "chartjs-plugin-datalabels";

ChartJS.Chart.register( 
    ChartDataLabels,
    SankeyController,
    Flow,
    TreemapElement,
    TreemapController
)

const App = () => {

    const {
        version
    } = useOfficeVersion();

    const { loadingUser, user } = useUser();

    const getRoute = (path: IAppRoute) => <Route path={path.path} key={path.path} element={path.component} />
    
    if (!version || loadingUser) return <FullScreenSpinner />
    if (!user) return (
        <Routes>
            <Route path="*" element={<LogIn /> } />
            {getRoute(AppRoutes.Help)}
            {getRoute(AppRoutes.Privacy)}
        </Routes>
    );


    return (
        <div className="d-flex flex-row vw-100 vh-100 overflow-hidden" id="app">
            <Sidebar />
            <div id="app-content">
                <Routes>
                    {Object.values(AppRoutes).map(getRoute)}
                </Routes>
            </div>
        </div>
    );
};

export default App;
