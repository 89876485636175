import React from "react";
import Flex from "./Flex";
import { generateClassName } from "../../../hooks/useAttributes";
import PageHeader from "../text/PageHeader";
import { parseAppColor } from "../../../util/color";
import "./Page.css";

export interface IPageProps { 
    children: any,
    centered?: boolean,
    noBackground?: boolean,
    title?: string,
    className?: string,
    fullScreen?: boolean,
    action?: React.ReactNode
}

export default function Page(props: IPageProps) {

    const {
        children, 
        fullScreen, 
        title, 
        noBackground = false,
        action,
        centered,
        className
    } = props;

    return (
        <Flex className={generateClassName(className, {
            value: fullScreen,
            onTrue: "position-fixed top-0 start-0 vw-100 vh-100"
        })} align={centered ? "center" : undefined} justify={centered ? "center" : undefined} gap={4} >
            {
                !noBackground && (
                    <>
                        {
                            fullScreen && (
                                <div className="position-fixed page-bg top-0 vw-100 start-0" style={{zIndex: 0}}>
                                    <svg viewBox="0 0 500 500" className="page-bg">
                                        <path d="M 0 50 C 250 250 200 0 500 380 L 500 0 L 0 0" fill={parseAppColor("primary").bg}  opacity="0.15"></path>
                                        <path d="M 0 50 C 350 350 330 -30 500 350 L 500 0 L 0 0" fill={parseAppColor("primary").bg} opacity="0.1"></path>
                                        <path d="M 0 50 C 515 450 450 0 500 400 L 500 0 L 0 0" fill={parseAppColor("primary").bg} opacity="0.05"></path>
                                    </svg>
                                </div>
                            )
                        }
                        <div className="position-fixed bottom-0 vw-100 start-0" style={{zIndex: 0, transform: "rotate(180deg)"}}>
                            <svg viewBox="0 0 500 500">
                                <path d="M 0 50 C 350 350 300 0 500 380 L 500 0 L 0 0" fill={parseAppColor("primary").bg}  opacity={fullScreen ? "0.65" : "0.25"}></path>
                                <path d="M 0 50 C 350 350 330 -30 500 350 L 500 0 L 0 0" fill={parseAppColor("primary").bg} opacity={fullScreen ? "0.45" : "0.15"}></path>
                                <path d="M 0 50 C 415 350 250 0 500 400 L 500 0 L 0 0" fill={parseAppColor("primary").bg} opacity={fullScreen ? "0.2" : "0.05"}></path>
                            </svg>
                        </div>
                    </>
                )
            }
            <Flex row justify="between" wrap fullWidth style={{zIndex: 1}}>
                {
                    title && <PageHeader title={title} />
                }
                {
                    action
                }
            </Flex>
            {
                fullScreen 
                ? children 
                : (
                    <Flex fullWidth gap={3} style={{zIndex: 1}}>
                        {
                            children
                        }
                    </Flex>
                )
            }
        </Flex>
    )
}