import useSWR from "swr";
import { RouteParam, Routes } from "../../api/apiRoutes";
import { ISlideDeck } from "../../types/slideDeck.schema";

export const useSlideDeck = (id: string) => {

    const { data, isLoading, mutate } = useSWR<ISlideDeck>(Routes.slideDecks.byId.getValue([ { param: RouteParam.SlideDeck, value: id }]));

    return {
        slideDeck: data,
        loadingSlideDeck: isLoading,
        reloadSlideDeck: mutate
    }
}