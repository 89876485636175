import React from "react";
import { IMappingGroup, IMappingGroupWithMappings } from "../../../types/mappingGroup.schema";
import { CounterBadge, Input, Spinner, Tab, TabList, Table, TableBody, TableCell, TableCellActions, TableCellLayout, TableHeader, TableHeaderCell, TableRow, TableSelectionCell, Text } from "@fluentui/react-components";
import { BalanceIndicator, IMapping } from "../../../types/mapping.schema";
import Flex from "../container/Flex";
import { BuildingBankRegular, CalculatorRegular, DeleteRegular, SearchRegular } from "@fluentui/react-icons";
import useCurrentGroup from "../../../hooks/useCurrentGroup";
import ListPaginator from "./ListPaginator";
import "./PaginatedList.css";

type StandardEnum<T> = {
    [id: string]: T | string;
    [nu: number]: T | string
}

export interface IPaginatedListBase<T> {
    canSelect?: boolean,
    selectedKeys?: Array<string>,
    slot?: React.ReactElement,
    slotPosition?: "left" | "right",
    size?: "small" | "medium",
    pageSize?: number,
    onSelect?: (item: T) => void,
    onRemove?: (item: T) => void,
}

export interface IPaginatedListProps<T, X extends StandardEnum<any>> extends IPaginatedListBase<T> {
    headers: Array<string> | ((tab: keyof X) => Array<string>),
    items: Array<T> | ((tab: keyof X) => Array<T>),
    getKey: (item: T, index: number) => string | number,
    renderItem: (item: T) => Array<React.ReactNode>
    itemMatchesFilter?: (filter: string, item: T) => boolean,
    itemMatchesTab?: (tab: keyof X, item: T) => boolean,
    tabs?: X,
    getTabLabel?: (tab: keyof X) => string,
    getTabIcon?: (tab: keyof X) => React.ReactElement,
    filterPlaceholder?: string,
    noItemsPlaceholder?: string
}

export default function PaginatedList<T, X extends StandardEnum<any>>(props: IPaginatedListProps<T, X>) {

    const {
        headers,
        items,
        renderItem,
        tabs,
        canSelect,
        noItemsPlaceholder,
        getKey,
        selectedKeys,
        filterPlaceholder,
        getTabIcon,
        getTabLabel,
        itemMatchesFilter,
        itemMatchesTab,
        onRemove,
        onSelect,
        pageSize = 15,
        size,
        slot,
        slotPosition
    } = props;

    const filterTimer = React.useRef<any>();

    const [filtering, setFiltering] = React.useState<boolean>(false);
    const [page, setPage] = React.useState<number>(0);
    const [filter, setFilter] = React.useState<string>("");
    const [pages, setPages] = React.useState<number>(0);
    const [tab, setTab] = React.useState<keyof X>(tabs ? Object.keys(tabs)[0] as keyof X : "");
    const [availableElements, setAvailableElements] = React.useState<Array<T>>([]);
    
    
    const refreshAvailableElements = (resetPage: boolean = true) => {
        const usableItems = typeof items === "function" ? items(tab) : items;

        if (resetPage) setPage(0);

        const elements = (
            usableItems && !!usableItems.length
            ? usableItems.filter(m => {
                if (!!itemMatchesTab && !itemMatchesTab(tab, m)) return false;
                if (!filter || !itemMatchesFilter) return true;
                return itemMatchesFilter(filter, m);
            })
            : []
        )
        
        setAvailableElements(elements);
        setPages(Math.floor(elements.length / pageSize));
        setFiltering(false);
    }

    React.useEffect(() => refreshAvailableElements(false), [items]);
    React.useEffect(() => refreshAvailableElements(), [tab]);
    React.useEffect(() => {
        setFiltering(true);
        clearTimeout(filterTimer.current);
        filterTimer.current = setTimeout(() => refreshAvailableElements(), 300);

        return () => clearTimeout(filterTimer.current);
    }, [filter]);

    const currentElements = availableElements.slice(page * pageSize, (page + 1) * pageSize);

    const tabItems = tabs && Object.keys(tabs);
    const tableHeaders = typeof headers === "function" ? headers(tab) : headers;

    return (
        <Flex fullWidth>
            <Flex fullWidth row justify="between">
                {
                    tabItems && !!tabItems.length && (
                        <TabList 
                            selectedValue={tab} 
                            onTabSelect={(_, data) => setTab(data.value as keyof X)}
                            appearance="subtle"
                            size="small"
                        >
                            {
                                tabItems.map(t => (
                                    <Tab key={t} value={t} slot="before" icon={getTabIcon ? getTabIcon(t) : undefined} >{getTabLabel(t)}</Tab>
                                ))
                            }
                        </TabList>
                    )
                }
                {
                    !!itemMatchesFilter && (
                        <Input 
                            size={size}
                            appearance="filled-darker" 
                            contentBefore={filtering && <Spinner size="extra-tiny" />}
                            contentAfter={(
                                filter 
                                ? <DeleteRegular onClick={() => setFilter("")} /> 
                                : <SearchRegular />
                            )} 
                            onChange={(e, val) => setFilter(val.value)} 
                            placeholder={filterPlaceholder || "Suchen..."}
                            style={{minWidth: "300px"}}
                            value={filter}
                        />
                    )
                }
            </Flex>
            <Table size={size}>
                <TableHeader>
                    <TableRow>
                        {
                            canSelect && <TableSelectionCell ></TableSelectionCell>
                        }
                        {
                            tableHeaders.map(h => {
                                if (!h) return null;
                                return (
                                    <TableHeaderCell key={h}>{h}</TableHeaderCell>
                                )
                            })
                        }
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {
                        currentElements && !!currentElements.length
                        ? currentElements.map((m, index)=> {
                            const itemKey = getKey(m, index);
                            const isSelected = canSelect && selectedKeys?.includes(itemKey.toString());

                            return (
                                <TableRow 
                                    key={itemKey + "-paginated-list-item-" + index}
                                    onClick={() => isSelected ? onRemove?.(m) : onSelect?.(m)}
                                >
                                    {
                                        canSelect && <TableSelectionCell checked={isSelected} />
                                    }
                                    {
                                        renderItem(m).map(x => <TableCell>{x}</TableCell>)
                                    }
                                </TableRow>
                            )
                        })
                        : (
                            <TableRow>
                                <TableCell colSpan={tableHeaders.length + 1}>
                                    { noItemsPlaceholder || "Keine Positionen gefunden"}
                                </TableCell>
                            </TableRow>
                        )
                    }
                </TableBody>
            </Table>
            <Flex fullWidth row justify="between" className="paginated-list-footer">
                {
                    slot ?
                    (
                        slotPosition === "right" 
                        ? <ListPaginator currentPage={page} onPageChange={setPage} totalPages={pages} />
                        : slot
                    )
                    : <div />
                }
                {
                    slot ? 
                    (
                        slotPosition === "left"
                        ? <ListPaginator currentPage={page} onPageChange={setPage} totalPages={pages} />
                        : slot
                    )
                    : <ListPaginator currentPage={page} onPageChange={setPage} totalPages={pages} />
                }
            </Flex>
        </Flex>
    )
}