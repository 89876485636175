import React from "react";
import { ICustomer, ICustomerSettings } from "../../../types/customer.schema";
import ModalForm from "../modal/ModalForm";
import CustomMenuButton from "../button/CustomMenuButton";
import { AddRegular, ArrowClockwiseRegular, ColorRegular, SettingsRegular } from "@fluentui/react-icons";
import useApi from "../../../api/useApi";
import Flex from "../container/Flex";
import { Button, Text } from "@fluentui/react-components";
import ColorPicker from "../formik/ColorPicker";
import { useCustomer } from "../../../state/customers/useCustomer";
import usePersistance from "../../../hooks/usePersistance";
import useColorPalette, { IColorPalette } from "../../../hooks/useColorPalette";
import CustomButton from "../button/CustomButton";
import { Field, useFormikContext } from "formik";
import { ColorMode } from "../../../state/color/color.state";
import { hexToComplimentary } from "../../../util/color";

export default function AdjustPaletteColorsForm() {

    const {
        palette,
        createNewPalette,
        generateColors,
        updatePalette
    } = useColorPalette();

    return (
        <ModalForm
            title="Farben anpassen"
            button={onClick => <CustomMenuButton onClick={onClick} text="Farben" icon={<ColorRegular />} />}
            initialValues={{
                palette: palette
            }}
            onSubmit={async (values) => {
                updatePalette(values.palette);
                return true;
            }}
        >
            {
                formik => {

                    const updatePrimaryColor = (b: string, mode: ColorMode) => {

                        const updateColor = (key: keyof IColorPalette, color: string) => {
                            const newColors = generateColors(color);
                            formik.setFieldValue(`palette.${key}`, newColors);
                        }

                        switch (mode) {
                            case ColorMode.Primary:

                                const newComplementary = hexToComplimentary(b);

                                updateColor("primaries", b);
                                updateColor("complementaries", newComplementary);

                                break;

                            case ColorMode.Accent:

                                updateColor("accents", b);
                                break;

                            case ColorMode.Complementary:

                                updateColor("complementaries", b);
                                break;
                        }
                    }

                    return (
                        <Flex fullWidth>
                            <Flex row fullWidth justify="between">
                                <Text weight="bold">Farben</Text>
                                <CustomButton
                                    text="Neu generieren"
                                    onClick={() => formik.setFieldValue("palette", createNewPalette())}
                                    icon={<ArrowClockwiseRegular />}
                                />
                            </Flex>
                            <PalettePartField 
                                name="palette.primaries" 
                                label="Primärfarben" 
                                values={formik.values.palette.primaries} 
                                afterPrimaryChange={val => updatePrimaryColor(val, ColorMode.Primary)}
                            />
                            <PalettePartField 
                                name="palette.accents" 
                                label="Akzentfarben" 
                                values={formik.values.palette.accents} 
                                afterPrimaryChange={val => updatePrimaryColor(val, ColorMode.Accent)}
                            />
                            <PalettePartField 
                                name="palette.complementaries" 
                                label="Komplementärfarben" 
                                values={formik.values.palette.complementaries} 
                                afterPrimaryChange={val => updatePrimaryColor(val, ColorMode.Complementary)}
                            />
                        </Flex>
                    )
                }
            }

        </ModalForm>
    )
}

function PalettePartField({ label, name, values, afterPrimaryChange }: { 
    name: string, 
    label: string, 
    values: Array<string>,
    afterPrimaryChange?: (newPrimary: string) => void
}) {

    const {
        setFieldValue
    } = useFormikContext();

    const changeTimer = React.useRef<any>();

    const onChange = (val: string) => {
        if (!afterPrimaryChange) return;

        clearTimeout(changeTimer.current);
        changeTimer.current = setTimeout(() => afterPrimaryChange(val), 150);
    }

    const getColorField = (index: number) => {
        const label = index === 0 ? "Basisfarbe" : `${index}`;

        return (
            <Flex key={`color-field-${name}-${index}`}>
                <Field 
                    name={`${name}.${index}`} 
                    onChange={e => onChange(e.target.value)}
                    type="color" 
                    className="color-picker" 
                />
                <Text size={200} weight={index === 0 ? "bold" : undefined}>{label}</Text>
            </Flex>
        )
    }

    return (
        <Flex fullWidth>
            <Flex row fullWidth justify="between">
                <Text weight="bold">{label}</Text>
            </Flex>
            <Flex row align="start" gap={4}>
                {getColorField(0)}
                <Flex row wrap fullWidth>
                    {
                        values.map((c, index) => index > 0 ? getColorField(index) : null)
                    }
                </Flex>
            </Flex>
        </Flex>

    )
}
