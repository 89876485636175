import React from "react";
import Page from "../components/container/Page";
import { AppRoutes } from "../components/menu/Sidebar";
import MappingGroupList from "../components/mappings/groups/MappingGroupList";
import UpdateMappingGroupForm from "../components/mappings/groups/UpdateMappingGroupForm";
import { Card } from "@fluentui/react-components";

export default function Mappings() {
    return (
        <Page title={AppRoutes.Mappings.label} action={<UpdateMappingGroupForm />} >
            <MappingGroupList />
        </Page>
    )
}