import { IModalActionPayload, addNotification, removeNotification } from "../state/modal/modal.state";
import { useAppDispatch } from "../state/reduxHooks";

export default function useNotifications() {
    const dispatch = useAppDispatch();
    return {
        showNotification: (args: IModalActionPayload) => dispatch(addNotification(args)),
        hideNotification: (id: number) => dispatch(removeNotification(id))
    }
    
}