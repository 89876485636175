import React from "react";
import { Year } from "../../../types/mapping.schema";
import Flex from "../container/Flex";
import { INumbersImportState, MappedNumberImport } from "../../../state/datev/numbersImport.state";
import CsvSelect from "../csv/CsvSelect";
import { Card, Spinner } from "@fluentui/react-components";
import { useFormikContext } from "formik";
import useNumberImport, { IAccountValue, ExternalDataSource } from "../../../hooks/useNumberImport";
import useYearUtil from "../../../hooks/useYearUtil";
import MappedResultDialog from "./MappedResultDialog";
import useCurrentGroup from "../../../hooks/useCurrentGroup";
import { raw } from "express";

export interface IDatevSingleYearDataFormProps {
    year: Year
}

export default function DatevSingleYearDataForm({ year }: IDatevSingleYearDataFormProps) {

    const [rows, setRows] = React.useState<number>(0);

    const {
        currentGroupId     
    } = useCurrentGroup();

    const {
        getImportedDataFromCsv
    } = useNumberImport(ExternalDataSource.Datev, currentGroupId);
      
    const {
        values,
        setFieldValue
    } = useFormikContext<INumbersImportState>();

    const {
        getLabelForYear     
    } = useYearUtil(values.currentYear);

    const {
        rawData
    } = values;

    const updateValues = async (data: Array<any>) => {
        if (!data) return;
        const result = getImportedDataFromCsv(data);

        const newRawData = !!rawData ? {...rawData} : {};
        newRawData[year] = result;

        await setFieldValue("rawData", newRawData);
        setRows(data.length);
    }

    const handleNewCsv = async (data: Array<Papa.ParseStepResult<any>>) => {
        if (!data || !data.length) return;
        const importedValues = data.map(d => d.data);
        await updateValues(importedValues);
    }
    
    const existingRows = rows || (rawData && rawData[year] && rawData[year].length);
    const hasData = rows > 0 || (rawData && rawData[year] && !!rawData[year].length);

    return (
        <Card appearance={hasData ? "filled-alternative" : "subtle"} className="w-100">
            <CsvSelect 
                hasData={hasData}
                existingRows={existingRows}
                textOnEmpty={getLabelForYear(year)} 
                textOnSelected={getLabelForYear(year) + " (verarbeitet)"}
                processResult={handleNewCsv} 
            />
        </Card>
    )
}
