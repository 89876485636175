import React from "react";
import LogInForm from "../components/user/LogInForm";
import Page from "../components/container/Page";
import HelpButton from "../components/help/HelpButton";
import IntroductionCarousel from "../components/introduction/IntroductionCarousel";
import "./LogIn.css";
import CustomButton from "../components/button/CustomButton";
import { Link } from "@fluentui/react-components";
import Flex from "../components/container/Flex";
import useResponsive, { Breakpoint, Height } from "../../hooks/useResponsive";
import { generateClassName } from "../../hooks/useAttributes";

export default function LogIn() {

    const {
        isLower
    } = useResponsive(Breakpoint.Tiny, Height.Small);

    return (
        <Page fullScreen className="log-in-container">
            <div 
                className={generateClassName("vw-100", {
                    value: isLower,
                    standard: "vh-100 position-fixed p-4",
                    onTrue: "h-100"
                })}
                style={{zIndex: 0}}
            >
                <IntroductionCarousel />
            </div>
            <div 
                className={generateClassName("vw-100", {
                    value: isLower,
                    base: "position-fixed bottom-0",
                    onTrue: "vh-50",
                    standard: "start-50 translate-middle",
                })}
                style={{zIndex: 1}}
            >
                <Flex align="center" justify="center" fullWidth>
                    <LogInForm />
                    {
                        isLower && <HelpButton floating={false} />
                    }
                </Flex>
            </div>
            {
                !isLower && <HelpButton floating />
            }
        </Page>
    )
}