import React from "react";
import useOfficeVersion from "../hooks/useOfficeVersion";
import axios, { CanceledError, HttpStatusCode } from "axios";
import { IDynamicRoute, IRouteParam, RouteParam, Routes } from "./apiRoutes";
import { ILogInRequest } from "../types/session.schema";
import { IUser, IUserDocument } from "../types/user.schema";
import { ICustomer, ICustomerDocument, ICustomerSettings } from "../types/customer.schema";
import { IMappingGroup, IMappingGroupDocument, IMappingGroupImport } from "../types/mappingGroup.schema";
import { ModalType } from "../state/modal/modal.state";
import { Config } from "../util/config";
import useNotifications from "../hooks/useModal";
import useStorage, { StorageKey } from "../hooks/usePersistance";
import { IMappingDocument } from "../types/mapping.schema";
import { IChart, IChartDocument } from "../types/charts.schema";
import { ISlideDeck, ISlideDeckDocument } from "../types/slideDeck.schema";
import { IMongooseDocument } from "../types/mongoose";
import { IUploadableFile } from "../types/googlecloudstorage.types";

type HttpMethod = "GET" | "POST" | "DELETE";

interface IDispatchParams<T = Object> {
    body?: T,
    routeParams?: Array<IRouteParam>,
    abortController?: AbortController | null,
    messageOnSuccess?: string,
    showMessage?: boolean,
    showMessageOnSuccess?: boolean,
    abortSignal?: AbortSignal | null
}

export interface IApiResponse<T = any> {
    success: boolean,
    canceled: boolean,
    message: string,
    data: T
}

export default function useApi() {

    const {
        value: token
    } = useStorage(StorageKey.Session);

    const {
        showNotification
    } = useNotifications();

    const isErrorApiResponse = (apiResponse: IApiResponse | null | undefined) => {
        if (!apiResponse) return true;
        return !apiResponse.success;
    }

    const createApiError = <T = any>(message: string = "Es ist ein Fehler aufgetreten."): IApiResponse<T> => ({
        success: false,
        canceled: false,
        message: message,
        data: null
    });

    const createCanceledResponse = <T = any>(): IApiResponse<T> => ({
        canceled: true,
        success: false,
        message: "Canceled.",
        data: null
    });

    function createApiSuccessPayload<T>(payload: T): IApiResponse<T> { 
        return ({
            success: true,
            canceled: false,
            message: "Ok",
            data: payload
        });
    }

    const handleApiCall = async<T = any> (apiCall: () => Promise<IApiResponse<T>>, showMessage: boolean = true, showMessageOnSuccess: boolean = false, message: string = "Erfolg"): Promise<IApiResponse<T> | null> => {
        const result = await apiCall();

        if (result && result.canceled) return null;

        if (!result) {
            if (showMessage) showNotification({
                title: "Fehler",
                text: "Es ist ein Fehler aufgetreten.",
                type: ModalType.Error 
            });

            return null;
        }

        if (isErrorApiResponse(result)) {
            if (showMessage) showNotification({
                title: "Fehler",
                text: result.message || "Es ist ein Fehler aufgetreten.",
                type: ModalType.Error
            });

            return null;
        }
        
        if (showMessage && showMessageOnSuccess) showNotification({
            title: "Erfolg",
            text: message,
            type: ModalType.Success
        });

        return result;
    }
    
    const apiRequest = async<ResponseT = any, BodyT = any> (method: HttpMethod = "GET", endpoint: string | IDynamicRoute, params: IDispatchParams<BodyT> = {}) => await handleApiCall<ResponseT>(async () => {
        try {
            if (!endpoint) return createApiError<ResponseT>("No endpoint specified.");

            const {
                body,
                abortController,
                abortSignal
            } = params;

            const url = Config.ApiUrl;
            const usableEndpoint = typeof endpoint === "string" ? endpoint : endpoint.getValue(params?.routeParams || []);

            const res = await axios({
                baseURL: url,
                method: method,
                url: usableEndpoint,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Access-Control-Allow-Origin': "*"
                },
                signal: abortController ? abortController.signal : abortSignal || undefined,
                data: body
            });
            
            if (!res) return createApiError<ResponseT>("Anfrage konnte nicht gesendet werden.");
            if (res.status >= HttpStatusCode.Ok && res.status < HttpStatusCode.MultipleChoices) return createApiSuccessPayload<ResponseT>(res.data);

            return createApiError<ResponseT>("Die Anfrage war fehlerhaft.");

        }
        catch (err: any) {
            if (err instanceof CanceledError) return createCanceledResponse<ResponseT>();
            if (err?.response?.data?.message) return createApiError<ResponseT>(err.response.data.message);
        }

        return createApiError<ResponseT>("Anfrage fehlgeschlagen.");
    }, params?.showMessage, params?.showMessageOnSuccess, params?.messageOnSuccess);
    
    return {
        swrFetch: async (endpoint: string, options?: RequestInit) => {
            try {
                const res = await apiRequest("GET", endpoint, { abortSignal: options?.signal, showMessage: false });
                if (!res.success) return null;
    
                return res.data || true;
            }
            catch {
                return null;
            }
        },
        
        sessionStart: async (body: ILogInRequest) => await apiRequest<{token: string}, ILogInRequest>("POST", Routes.session.current, { body }),
        sessionEnd: async () => await apiRequest("DELETE", Routes.session.current),
        
        usersCreate: async (body: IUserDocument) => await apiRequest("POST", Routes.users.all, { body }),
        usersUpdate: async (id: string, body: IUser) => await apiRequest("POST", Routes.users.byId, { body, routeParams: [{ param: RouteParam.User, value: id }] }),
        usersDelete: async (id: string) => await apiRequest("DELETE", Routes.users.byId, { routeParams: [{ param: RouteParam.User, value: id }] }),

        customersCreate: async (body: ICustomerDocument) => await apiRequest("POST", Routes.customers.all, { body }),
        customersUpdate: async (id: string, body: ICustomer) => await apiRequest("POST", Routes.customers.byId, { body, routeParams: [{ param: RouteParam.Customer, value: id }] }),
        customersDelete: async (id: string) => await apiRequest("DELETE", Routes.customers.byId, { routeParams: [{ param: RouteParam.Customer, value: id }] }),
        
        customerSettingsUpdate: async (id: string, body: ICustomerSettings) => await apiRequest("POST", Routes.customers.settings, { body, routeParams: [{ param: RouteParam.Customer, value: id }] }),
        
        mappingGroupsCreate: async (body: IMappingGroupImport) => await apiRequest("POST", Routes.mappingGroups.all, { body }),
        mappingGroupsUpdate: async (id: string, body: IMappingGroupImport) => await apiRequest("POST", Routes.mappingGroups.byId, { body, routeParams: [{ param: RouteParam.MappingGroup, value: id }] }),
        mappingGroupsDuplicate: async (id: string, body: IMappingGroupImport) => await apiRequest("POST", Routes.mappingGroups.duplicate, { body, routeParams: [{ param: RouteParam.MappingGroup, value: id }] }),
        mappingGroupsDelete: async (id: string) => await apiRequest("DELETE", Routes.mappingGroups.byId, { routeParams: [{ param: RouteParam.MappingGroup, value: id }] }),
        
        mappingsCreate: async (mappingGroupId: string, body: IMappingDocument) => await apiRequest("POST", Routes.mappings.forGroup, { body, routeParams: [{ param: RouteParam.MappingGroup, value: mappingGroupId }] }),
        mappingsUpdate: async (mappingId: string, body: IMappingDocument) => await apiRequest("POST", Routes.mappings.byId, { body, routeParams: [{ param: RouteParam.Mapping, value: mappingId }] }),
        mappingsDelete: async (mappingId: string) => await apiRequest("DELETE", Routes.mappings.byId, { routeParams: [{ param: RouteParam.Mapping, value: mappingId }] }),
    
        chartsCreate: async (body: IChartDocument) => await apiRequest("POST", Routes.charts.all, { body }),
        chartsUpdate: async (id: string, body: IChart) => await apiRequest("POST", Routes.charts.byId, { body, routeParams: [{ param: RouteParam.Chart, value: id }] }),
        chartsDelete: async (id: string) => await apiRequest("DELETE", Routes.charts.byId, { routeParams: [{ param: RouteParam.Chart, value: id }] }),

        slideDecksCreate: async (body: ISlideDeckDocument) => await apiRequest("POST", Routes.slideDecks.all, { body }),
        slideDecksUpdate: async (id: string, body: ISlideDeck) => await apiRequest("POST", Routes.slideDecks.byId, { body, routeParams: [{ param: RouteParam.SlideDeck, value: id }] }),
        slideDecksDownload: async (id: string) => await apiRequest<IUploadableFile>("GET", Routes.slideDecks.downloadPreset, { routeParams: [{ param: RouteParam.SlideDeck, value: id }] }),
        slideDecksDelete: async (id: string) => await apiRequest("DELETE", Routes.slideDecks.byId, { routeParams: [{ param: RouteParam.SlideDeck, value: id }] })
    }
}