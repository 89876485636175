import React from "react";
import "./Flex.css";
import IElementProps from "../../../types/element.types";
import { generateClassName, generateStyle, generateStyleWithBase } from "../../../hooks/useAttributes";

export enum FlexGap {
    InnerContent = 2,
    Even = 3,
    BetweenItems = 4
}

export interface IFlexProps extends IElementProps {
    row?: boolean,
    align?: "start" | "center" | "baseline" | "top" | "end" | "bottom",
    justify?: "start" | "center" | "end" | "between" | "around",
    overflowX?: "auto" | "hidden" | "visible" | "scroll" | "initial" | "inherit",
    overflowY?: "auto" | "hidden" | "visible" | "scroll" | "initial" | "inherit",
    wrap?: boolean,
    reverse?: boolean,
    fullWidth?: boolean,
    fullHeight?: boolean,
    gap?: 0 | 1 | 2 | 3 | 4 | 5
}

export default function Flex(props: IFlexProps) {
    
    const {
        wrap, 
        fullHeight, 
        fullWidth,
        row = false, 
        overflowX, 
        overflowY, 
        gap, 
        onClick, 
        reverse, 
        children, 
        style, 
        className, 
        align, 
        justify = "start"
    } = props;

    const realGap = gap === undefined ? FlexGap.InnerContent : gap;

    const defaultAlign = row ? "center" : "start";
    
    const flexClass = generateClassName(className, "flexbox d-flex", {
        base: "flex-",
        value: row, 
        onTrue: `row${reverse ? "-reverse" : ""}`,
        standard: `column${reverse ? "-reverse" : ""}`
    }, {
        base: "gap-",
        value: realGap
    }, {
        base: "align-items-",
        value: align || defaultAlign
    }, {
        base: "justify-content-",
        value: justify
    }, {
        value: wrap,
        onTrue: "flex-wrap"
    }, {
        value: fullWidth,
        onTrue: "w-100"
    }, {
        value: fullHeight,
        onTrue: "h-100"
    }, {
        value: !!onClick,
        onTrue: "flex-clickable"
    });

    const realStyle = generateStyleWithBase(style, {
        name: "overflowY",
        applyCondition: overflowY !== undefined,
        value: overflowY
    }, {
        name: "overflowX",
        applyCondition: overflowX !== undefined,
        value: overflowX
    }); 

    return (
        <div className={flexClass} style={realStyle} onClick={onClick} >
            {children}
        </div>
    )
}