import useSWR from "swr";
import { RouteParam, Routes } from "../../api/apiRoutes";
import { IMappingGroup, IMappingGroupWithMappings } from "../../types/mappingGroup.schema";
import { ICustomer } from "../../types/customer.schema";

export const useCustomer = () => {

    const { data, isLoading, mutate } = useSWR<ICustomer>(Routes.customers.current.route);

    return {
        customer: data,
        loadingCustomer: isLoading,
        reloadCustomer: mutate
    }
}   