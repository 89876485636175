import React from "react";
import Page from "../components/container/Page";
import SlideDeckList from "../components/slideDecks/SlideDeckList";
import UpdateSlideDeckForm from "../components/slideDecks/UpdateSlideDeckForm";
import { Card, Spinner, Text } from "@fluentui/react-components";
import { useParams } from "react-router-dom";
import { useSlideDeck } from "../../state/slideDecks/useSlideDeck";

export default function Presentation() {

    const {
        id
    } = useParams();

    const {
        slideDeck,
        loadingSlideDeck
    } = useSlideDeck(id);

    return (
        <Page title={slideDeck ? `Präsentation '${slideDeck.name}' bearbeiten` : "Präsentation bearbeiten"}>
            {
                loadingSlideDeck 
                ? <Spinner /> 
                : (
                    slideDeck
                    ? <UpdateSlideDeckForm slideDeck={slideDeck} />
                    : <Text>Präsentation nicht gefunden</Text>
                )
            }
        </Page>
    )
}