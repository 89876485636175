import React from "react";
import Page from "../components/container/Page";
import UpdateCustomerForm from "../components/customer/UpdateCustomerForm";
import CustomerList from "../components/customer/CustomerList";
import { Card } from "@fluentui/react-components";

export default function Customers() {
    return (
        <Page title="Kundenverwaltung" action={<UpdateCustomerForm />}>
            <CustomerList />
        </Page>
    )
}