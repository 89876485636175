import React from "react";

export enum Host {
Mac = "Mac Desktop",
Windows = "Windows Desktop",
OfficeOnline = "OfficeOnline",
Browser = "Browser"
}

export interface IVersion {
majorVersion: number,
minorVersion: number,
build: number,
revision: number,
host: Host,
platform: any,
isWeb: boolean,
isDesktop: boolean,
isLocalhost: boolean
}


export default function useOfficeVersion() {
    
    const isFeatureAvailable = (obj: any): boolean => {
        if (typeof obj === "undefined") return false;
        if (!obj) return false;
        return true;
    }

    const isVersionSupported = (v: IVersion | null): boolean => {
        if (!v) return false;
        if (v.host === Host.Browser) return false;
        if (v.majorVersion < 16) return false;
        if (v.host === Host.Mac && v.build >= 34) return true;
        if (v.build >= 14326) return true;
        return v.revision > 20454;
    }

    const getVersionString = (v: IVersion): string => `${v.majorVersion}.${v.minorVersion}.${v.build}.${v.revision}`;

    const getOfficeVersion = (): IVersion | null=> {
        const isLocalhost = window.location.href.includes("https://localhost:3000");

        const result: IVersion = {
            majorVersion: 1,
            minorVersion: 0,
            platform: "Web",
            build: 0,
            revision: 0,
            host: Host.Browser,
            isWeb: false,
            isDesktop: false,
            isLocalhost: isLocalhost
        };

        try {
            const openedInsideOffice = isFeatureAvailable(Office) && isFeatureAvailable(Office.context) && isFeatureAvailable(Office.context.diagnostics);
        
            if (!openedInsideOffice) return result;
        
            const version = Office.context.diagnostics.version;

            result.platform = Office.context.diagnostics.platform;

            if (result.platform === Office.PlatformType.OfficeOnline) {
                result.host = Host.OfficeOnline;
                return result;
            }

            result.isDesktop = openedInsideOffice && isFeatureAvailable(PowerPoint);
            
            if (!version) return null;
            if (typeof version !== "string") return null;
            if (version.length <= 0) return null;
            
            const parts = version.split(".");
        
            if (!parts) return null;
        
            result.host = result.platform === Office.PlatformType.Mac ? Host.Mac : Host.Windows;

            if (parts.length < 2) return result;

            if (result.host === Host.Mac) {
                result.majorVersion = parseInt(parts[0]);
                result.build = parseInt(parts[1]);

                return result;
            }


            if (parts.length === 3) {
                result.majorVersion = parseInt(parts[0]);
                result.build = parseInt(parts[1]);
                result.revision = parseInt(parts[2]);
            }
            else if (parts.length === 4) {
                result.majorVersion = parseInt(parts[0]);
                result.minorVersion = parseInt(parts[1]);
                result.build = parseInt(parts[2]);
                result.revision = parseInt(parts[3]);
            }

            return result;
        }
        catch (err) {
            console.error(err);
            return null;
        }
        finally {
            let log = `Running inside ${result.host}`;

            if (result.host !== Host.Browser) log += `, version '${getVersionString(result)}'`;
            if (result.isLocalhost) log += ', local development deployment';

            result.isWeb = result.host === Host.Browser;
        }
    }
        
    const version = getOfficeVersion();

    return {
        version,
        isOpenOutsideOfOffice: version ? version.isWeb : true,
        isVersionSupported,
        isFeatureAvailable,
        getVersionString
    }
}