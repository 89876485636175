import React from "react";
import { Field, Form, Formik } from "formik";
import useApi from "../../../api/useApi";
import { ILogInRequest } from "../../../types/session.schema";
import { Button, Card, Input, Link } from "@fluentui/react-components";
import FormikField from "../formik/FormikField";
import useStorage, { StorageKey } from "../../../hooks/usePersistance";
import FormikForm from "../formik/FormikForm";
import Flex from "../container/Flex";
import { DoorRegular } from "@fluentui/react-icons";
import Logo from "../logo/Logo";
import useResponsive, { Height } from "../../../hooks/useResponsive";
import CustomButton from "../button/CustomButton";



export default function LogInForm() {

    const {
        updatePersistedValue
    } = useStorage(StorageKey.Session);

    const {
        sessionStart
    } = useApi();

    const {
        isLower
    } = useResponsive(undefined, Height.Small);

    return (
        <Card>
            {
                !isLower && <Logo centered size={88} padding={3}/>
            }
            <FormikForm
                initialValues={{
                    mail: "",
                    password: ""
                } as ILogInRequest}
                secondaryAction={
                    <CustomButton
                        appearance="outline" 
                        onClick={() => Office.context.ui.openBrowserWindow("https://vip.steuerkoepfe.de/")}
                        text="Zugang anfragen"
                    />
                }
                onSubmit={async (values) => {
                    const res = await sessionStart(values);
                    if (!res || !res.data) return false;
                    updatePersistedValue(res.data.token);
                    window.location.reload();
                    return true;
                }}
                submitText="Anmelden"
                submitIcon={<DoorRegular />}

            >
                {
                    formik => (
                        <>
                            <FormikField name="mail" type="email" label="E-Mail" />
                            <FormikField name="password" type="password" appearance="filled-darker" label="Passwort" />
                        </>
                    )
                }
            </FormikForm>
        </Card>
    )
}