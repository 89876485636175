import React from "react";
import { ColorMode, setColorMode } from "../state/color/color.state";
import { useAppDispatch, useAppSelector } from "../state/reduxHooks";
import usePersistance, { StorageKey } from "./usePersistance";

export default function useColorMode() {

    const dispatch = useAppDispatch();

    const {
        colorMode
    } = useAppSelector(state => state.colorMode);

    const {
        updatePersistedValue,
        value
    } = usePersistance<ColorMode>(StorageKey.ColorMode);

    const updateMode = (mode: ColorMode) => {
        dispatch(setColorMode(mode));
        updatePersistedValue(mode);
    }

    React.useEffect(() => {
        if (!value) return;
        
        if (colorMode) {
            updateMode(colorMode);
            return;
        }

        if (value === colorMode) return;

        updateMode(value);
    }, [])

    return {
        colorMode,
        updateCurrentColorMode: updateMode,
    }
}