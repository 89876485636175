import React from "react";
import { useFormikContext } from "formik";
import { IChartDocument } from "../../../types/charts.schema";
import { useMappingGroups } from "../../../state/mappingGroups/useMappingGroups";
import { Button, Menu, MenuItem, MenuList, MenuPopover, MenuTrigger } from "@fluentui/react-components";
import { AddRegular } from "@fluentui/react-icons";
import CustomButton from "../button/CustomButton";


interface IAddNewMappingGroupButtonProps {
    addGroup: (groupId: string) => void
}

export default function AddNewMappingGroupButton(props: IAddNewMappingGroupButtonProps) {
    const {
        addGroup
    } = props;

    const {
        values,
        setFieldValue     
    } = useFormikContext<IChartDocument>();

    const {
        loadingMappingGroups,
        mappingGroups
    } = useMappingGroups();

    if (!mappingGroups || !mappingGroups.length) return null;

    const availableGroups = (!values.groups || !values.groups.length) ? mappingGroups : mappingGroups.filter(g => !values.groups.find(vg => 
        typeof vg.mappingGroup === "string" 
        ? vg.mappingGroup === g._id 
        : vg.mappingGroup._id === g._id
    ));

    if (!availableGroups || !availableGroups.length) return null;

    return (
        <Menu>
            <MenuTrigger>
                <Button
                    icon={<AddRegular />}
                    appearance="subtle"
                >
                    Neue Zuweisung
                </Button>
            </MenuTrigger>
            <MenuPopover>
                <MenuList>
                    {
                        availableGroups.map(m => (
                            <MenuItem
                                key={m._id}
                                onClick={() => addGroup(m._id)}
                            >
                                {m.name}
                            </MenuItem>
                        ))
                    }
                </MenuList>
            </MenuPopover>
        </Menu>
    )
    
}