import React from "react";
import { useUsers } from "../../../state/user/useUsers";
import { Badge, Button, Card, Input, Menu, MenuItem, MenuList, MenuPopover, MenuTrigger, Spinner, Tab, TabList, Table, TableBody, TableCell, TableCellActions, TableCellLayout, TableHeader, TableHeaderCell, TableRow, Text } from "@fluentui/react-components";
import UserAvatar from "./UserAvatar";
import { useUser } from "../../../state/user/useUser";
import { IUser } from "../../../types/user.schema";
import UpdateUserForm from "./UpdateUserForm";
import Flex from "../container/Flex";
import { BuildingRegular, CheckmarkRegular, FilterRegular, SearchRegular } from "@fluentui/react-icons";
import { useCustomer } from "../../../state/customers/useCustomer";
import { useCustomers } from "../../../state/customers/useCustomers";
import { ICustomer } from "../../../types/customer.schema";
import UserAdminBanner from "./UserAdminBanner";
import DeleteUserButton from "./DeleteUserButton";
import useResponsive, { Breakpoint } from "../../../hooks/useResponsive";

export enum UserTab {
    All = "all",
    Admins = "admins"
}

export default function UserList(props: { onCustomerChange?: (customer: ICustomer) => void}) {

    const {
        onCustomerChange
    } = props;

    const {
        user     
    } = useUser();

    const {
        customer: currentCustomer
    } = useCustomer();

    const {
        customers,
        loadingCustomers
    } = useCustomers();
    
    const [filter, setFilter] = React.useState<string>("");
    const [tab, setTab] = React.useState<UserTab>(UserTab.All);
    const [customer, setCustomer] = React.useState<ICustomer>(currentCustomer);

    const {
        isNarrower     
    } = useResponsive(Breakpoint.Mobile);

    const {
        loadingUsers,
        reloadUsers,
        users
    } = useUsers(customer?._id);

    React.useEffect(() => {
        if (!currentCustomer) return;
        if (!!customer) return;
        setCustomer(currentCustomer);
    }, [currentCustomer]);
    
    const updateCustomer = (c: ICustomer) => {
        setCustomer(c);
        if (onCustomerChange) onCustomerChange(c);
    }

    const filteredUsers = (
        users && !!users.length
        ? users.filter(u => {
            if (!filter) return true;
            if (u.firstName.toLowerCase().includes(filter.toLowerCase())) return true;
            if (u.lastName.toLowerCase().includes(filter.toLowerCase())) return true;
            if (u.mail.toLowerCase().includes(filter.toLowerCase())) return true;
            return false;
        })
        : []
    );

    return (
        <Flex fullWidth>
            <Flex row wrap fullWidth justify="between">
                <TabList
                    onTabSelect={(_, data) => setTab(data.value as UserTab)}
                    selectedValue={tab}
                >
                    <Tab value={UserTab.All}>Alle</Tab>
                    <Tab value={UserTab.Admins}>Administratoren</Tab>
                </TabList>
                <Flex row>
                    {
                        user?.isGlobalAdmin && (customers && !!customers.length) && (
                            <Menu hasIcons>
                                <MenuTrigger disableButtonEnhancement>
                                    <Button 
                                        appearance="subtle"
                                        icon={<BuildingRegular />}
                                    >
                                        {
                                            !isNarrower && (
                                                customer ? customer.name : "Kunde auswählen..."

                                            )
                                        }
                                    </Button>
                                </MenuTrigger>
                                <MenuPopover>
                                    <MenuList>
                                        {
                                            customers.map(c => (
                                                <MenuItem
                                                    key={c._id}
                                                    icon={
                                                        c._id === customer?._id
                                                        ? <CheckmarkRegular />
                                                        : undefined
                                                    }
                                                    onClick={() => updateCustomer(c)}
                                                >
                                                    {c.name}
                                                </MenuItem>
                                            ))
                                        }
                                    </MenuList>
                                </MenuPopover>
                            </Menu>
                        )
                    }
                    <Input 
                        className="full-width-mobile"
                        value={filter} 
                        onChange={(e, data) => setFilter(data.value)} 
                        placeholder="Suchen..." 
                        type="text" 
                        contentAfter={<SearchRegular />}
                    />
                </Flex>
            </Flex>
            <Card className="w-100">
                <Table>
                    <TableHeader>
                        <TableRow>
                            <TableHeaderCell>Nutzer</TableHeaderCell>
                            {
                                !isNarrower && <TableHeaderCell>E-Mail</TableHeaderCell>
                            }
                            {
                                !isNarrower && <TableHeaderCell>Admin-Status</TableHeaderCell>
                            }
                            <TableHeaderCell>Aktionen</TableHeaderCell>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {
                            loadingUsers
                            ? <TableRow><TableCell><Spinner /></TableCell></TableRow>
                            : (
                                !users || !users.length 
                                ? <TableRow><TableCell><Text>Keine Nutzer vorhanden</Text></TableCell></TableRow>
                                : (
                                    filteredUsers && filteredUsers.length
                                    ? filteredUsers.map(u => (
                                        <TableRow key={u._id}>
                                            <TableCell>
                                                <TableCellLayout
                                                    media={<UserAvatar user={u} />}
                                                >
                                                    <Flex gap={1}>
                                                        <Text>{u.firstName} {u.lastName}</Text>
                                                        { isNarrower && <Text size={200}>{u.mail}</Text>}
                                                        { isNarrower && <UserAdminBanner user={u} /> }
                                                    </Flex>
                                                </TableCellLayout>
                                            </TableCell>
                                            {
                                                !isNarrower && <TableCell>{u.mail}</TableCell>
                                            }
                                            {
                                                !isNarrower && (
                                                    <TableCell>
                                                        <UserAdminBanner user={u} />
                                                    </TableCell>
                                                )
                                            }
                                            <TableCell>
                                                <Flex align="end" row>
                                                    <UpdateUserForm user={u} customer={u.customer} />
                                                    <DeleteUserButton user={u} />
                                                </Flex>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                    : <TableRow><TableCell><Text>Keine Ergebnisse</Text></TableCell></TableRow>
                                )
                            )
                        }
                    </TableBody>
                </Table>        
            </Card>
        </Flex>
    )
}
