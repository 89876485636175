import { IStatValues } from "./squarify";

const min = Math.min;
const max = Math.max;

function getStat(sa: StatArray) {
    return {
        min: sa.min,
        max: sa.max,
        sum: sa.sum,
        nmin: sa.nmin,
        nmax: sa.nmax,
        nsum: sa.nsum
    };
}

function getNewStat(sa: StatArray, o: { [x: string]: string | number; _normalized: number; }) {
    const v = +o[sa.key];
    const n = v * sa.ratio;
    o._normalized = n;

    return {
        min: min(sa.min, v),
        max: max(sa.max, v),
        sum: sa.sum + v,
        nmin: min(sa.nmin, n),
        nmax: max(sa.nmax, n),
        nsum: sa.nsum + n
    };
}

function setStat(sa: any, stat: any) {
    Object.assign(sa, stat);
}

function push(sa: StatArray, o: any, stat: { min: number; max: number; sum: any; nmin: number; nmax: number; nsum: any; }) {
    sa._arr.push(o);
    setStat(sa, stat);
}

export default class StatArray {

    key: string;
    ratio: number;
    sum: number;
    nsum: number;
    min: number;
    max: number;
    nmin: number;
    nmax: number;
    _arr: any[];
    _hist: any[];

    constructor(key: string, ratio: number) {
        const me = this;
        me.key = key;
        me.ratio = ratio;
        me.reset();
    }

    get length() {
        return this._arr.length;
    }

    reset() {
        const me = this;
        me._arr = [];
        me._hist = [];
        me.sum = 0;
        me.nsum = 0;
        me.min = Infinity;
        me.max = -Infinity;
        me.nmin = Infinity;
        me.nmax = -Infinity;
    }

    push(o: any) {
        push(this, o, getNewStat(this, o));
    }

    pushIf(o: any, fn: { (oldStat: IStatValues, newStat: IStatValues, args: [any]): boolean; (arg0: { min: any; max: any; sum: any; nmin: any; nmax: any; nsum: any; }, arg1: { min: number; max: number; sum: any; nmin: number; nmax: number; nsum: any; }, arg2: any[]): any; }, ...args: number[]) {
        const nstat = getNewStat(this, o);
        if (!fn(getStat(this), nstat, args)) {
            return o;
        }
        push(this, o, nstat);
    }

    get() {
        return this._arr;
    }
}
