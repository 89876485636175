import React, { PropsWithChildren } from "react";
import Flex from "./components/container/Flex";
import CustomButton from "./components/button/CustomButton";
import { Text } from "@fluentui/react-components";
import Logo from "./components/logo/Logo";

interface IErrorBoundaryState {
    hasError: boolean,
    error: any
}

export default class ErrorBoundary extends React.Component<PropsWithChildren, IErrorBoundaryState> {
    constructor(props: PropsWithChildren) {
        super(props);
        this.state = { 
            hasError: false,
            error: undefined
        };
    }
  
    static getDerivedStateFromError(error: Error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, error: error };
    }
  
    logErrorToMyService(error: any, errorInfo: any) {
        console.log("Error at " + Date.now().toLocaleString() + ":")
        console.log(error);
        console.log(errorInfo);
    }

    componentDidCatch(error: any, errorInfo: any) {
        this.logErrorToMyService(error, errorInfo);
    }
    
    render() {
        if (this.state.hasError) return (
            <Flex fullWidth justify="center" align="center" fullHeight className="vw-100 vh-100 position-fixed top-0 start-0">
                <Logo size={200} />
                <Flex className="mb-3" />
                <Text size={900} weight="bold">
                    500: Fehler
                </Text>
                <Text>Es ist ein Fehler aufgetreten.</Text>
                <Text>{this.state.error?.toString()}</Text>
                <CustomButton text="Neu laden" onClick={() => window.location.reload()} />
            </Flex>
        );
        return this.props.children; 
    }
}