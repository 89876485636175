import React from "react";
import { ChartGroupByType, ChartOrientation, IChartDocument } from "../../../types/charts.schema";
import { Badge } from "@fluentui/react-components";

export default function ChartGroupByBanner(props: { chart: IChartDocument }) {

    if (!props.chart || !props.chart.groupByType) return null;
    if (props.chart.groupByType !== ChartGroupByType.Group) return null;

    return (
        <Badge color="informative">Kontengruppierung</Badge>
    )
}