import React from "react";
import { useCustomers } from "../../../state/customers/useCustomers";
import { Avatar, AvatarGroup, AvatarGroupItem, Badge, Card, CardFooter, CardHeader, Spinner, Text } from "@fluentui/react-components";
import Flex from "../container/Flex";
import UpdateCustomerForm from "./UpdateCustomerForm";
import DeleteCustomerButton from "./DeleteCustomerButton";
import { BuildingRegular } from "@fluentui/react-icons";

export default function CustomerList() {

    const {
        customers,
        loadingCustomers
    } = useCustomers();

    if (loadingCustomers) return <Spinner />

    return (
        <Flex fullWidth row wrap>
            {
                customers && !!customers.length
                ? customers.map(c => (
                    <Card key={c._id} className="full-width-mobile">
                        <CardHeader
                            image={
                                <AvatarGroup layout="stack">
                                    <AvatarGroupItem icon={<BuildingRegular />} color="anchor" />
                                    <AvatarGroupItem name={c.name} color="colorful" />
                                </AvatarGroup>
                            }
                            header={
                                <Flex row>
                                    <Text weight="bold">{c.name}</Text>
                                    {
                                        c.isMaster && <Badge color="danger">Verwaltung</Badge>
                                    }
                                </Flex>
                            }
                            description={<Text size={200}>Kundenname</Text>}
                        />
                        <CardFooter>
                            <Flex row wrap>
                                <UpdateCustomerForm customer={c} />
                                <DeleteCustomerButton customer={c} />
                            </Flex>
                        </CardFooter>
                    </Card>
                ))
                : <Text>Keine Kunden hinterlegt</Text>
            }
        </Flex>
    )
}