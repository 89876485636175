import React from "react";
import { IUser, IUserDocument } from "../../../types/user.schema";
import ModalForm from "../modal/ModalForm";
import Flex from "../container/Flex";
import { AddRegular, ArrowClockwiseRegular, EditRegular } from "@fluentui/react-icons";
import useApi from "../../../api/useApi";
import FormikField from "../formik/FormikField";
import { useUser } from "../../../state/user/useUser";
import { Button, Card, Text } from "@fluentui/react-components";
import CheckBox from "../formik/CheckBox";
import { ICustomer } from "../../../types/customer.schema";
import { useUsers } from "../../../state/user/useUsers";
import { getId } from "../../../util/mongoUtil";
import useResponsive, { Breakpoint } from "../../../hooks/useResponsive";

export interface IUpdateUserFormProps {
    user?: IUser,
    customer: string | ICustomer
}

export default function UpdateUserForm(props: IUpdateUserFormProps) {

    const {
        user,
        customer
    } = props;

    const {
        user: currentUser
    } = useUser();

    const {
        isNarrower
    } = useResponsive(Breakpoint.Mobile);

    const {
        reloadUsers
    } = useUsers(getId(customer));

    const {
        usersCreate,
        usersUpdate
    } = useApi();

    if (!customer) return null;
    if (!currentUser) return null;
    if (user?.isGlobalAdmin && !currentUser.isGlobalAdmin) return null;

    const createRandomSecurePassword = (): string => {
        const specialChars = "!@#$%^&*()_+~`|}{[]:;?><,./-=";
        const upperCaseChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        const lowerCaseChars = "abcdefghijklmnopqrstuvwxyz";
        const numberChars = "0123456789";

        const length = 16;

        let password = "";

        const appendCharFromCollection = (collection: string) => {
            password += collection[Math.floor(Math.random() * collection.length)];
        }

        for (let i = 0; i < length; i++) {
            const random = Math.floor(Math.random() * 4);

            switch (random) {
                case 0:
                    appendCharFromCollection(specialChars);
                    break;
                case 1:
                    appendCharFromCollection(upperCaseChars);
                    break;
                case 2:
                    appendCharFromCollection(lowerCaseChars);
                    break;
                case 3:
                    appendCharFromCollection(numberChars);
                    break;
            }
        }

        appendCharFromCollection(specialChars);
        appendCharFromCollection(upperCaseChars);
        appendCharFromCollection(lowerCaseChars);
        appendCharFromCollection(numberChars);

        return password;
    }

    return (
        <ModalForm
            text={user ? (!isNarrower && "Bearbeiten") : "Neuer Benutzer"}
            icon={user ? <EditRegular /> : <AddRegular /> }
            title={user ? "Nutzer bearbeiten" : "Neuen Nutzer erstellen"}
            description={typeof customer === "string" ? "" : `Nutzer für Kunden '${customer.name}' erstellen`}
            enableReinitialize
            initialValues={{
                isCustomerAdmin: user?.isCustomerAdmin ?? false,
                isGlobalAdmin: user?.isGlobalAdmin ?? false,
                customer: typeof customer === "string" ? customer : customer._id,
                firstName: user?.firstName ?? "",
                lastName: user?.lastName ?? "",
                mail: user?.mail ?? "",
                password: ""
            } as IUserDocument}
            onSubmit={async (values) => {
                const res = (
                    user
                    ? await usersUpdate(user._id, values as IUser)
                    : await usersCreate(values)
                ).success;

                if (!res) return false;

                await reloadUsers();

                return res;
            }}
        >
            {
                formik => (
                    <Flex fullWidth gap={3}>
                        <Flex fullWidth>
                            <Flex row fullWidth wrap>
                                <FormikField name="firstName" placeholder="Vorname" label="Vorname" />
                                <FormikField name="lastName" placeholder="Nachname" label="Nachname" />
                            </Flex>
                            <FormikField name="mail" placeholder="E-Mail" type="email" label="E-Mail" autoComplete="off" />
                            <FormikField name="password" placeholder="Passwort" type="password" label="Passwort" autoComplete="off" passwordVisibleInitially />
                            <Button 
                                type="button" 
                                onClick={() => formik.setFieldValue("password", createRandomSecurePassword())} 
                                className="align-self-end" 
                                appearance="outline" 
                                icon={<ArrowClockwiseRegular />}
                            >Passwort vorschlagen</Button>
                        </Flex>
                        {
                            (currentUser.isGlobalAdmin || currentUser.isCustomerAdmin) && user && (
                                <Card className="w-100" appearance="filled-alternative">
                                    <Text weight="bold">Admin-Rechte</Text>
                                    {
                                        currentUser.isGlobalAdmin && (
                                            <CheckBox 
                                                name="isGlobalAdmin" 
                                                label="Globaler Administrator" 
                                                disabled={user?._id === currentUser._id} 
                                            />
                                        )
                                    }
                                    <CheckBox name="isCustomerAdmin" label="Kunden-Administrator" disabled={user?._id === currentUser._id} />
                                </Card>
                            )
                        }
                    </Flex>
                )
            }
        </ModalForm>
    )
}