import React from "react";
import { CalculationResultType } from "../../../../types/mapping.schema";
import { Combobox, Option, Select, Text } from "@fluentui/react-components";
import Flex from "../../container/Flex";

export interface ICalculationResultTypeSelectProps {
    onSave: (type: CalculationResultType) => void,
    value: CalculationResultType,
    inline?: boolean
}

export default function CalculationResultTypeSelect(props: ICalculationResultTypeSelectProps) {
    const {
        onSave,
        inline = false,
        value
    } = props;

    const getLabel = (type: CalculationResultType) => {
        switch (type) {
            case CalculationResultType.Number: return "Zahl";
            case CalculationResultType.Currency: return "Währung";
            case CalculationResultType.Percentage: return "Prozent";
        }
    }

    return (
        <Flex fullWidth row={inline}>
            <Text>Ergebnistyp</Text>
            <Select
                value={value}
                onChange={(e) => onSave(e.target.value as CalculationResultType)}
            >
                <option value={CalculationResultType.Number}>{getLabel(CalculationResultType.Number)}</option>
                <option value={CalculationResultType.Currency}>{getLabel(CalculationResultType.Currency)}</option>
                <option value={CalculationResultType.Percentage}>{getLabel(CalculationResultType.Percentage)}</option>
            </Select>
        </Flex>
    )
}