import React from "react";
import { IMappingCalculationElement, MappingCalculationElementType } from "../../../../types/mapping.schema";
import { Badge, Card } from "@fluentui/react-components";
import Flex from "../../container/Flex";
import { index } from "../../../../charts/treemap/utils";
import CustomButton from "../../button/CustomButton";
import { DeleteRegular, EditRegular, StyleGuide20Filled } from "@fluentui/react-icons";
import { generateStyle } from "../../../../hooks/useAttributes";
import CalculationElementBadge from "./CalculationElementBadge";
import "./CalculationElementPreview.css";

export interface ICalculatedElementPreviewProps {
    element: IMappingCalculationElement,
    showActions?: boolean,
    index: number,
    editElement?: () => void,
    removeElement?: () => void,
}

export default function CalculationElementPreview(props: ICalculatedElementPreviewProps) {

    const {
        element,
        removeElement,
        editElement,
        index,
        showActions
    } = props;

    const [hover, setHover] = React.useState<boolean>(false);

    return (
        <div className="d-flex flex-row align-items-center position-relative"  
            onMouseEnter={() => showActions ? setHover(true) : undefined} 
            onMouseLeave={() => showActions ? setHover(false) : undefined} 
            onMouseOver={() => showActions ? setHover(true) : undefined}
        >
            <CalculationElementBadge
                element={element}
                index={index}
            />
            {
                hover && (
                    <Flex 
                        row 
                        className="calculation-element-form-actions ps-1 pe-1"
                    >
                        {
                            removeElement && ( 
                                <CustomButton 
                                    onClick={editElement} 
                                    appearance="outline"
                                    color="light" 
                                    text="Bearbeiten"
                                    icon={<EditRegular />} 
                                />
                            )
                        }
                        {
                            removeElement && ( 
                                <CustomButton 
                                    onClick={removeElement} 
                                    appearance="primary" 
                                    color="danger"
                                    icon={<DeleteRegular color="#FF0000" />} 
                                />
                            )
                        }
                    </Flex>
                )
            }
        </div>
    )
}