import React from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "../button/CustomButton";
import { AppRoutes } from "../menu/Sidebar";
import Flex from "../container/Flex";
import { generateClassName } from "../../../hooks/useAttributes";

export interface IHelpButtonProps {
    floating?: boolean
}

export default function HelpButton({ floating }: IHelpButtonProps) {
    const navigate = useNavigate();

    return (
        <Flex style={{zIndex: 1}} row align="center" fullWidth justify="center" className={generateClassName("mb-3", { value: floating, onTrue: "position-fixed bottom-0 end-0 me-3"})}>
            <CustomButton
                appearance="transparent"
                onClick={() => navigate(AppRoutes.Privacy.path)}
                text="Datenschutz"
            />
            <CustomButton
                onClick={() => navigate(AppRoutes.Help.path)}
                text="Benötigen Sie Hilfe?"
            />
        </Flex>
    )
}