import React from "react";
import { useFormikContext } from "formik";

export default function useFormikValue<Values = any, Result = any>(name: string, defaultValue?: Result) {
    
    const {
        values
    } = useFormikContext<Values>();

    const parseFormikValuesFromName = (): Result | undefined => {  
        try {
            if (!name) return undefined;
            if (!values) return undefined;
            
            const keys = name.split(".");
        
            if (!keys || !keys.length) return undefined;
        
            let v: any = values;
        
            keys.forEach(key => {
                v = v[key]
            });
        
            return v;
        }
        catch { }

        return undefined;
    }   

    return {
        value: parseFormikValuesFromName() ?? defaultValue
    }
}