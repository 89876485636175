import React from "react";
import usePersistance, { StorageKey } from "../../../hooks/usePersistance";
import useApi from "../../../api/useApi";
import { Button } from "@fluentui/react-components";
import { SignOutRegular } from "@fluentui/react-icons";
import CustomButton from "../button/CustomButton";
import CustomMenuButton from "../button/CustomMenuButton";
import { useNavigate } from "react-router-dom";

export default function LogOutButton({ className } : { className?: string }) {
    
    const {
        sessionEnd
    } = useApi();

    const {
        removePersistedValue
    } = usePersistance(StorageKey.Session);

    const navigate = useNavigate();

    const logOut = async () => {
        await sessionEnd();
        removePersistedValue();
        navigate("/");
        window.location.reload();
    }

    return (
        <CustomMenuButton
            onClick={logOut}
            text="Abmelden"
            icon={<SignOutRegular />}
        />
    )
}