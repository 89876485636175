import { Card, Checkbox, Radio, RadioGroup, Switch, Text } from "@fluentui/react-components";
import { AddRegular, CopyRegular, EditRegular } from "@fluentui/react-icons";
import React from "react";
import useApi from "../../../api/useApi";
import useChartUtil from "../../../hooks/useChartUtil";
import { useCharts } from "../../../state/charts/useCharts";
import { useAppSelector } from "../../../state/reduxHooks";
import { useUser } from "../../../state/user/useUser";
import { ChartGroupByType, ChartOrientation, ChartType, IChart, IChartDocument, IChartMappingGroup } from "../../../types/charts.schema";
import { Year } from "../../../types/mapping.schema";
import { getId } from "../../../util/mongoUtil";
import Flex from "../container/Flex";
import CheckBox from "../formik/CheckBox";
import FormikField from "../formik/FormikField";
import FormikSelect from "../formik/FormikSelect";
import ModalForm from "../modal/ModalForm";
import YearsToggle from "../year/YearsToggle";
import ChartGroupsField from "./ChartGroupField";
import ChartPreview from "./ChartPreview";
import UpdateChartFormTabs, { UpdateChartFormTab } from "./UpdateChartFormTabs";

export interface IUpdateChartFormProps {
    chart?: IChart,
    isDuplication?: boolean
}

export default function UpdateChartForm({chart, isDuplication}: IUpdateChartFormProps) {
    
    const [tab, setTab] = React.useState<UpdateChartFormTab>(UpdateChartFormTab.General);
    const [previewVisible, setPreviewVisible] = React.useState<boolean>(false);

    const {
        reloadCharts
    } = useCharts();

    const {
        chartsUpdate,
        chartsCreate
    } = useApi();

    const {
        getLabelForChartType,
        SecondaryFriendlyChartTypes,
        canTypeHaveSecondaryType,
        isTypeAllowedForYoyChart
    } = useChartUtil();

    const {
        mappedResults
    } = useAppSelector(state => state.numbersImport);

    const {
        user
    } = useUser();

    if (chart && chart.isGlobal && !user?.isGlobalAdmin) return null;

    return (
        <ModalForm
            title={(chart && !isDuplication) ? "Chart bearbeiten" : "Chart erstellen"}
            text={chart ? (isDuplication ? "Duplizieren" : "Bearbeiten") : "Neu"}
            icon={chart ? (isDuplication ? <CopyRegular /> : <EditRegular />) : <AddRegular />}
            initialValues={chart || {
                adjustments: [],
                groups: [],
                title: "",
                isGlobal: false,
                years: [],
                isYoY: false,
                orientation: ChartOrientation.Vertical,
                groupByType: ChartGroupByType.Year,
                sankeyLinks: [],
                type: ChartType.BarChart,
                secondaryType: undefined
            } as IChartDocument}
            onSubmit={async (values) => {
                
                values.groups = values.groups.map((g): IChartMappingGroup => ({
                        mappingGroup: getId(g.mappingGroup),
                        yoyStart: getId(g.yoyStart),
                        mappings: (!!g.mappings?.length) ? g.mappings.map(m => ({ 
                            ...m,
                            mapping: getId(m.mapping),
                            useSecondaryType: !!m.useSecondaryType && canTypeHaveSecondaryType(values.type) && !!values.secondaryType && values.groupByType !== ChartGroupByType.Group,
                            sankeyData: m.sankeyData && m.sankeyData.sourceMapping ? m.sankeyData : undefined
                        })) : [],
                }));
                
                const years = [];

                if (!canTypeHaveSecondaryType(values.type) && values.years.length > 1) values.years = [ values.years[0] ];
                else {
                    if (values.years.includes(Year.Current)) years.push(Year.Current);
                    if (values.years.includes(Year.First)) years.push(Year.First);
                    if (values.years.includes(Year.Second)) years.push(Year.Second);
                    if (values.years.includes(Year.Third)) years.push(Year.Third);
                    if (values.years.includes(Year.Fourth)) years.push(Year.Fourth);
                }

                values.years = years;

                const res = (
                    (chart && !isDuplication)
                    ? await chartsUpdate(chart._id, values as IChart)
                    : await chartsCreate(values)
                ).success;

                if (res) await reloadCharts();
                return res;
            }}
        >
            {
                formik => {

                    const getAvailableTypes = () => Object.values(ChartType).filter(isTypeAllowedForYoyChart);

                    return (
                        <Flex fullWidth>
                            <Card appearance="outline" className="w-100">
                                <UpdateChartFormTabs
                                    onSelect={t => setTab(t)}
                                    selectedTab={tab} 
                                /> 
                                {
                                    tab === UpdateChartFormTab.General && (
                                        <>
                                            <Flex gap={1} fullWidth>
                                                <FormikField name="title" label="Titel" />
                                                {
                                                    (!!user && user.isGlobalAdmin) && ( 
                                                        <CheckBox name="isGlobal" label="Dieses Diagramm kann von jedem Kunden genutzt werden" />
                                                    )
                                                }
                                            </Flex>
                                            {
                                                (!formik.values.type || canTypeHaveSecondaryType(formik.values.type)) && (
                                                    <Flex fullWidth gap={1}>
                                                        <Flex gap={0}>
                                                            <Text>Als Jahresentwicklung darstellen</Text>
                                                            <Text size={200}>Dieses Diagramm stellt die Entwicklung einer Position zum Vorjahr dar. Eignet sich etwa für Finanzflussdiagramme.</Text>
                                                        </Flex>
                                                        <CheckBox 
                                                            name="isYoY" 
                                                            label="Dieses Diagramm zeigt die Positionsentwicklung zum Vorjahr" 
                                                            onChange={(val) => {
                                                                if (val) {
                                                                    formik.setFieldValue("years", formik.values.years[0] );
                                                                    formik.setFieldValue("type", ChartType.BarChart);
                                                                }
                                                            }}
                                                        />
                                                    </Flex>
                                                )
                                            }
                                            {
                                                ((!formik.values.type || canTypeHaveSecondaryType(formik.values.type)) && !formik.values.isYoY) && (
                                                    <Flex fullWidth gap={1}>
                                                        <Flex gap={0}>
                                                            <Text>Gruppierung X-Achse</Text>
                                                            <Text size={200}>Wählen Sie Kontengruppe, um an der X-Achse nach Kontengruppe zu unterscheiden.</Text>
                                                        </Flex>
                                                        <RadioGroup
                                                            onChange={(_, data) => formik.setFieldValue("groupByType", data.value)}
                                                            value={formik.values.groupByType}
                                                            layout="horizontal"
                                                        >
                                                            {
                                                                Object.values(ChartGroupByType).map(v => (
                                                                    <Radio 
                                                                        value={v} 
                                                                        label={(() => {
                                                                            switch (v) {
                                                                                case ChartGroupByType.Year: return "Jahr";
                                                                                case ChartGroupByType.Group: return "Kontengruppe";
                                                                            }
                                                                        })()} 
                                                                        key={v}
                                                                    />
                                                                ))
                                                            }
                                                        </RadioGroup>
                                                    </Flex>
                                                )
                                            }
                                            {
                                                (!!formik.values.type && formik.values.type === ChartType.BarChart && !formik.values.isYoY) && (
                                                    <Flex fullWidth gap={1}>
                                                        <Text>Orientierung</Text>
                                                        <RadioGroup
                                                            onChange={(_, data) => {
                                                                const option = data.value as ChartOrientation;

                                                                if (option === ChartOrientation.Horizontal) {
                                                                    formik.setFieldError("secondaryType", undefined);
                                                                }
                                                                
                                                                formik.setFieldValue("orientation", data.value)
                                                            }}
                                                            value={formik.values.orientation}
                                                            layout="horizontal"
                                                        >
                                                            {
                                                                Object.values(ChartOrientation).map(v => (
                                                                    <Radio 
                                                                        value={v} 
                                                                        label={(() => {
                                                                            switch (v) {
                                                                                case ChartOrientation.Vertical: return "Vertikal";
                                                                                case ChartOrientation.Horizontal: return "Horizontal"
                                                                            }
                                                                        })()} 
                                                                        key={v}
                                                                    />
                                                                ))
                                                            }
                                                        </RadioGroup>
                                                    </Flex>
                                                )
                                            }
                                        
                                            <Flex fullWidth gap={1}>
                                                <FormikSelect
                                                    label={formik.values.secondaryType ? "Primärer Diagrammtyp" : "Diagrammtyp"}
                                                    name="type"
                                                    options={getAvailableTypes()}
                                                    getLabel={getLabelForChartType}
                                                    onChange={v => {
                                                        if (canTypeHaveSecondaryType(v as ChartType)) return;
                                                        formik.setFieldValue("secondaryType", undefined);
                                                        formik.setFieldValue("years", [ formik.values.years[0] ]);
                                                    }}
                                                    bold={false}
                                                />
                                                {
                                                    (canTypeHaveSecondaryType(formik.values.type) && formik.values.groupByType !== ChartGroupByType.Group && formik.values.orientation !== ChartOrientation.Horizontal && !formik.values.isYoY) && (
                                                        <>
                                                            <Checkbox
                                                                checked={!!formik.values.secondaryType}
                                                                label="Beinhaltet sekundären Diagrammtyp auf zweiter Y-Achse"
                                                                onChange={(_, data) => formik.setFieldValue("secondaryType", data.checked ? ChartType.LineChart : undefined)}
                                                            />
                                                            {
                                                                !!formik.values.secondaryType && (
                                                                    <FormikSelect
                                                                        label="Sekundärer Diagrammtyp"
                                                                        description="Erlaubt es, einige Datenquellen in einem anderen Diagrammtyp darzustellen."
                                                                        name="secondaryType"
                                                                        options={SecondaryFriendlyChartTypes}
                                                                        getLabel={getLabelForChartType}
                                                                        bold={false}
                                                                    />
                                                                )
                                                            }
                                                        </>
                                                    )
                                                }
                                            </Flex>
                                            <YearsToggle 
                                                label="Verwendete Jahre"
                                                description={(canTypeHaveSecondaryType(formik.values.type) && !formik.values.isYoY) ? undefined : "Für diesen Diagrammtyp kann nur ein Jahr ausgewählt werden."}
                                                maxValues={(canTypeHaveSecondaryType(formik.values.type) && !formik.values.isYoY) ? undefined : 1}
                                                saveValues={years => formik.setFieldValue("years", years)} 
                                                values={formik.values.years} 
                                            />
                                            
                                        </>
                                    )
                                }
                                {
                                    tab === UpdateChartFormTab.Data && <ChartGroupsField />
                                }
                            </Card>
                            {
                                !!mappedResults && !!formik.values.years?.length && (
                                    <Flex fullWidth>
                                        <Flex row fullWidth justify="between">
                                            <Text weight="bold" size={400}>Vorschau</Text>
                                            <Switch
                                                checked={previewVisible}
                                                onChange={(_, data) => setPreviewVisible(data.checked)}
                                            />
                                        </Flex>
                                        {
                                            previewVisible && (
                                                <Flex fullWidth style={{height: "300px"}}>
                                                    <ChartPreview chart={formik.values} />
                                                </Flex>
                                            )
                                        }
                                    </Flex>
                                )
                            }
                        </Flex>
                    )
                }
            }
        </ModalForm>
    )
}