import React from "react";
import Flex from "../container/Flex";
import "./Sidebar.css";
import Dashboard from "../../pages/Dashboard";
import { BuildingRegular, ChartMultipleRegular, DataTreemapRegular, HomeRegular, IncognitoRegular, LinkRegular, PeopleRegular, PresenterRegular, QuestionRegular, SettingsRegular } from "@fluentui/react-icons";
import SidebarLink from "./SidebarLink";
import Mappings from "../../pages/Mappings";
import { Card } from "@fluentui/react-components";
import CustomButton from "../button/CustomButton";
import LogOutButton from "../user/LogOutButton";
import Presentations from "../../pages/Presentations";
import UserAvatar from "../user/UserAvatar";
import Users from "../../pages/Users";
import Customers from "../../pages/Customers";
import Logo from "../logo/Logo";
import useResponsive, { Breakpoint, Height } from "../../../hooks/useResponsive";
import Help from "../../pages/Help";
import Privacy from "../../pages/Privacy";
import WindowSizeIndicator from "./WindowSizeIndicator";
import Charts from "../../pages/Charts";
import Presentation from "../../pages/Presentation";

export interface IAppRoute {
    path: string,
    label: string,
    showInMenu?: boolean,
    getPath?: Function,
    description?: string,
    icon: React.ReactElement,
    visibleInOffice?: boolean,
    hiddenOnTheWeb?: boolean,
    external?: boolean,
    customerAdminOnly?: boolean,
    globalAdminOnly?: boolean,
    component: React.ReactElement,
}

export const AppRoutes: {[key: string]: IAppRoute} = {
    Dashboard: {
        path: "/",
        label: "Dashboard",
        visibleInOffice: true,
        showInMenu: true,
        icon: <HomeRegular />,
        component: <Dashboard />
    },
    Charts: {
        path: "/charts",
        showInMenu: true,
        visibleInOffice: true,
        label: "Diagramme",
        icon: <ChartMultipleRegular />,
        component: <Charts />
    },
    Mappings: {
        path: "/mappings",
        showInMenu: true,
        label: "Kontenrahmen",
        icon: <DataTreemapRegular />,
        component: <Mappings />
    },
    SlideDecks: {
        path: "/slide-decks",
        showInMenu: true,
        visibleInOffice: true,
        label: "Präsentationen",
        icon: <PresenterRegular />,
        component: <Presentations />
    },
    SlideDeck: {
        path: "/slide-deck/:id",
        getPath: (id: string) => `/slide-deck/${id}`,
        showInMenu: false,
        visibleInOffice: false,
        label: "Präsentation",
        icon: <PresenterRegular />,
        component: <Presentation />
    },
    Users: {
        path: "/users",
        showInMenu: true,
        label: "Benutzer",
        customerAdminOnly: true,
        icon: <PeopleRegular />,
        component: <Users />
    }, 
    Customers: {
        path: "/customers",
        showInMenu: true,
        globalAdminOnly: true,
        label: "Kunden",
        component: <Customers />,
        icon: <BuildingRegular />
    },
    WebLink: {
        path: "https://sbt-presentations.com",
        showInMenu: true,
        visibleInOffice: true,
        hiddenOnTheWeb: true,
        external: true,
        component: <></>,
        icon: <SettingsRegular />,
        label: "Administration",
        customerAdminOnly: true
    },
    Help: {
        path: "/help",
        showInMenu: true,
        component: <Help />,
        icon: <QuestionRegular />,
        label: "Hilfe",
        visibleInOffice: true
    },
    Privacy: {
        path: "/privacy",
        showInMenu: false,
        visibleInOffice: false,
        component: <Privacy />,
        label: "Datenschutzerklärung",
        icon: <IncognitoRegular />
    }
}

export default function Sidebar() {
    return (
        <Flex fullHeight className="position-relative sidebar" fullWidth={false} style={{zIndex: 1}}>
            <Card className="sidebar-content h-100 w-100 m-0">
                <Flex fullHeight align="center" fullWidth={false} justify="between">
                    <Flex gap={3} fullWidth className="sidebar-links">
                        <Logo padding={3} size={48} className="sidebar-logo" />
                        {
                            Object.values(AppRoutes).map((route, index) => route.showInMenu ? <SidebarLink key={index} to={route} /> : undefined)
                        }
                        <WindowSizeIndicator />
                    </Flex>
                    <UserAvatar />
                </Flex>
            </Card>
        </Flex>
    )
}