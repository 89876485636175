import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMappingGroup } from "../../types/mappingGroup.schema";
import { Year } from "../../types/mapping.schema";
import { IAccountValue } from "../../hooks/useNumberImport";

export interface ICurrentGroupState {
    groupId: string
}

export const CurrentGroupSlice = createSlice({
    name: "currentGroup",
    initialState: {
        groupId: ""
    } as ICurrentGroupState,
    reducers: {
        setGroupId: (state, action: PayloadAction<string>) => {
            state.groupId = action.payload;
        }
    }
});

export const { setGroupId } = CurrentGroupSlice.actions;

export default CurrentGroupSlice.reducer;