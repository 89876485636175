import React from "react";
import { IMappingGroup, IMappingGroupWithMappings } from "../../../types/mappingGroup.schema";
import { useMappingForGroup } from "../../../state/mappings/useMappingForGroup";
import ModalForm from "../modal/ModalForm";
import useApi from "../../../api/useApi";
import { BalanceIndicator, CalculationResultType, IMapping, IMappingAccount, IMappingDocument } from "../../../types/mapping.schema";
import Flex from "../container/Flex";
import { Button, Card, Radio, RadioGroup, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow, Text } from "@fluentui/react-components";
import { Field, FieldArray } from "formik";
import { Add16Regular, AddRegular, Delete16Regular, EditRegular } from "@fluentui/react-icons";
import FormikField from "../formik/FormikField";
import CustomButton from "../button/CustomButton";
import { useMappingGroups } from "../../../state/mappingGroups/useMappingGroups";
import { useUser } from "../../../state/user/useUser";
import IElementProps from "../../../types/element.types";
import useResponsive, { Breakpoint } from "../../../hooks/useResponsive";
import CheckBox from "../formik/CheckBox";
import UpdateCalculationStepForm from "./calculations/UpdateCalculationStepForm";
import CalculationResultTypeSelect from "./calculations/CalculationResultTypeSelect";

export interface IUpdateMappingFormProps extends IElementProps {
    mappingGroup: IMappingGroupWithMappings,
    mapping?: IMapping
}

export default function UpdateMappingForm(props: IUpdateMappingFormProps) {
    const {
        mappingGroup,
        mapping,
        className
    } = props;

    const {
        isNarrower
    } = useResponsive(Breakpoint.Mobile);

    const {
        mappingsUpdate,
        mappingsCreate
    } = useApi();

    const {
        user
    } = useUser();

    const {
        reloadMappingGroups
    } = useMappingGroups();

    if (mappingGroup.isGlobalPreset && !user?.isGlobalAdmin) return null;
    
    return (
        <ModalForm
            className={className}
            text={mapping ? (!isNarrower && "Bearbeiten") : "Neue Position"}
            title={mapping ? "Position bearbeiten" : "Neue Position erstellen"}
            description={mappingGroup ? `Neue Position für Kontenrahmen '${mappingGroup.name}' erstellen` : ""}
            icon={mapping ? <EditRegular /> : <AddRegular />}
            appearance={mapping ? "transparent" : undefined}
            size="large"
            initialValues={{
                accounts: mapping?.accounts ?? [],
                indicator: mapping?.indicator ?? BalanceIndicator.Credit,
                name: mapping?.name ?? "",
                shortName: mapping?.shortName ?? "",
                calculationSteps: mapping?.calculationSteps ?? [],
                resultIsDeltaToLastYear: mapping?.resultIsDeltaToLastYear ?? false,
                resultType: mapping?.resultType ?? CalculationResultType.Currency,
                isCalculated: mapping?.isCalculated ?? false
            } as IMappingDocument}
            enableReinitialize
            onSubmit={async (values) => {
                const res = (
                    mapping
                    ? await mappingsUpdate(mapping._id, values)
                    : await mappingsCreate(mappingGroup._id, values)
                ).success;

                if (!res) return false;

                await reloadMappingGroups();
                return true;
            }}
        >
            {
                formik => (
                    <Flex fullWidth>
                        <FormikField 
                            name="name" 
                            label="Name der Position" 
                        />
                        <FormikField 
                            name="shortName" 
                            label="Abkürzung der Position" 
                        />
                        <Flex fullWidth gap={0}>
                            <Text>Positionstyp</Text>
                            <CheckBox 
                                name="isCalculated"
                                label="Berechnete Position"
                                onChange={(e) => {
                                    formik.setFieldValue("isCalculated", e.checked);
                                    if (e.checked) formik.setFieldValue("accounts", []);
                                    else formik.setFieldValue("calculationSteps", []);
                                }}
                            />
                        </Flex>
                        <Text>Bilanztyp / Ergebnistyp</Text>
                        <RadioGroup 
                            layout="horizontal"
                            onChange={(_, data) => formik.setFieldValue("indicator", data.value)}
                            value={formik.values.indicator}
                            name="indicator"
                        >
                            <Radio value={BalanceIndicator.Credit} label="Haben" />
                            <Radio value={BalanceIndicator.Debit} label="Soll" />
                        </RadioGroup>
                        {
                            formik.values.isCalculated 
                            ? (
                                <Flex fullWidth>
                                    <Card className="w-100" appearance="outline" style={{overflow: "visible"}}>
                                        <FieldArray name="calculationSteps">
                                            {
                                                (arrayHelpers) => (
                                                    <Flex fullWidth>
                                                        <Flex fullWidth row justify="between">
                                                            <Text>Berechnungskomponenten</Text>
                                                            <UpdateCalculationStepForm 
                                                                mappingGroup={mappingGroup}	 
                                                                addNewCalculationStep={(step) => arrayHelpers.push(step)} 
                                                            />
                                                        </Flex>
                                                        <Flex row wrap>
                                                            {
                                                                formik.values.calculationSteps && !!formik.values.calculationSteps.length
                                                                ? formik.values.calculationSteps.map((step, index) => (
                                                                    <UpdateCalculationStepForm 
                                                                        mappingGroup={mappingGroup}	    
                                                                        calculcationStepIndex={index}
                                                                        removeElement={() => arrayHelpers.remove(index)}
                                                                        key={index}
                                                                        element={step}
                                                                    />
                                                                ))
                                                                : <Text>Keine Berechnungskomponenten</Text>
                                                            }

                                                        </Flex>
                                                    </Flex>
                                                )
                                            }
                                        </FieldArray>
                                    </Card>
                                    <Flex fullWidth gap={0}>
                                        <Text>Mehrjahresverrechnung</Text>
                                        <CheckBox name="resultIsDeltaToLastYear" label="Das Ergebnis dieser Position ist die Differenz zwischen dem aktuellen und dem Vorjahreswert für diese Berechnung" />
                                        <Text size={200}>
                                            Das Setzen dieser Option bewirkt, dass diese Kalkulation sowohl für das aktuelle als auch für das Vorjahr ausgewertet wird. 
                                            Als Wert / Ergebnis wird dann die Differenz dieser beiden angenommen. 
                                            Nützlich, um die Entwicklung von Positionen darzustellen, beispielsweise Umlaufvermögen oder Verbindlichkeiten.
                                        </Text>
                                    </Flex>
                                    <Flex fullWidth gap={0}>
                                        <CalculationResultTypeSelect 
                                            onSave={t => formik.setFieldValue("resultType", t)}
                                            value={formik.values.resultType}
                                        />
                                    </Flex>
                                </Flex>
                            )
                            : (
                                <Flex fullWidth>
                                    <Card className="w-100" appearance="outline">
                                        
                                        <FieldArray name="accounts">
                                            {
                                                (arrayHelpers) => (
                                                    <Flex fullWidth>
                                                        <Flex fullWidth row justify="between">
                                                            <Text>Konten</Text>
                                                            <Button onClick={() => arrayHelpers.push({accountFrom: 0, accountTo: 0} as IMappingAccount)} icon={<Add16Regular />} appearance="transparent">Neues Konto</Button>
                                                        </Flex>
                                                        <Table>
                                                            <TableHeader>
                                                                <TableRow>
                                                                    <TableHeaderCell>Konto von</TableHeaderCell>
                                                                    <TableHeaderCell>Konto bis</TableHeaderCell>
                                                                    <TableHeaderCell>Aktionen</TableHeaderCell>
                                                                </TableRow>
                                                            </TableHeader>
                                                            <TableBody>
                                                                {
                                                                    formik.values.accounts && !!formik.values.accounts.length
                                                                    ? formik.values.accounts.map((account, index) => (
                                                                        <TableRow key={index}>
                                                                            <TableCell>
                                                                                <FormikField 
                                                                                    type="number" 
                                                                                    placeholder="Konto eingeben" 
                                                                                    appearance="filled-lighter" 
                                                                                    name={`accounts.${index}.accountFrom`} 
                                                                                />
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                <FormikField 
                                                                                    type="number" 
                                                                                    placeholder="Konto eingeben" 
                                                                                    appearance="filled-lighter" 
                                                                                    name={`accounts.${index}.accountTo`} 
                                                                                />
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                <CustomButton color="danger" onClick={() => arrayHelpers.remove(index)} icon={<Delete16Regular/>} appearance="transparent"></CustomButton>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    ))
                                                                    : <TableRow><TableCell colSpan={3}>Keine Konten</TableCell></TableRow>
                                                                }
                                                            </TableBody>
                                                        </Table>
                                                    </Flex>
                                                )
                                            }
                                        </FieldArray>
                                    </Card>
                                </Flex>
                            )
                        }
                    </Flex>
                )
            }
        </ModalForm>
    )
}