import { IChartPosition } from "../types/slideDeck.schema";

export default function useSlideUtil() {
    
    const pptSlideSize: IChartPosition = {
        x: 1280,
        y: 720
    }

    const htmlSlideSize: IChartPosition = {
        x: 225,
        y: 125
    }

    const getPercentOfSlideSize = (dims: IChartPosition): IChartPosition => {
        return {
            x: dims.x / pptSlideSize.x * 100,
            y: dims.y / pptSlideSize.y * 100
        }
    }

    return {
        htmlSlideSize,
        pptSlideSize,
        slideCss: {
            width: htmlSlideSize.x + "px",
            height: htmlSlideSize.y + "px"
        },
        getPercentOfSlideSize
    }
}