import { IChart } from "./charts.schema";
import { IUploadableFile } from "./googlecloudstorage.types";
import { IMongooseDocument } from "./mongoose";

export interface IChartPosition {
    x: number,
    y: number
}

export enum ChartSizeMode {
    height = "height",
    width = "width"
}

export interface IChartSize {
    sizeMode: ChartSizeMode,
    value: number
}

export interface ISlideChart {
    chart: IChart,
    position: IChartPosition,
    size: IChartSize
}

export interface ISlidePadding {
    top: number,
    left: number,
    right: number,
    bottom: number
}

export interface ISlide {
    index: number,
    charts: Array<ISlideChart>
}

export interface ISlideDeckDocument {
    name: string,
    padding: ISlidePadding,
    isGlobalPreset: boolean,
    slides: Array<ISlide>,
    presetFile: IUploadableFile,
}

export interface ISlideDeck extends ISlideDeckDocument, IMongooseDocument { }